import { Button, Modal } from 'react-bootstrap'
import Element, { BaseProps } from './Element'
import React, { ChangeEventHandler, SyntheticEvent, useEffect, useState } from 'react'
import EntityFinderTable from '../../EntityFinderTable'
import axios, { AxiosResponse } from 'axios'
import { ActionItemProps } from '../../ResultViewer/ResultViewer'
import { CreateEnergyTypeModal } from '../../../modules/company/components/CreateEnergyTypeModal'

type FinderModalProps = BaseProps & {
  show: boolean
  handleClose: () => void
  title: string
  dataUrl: string
  onChange: ChangeEventHandler
  actionItems: Array<ActionItemProps>
}

const EntityFinderModal: React.FC<FinderModalProps> = ({
  show,
  handleClose,
  title,
  dataUrl,
  onChange,
  actionItems,
}) => {
  return (
    <Modal
      id='kt_modal_table_list'
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      size='xl'
      show={show}
      backdrop={true}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EntityFinderTable
          title={title}
          dataUrl={dataUrl}
          editCallback={onChange}
          deleteCallback={() => {}}
          actionItems={actionItems}
        />
      </Modal.Body>
    </Modal>
  )
}

type FinderProps = BaseProps & {
  name: string
  defaultValue: string | number | readonly string[]
  type: string
  onChange: Function
  placeholder?: string
  buttonClass: string
  dataUrl: string
  singleUrl: string
}

export default function EntityFinder(props: FinderProps) {
  const [showModal, setShowModal] = useState(false)
  const [placeholderValue, setPlaceholderValue] = useState('Select Energy Type')
  const [itemIdForEnergyType, setItemIdForEnergyType] = useState('')
  const [energyTypeRefreshFunction, setEnergyTypeRefreshFunction] = useState<any>(() => {})
  const fetchPlaceholderValue = async () => {
    try {
      setTimeout(async () => {
        let data: any
        const url = props.singleUrl + '/' + props.defaultValue
        data = await axios
          .get(url)
          .then((response: AxiosResponse) => response.data)
          .then((response) => response.data)
        let text = data['0'].energy_type + '_' + data['0'].energy_subtype
        setPlaceholderValue(text)
      }, 2000)
    } catch (error) {
      console.log('Error getting energy type value:', error)
    }
  }

  useEffect(() => {
    if (props.defaultValue) {
      fetchPlaceholderValue()
    }
  }, [props.defaultValue])

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <Element title={props.title} required={props.required}>
        <div className='d-flex align-items-center'>
          <input
            type='text'
            className='form-control'
            placeholder={props.placeholder}
            value={placeholderValue}
            readOnly
          />
          <Button className={props.buttonClass} onClick={handleOpenModal}>
            Select
          </Button>
        </div>
      </Element>

      <EntityFinderModal
        show={showModal}
        handleClose={handleCloseModal}
        title={props.title}
        onChange={(value) => {
          let event = {
            target: {
              name: props.name,
              value: value,
            },
          }
          props.onChange(event)
          handleCloseModal()
        }}
        actionItems={[
          {
            text: 'Add Energy Type',
            class: 'btn btn-sm btn-primary',
            icon: 'far fa-building',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              setItemIdForEnergyType('add')
              setEnergyTypeRefreshFunction(() => refresh)
            },
          },
        ]}
        dataUrl={props.dataUrl}
      />
      {itemIdForEnergyType !== '' ? (
        <CreateEnergyTypeModal
          show={itemIdForEnergyType !== ''}
          itemIdForUpdate={itemIdForEnergyType}
          onHide={() => {
            setItemIdForEnergyType('')
          }}
          refreshFunction={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForEnergyType(itemId)
            }
            energyTypeRefreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}
