import { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem } from '../../charts/core/_models'

export const meterTypeOptions = [
  {
    value: 'Electricity',
    label: 'Electric',
  },
  {
    value: 'Gas',
    label: 'Gas',
  },
  {
    value: 'Water',
    label: 'Water',
  },
  {
    value: 'Fuel',
    label: 'Fuel',
  },
]

export type meterType = { value: string; label: string }

export type cellChartParams = {
  isLoggedIn: boolean
  companyId: string
  siteId: MultiValue<CompanyListItem>
  metersId?: MultiValue<CompanyListItem>
  meterType: SingleValue<meterType>
  startDate: string
  endDate: string
}

export type DataObject = {
  thisYear?: number[]
  lastYear?: number[]
  alarm?: string
}

export type DataModel = Record<string, DataObject>

export const predictionAlgorithmOptions = [
  {
    value: 'Average',
    label: 'Average',
  },
  {
    value: 'Trend',
    label: 'Trend',
  },
  {
    value: 'Trend10',
    label: 'Trend10',
  },
  {
    value: 'Trend10Minus1',
    label: 'Trend10Minus1',
  },
]

export type predictionAlgorithmType = { value: string; label: string }

export type analyticChartParams = {
  isLoggedIn: boolean
  companyId: string
  siteId: MultiValue<CompanyListItem>
  metersId?: MultiValue<CompanyListItem>
  meterType: SingleValue<meterType>
  algorithmType: MultiValue<predictionAlgorithmType>
  readingType: string
  startDate: string
  endDate: string
}

export type compareChartParams = {
  isLoggedIn: boolean
  companyId: string
  siteId: MultiValue<CompanyListItem>
  metersId?: MultiValue<CompanyListItem>
  meterType: SingleValue<meterType>
  firstDate: string
  secondDate: string
  thirdDate: string
}

export type SeriesDataItem = {
  name: string
  data: number[]
}

export type alarmDataForCalendarParams = {
  isLoggedIn: boolean
  companyId: string
  siteId: MultiValue<CompanyListItem>
  metersId?: MultiValue<CompanyListItem>
  meterType: string
  selectedDate: string
}

export type SiteData = {
  today: any[]
  average: any[]
}

export type MeterData = {
  today: any[]
  average: any[]
}

export type SiteBasedData = {
  [siteName: string]: SiteData
}

export type MeterBasedData = {
  [siteName: string]: {
    [meterName: string]: MeterData
  }
}

export type AlarmDataForCalendarModal = []
