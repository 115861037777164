import React, { useEffect, useState } from 'react'
import { Modal, FormGroup } from 'react-bootstrap'
import axios from 'axios'
import Field from '../../../../utils/Form/Widgets/Field'
import Textarea from '../../../../utils/Form/Widgets/Textarea'
import { Mail } from './core/_models'

type SendDataModalProps = {
  show: boolean
  dataRequestId: string
  onHide: () => void
  refreshFunction?: Function
  mail: Mail
}

const ForwardMailModal: React.FC<SendDataModalProps> = ({
  show,
  dataRequestId,
  onHide,
  refreshFunction,
  mail,
}) => {
  const [formData, setFormData] = useState({
    mailId: mail.mailId,
    comment: '',
    recipientName: '',
    recipientMail: '',
  })
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      comment: 'Enter comment for forward mail.',
      recipientName: 'Recipient Name',
      recipientMail: 'recipient@recipient.com',
    }))
  }, [show])

  const handleMailBodyInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      comment: value,
    }))
  }

  const handleRecipientNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      recipientName: value,
    }))
  }
  const handleRecipientMailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      recipientMail: value,
    }))
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const response = await axios.post('/microsoft_integration/forwardMail', {
        mailId: mail.mailId,
        comment: formData.comment,
        recipientName: formData.recipientName,
        recipientMail: formData.recipientMail,
      })

      if (response.status === 200) {
        console.log('Email sent successfully.')
        onHide()
      } else {
        console.log('API error:', response)
      }
    } catch (error) {
      console.log('An error occurred:', error)
    }
  }

  return (
    <Modal
      id='kt_modal_create_company'
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      size='lg'
      show={show}
      backdrop={true}
      onHide={onHide}
    >
      <form
        onSubmit={(event) => {
          handleSubmit(event).then((response) => {
            if (refreshFunction) {
              refreshFunction(response)
            }
          })
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Forward Mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormGroup>
              <Field
                title='Recipient Name'
                className='form-control'
                type='text'
                onChange={handleRecipientNameInputChange}
                name='recipientName'
                onInput={() => {}}
                placeholder={formData.recipientName}
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Recipient Mail'
                className='form-control'
                type='text'
                name='recipientMail'
                onChange={handleRecipientMailInputChange}
                onInput={() => {}}
                placeholder={formData.recipientMail}
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Textarea
                title='Comment'
                className='form-control'
                type='textarea'
                name='comment'
                onInput={handleMailBodyInputChange}
                placeholder={formData.comment}
                required={true}
              />
            </FormGroup>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <button type='submit' className='btn btn-primary btn-sm'>
            Forward Mail
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ForwardMailModal
