import { ReactNode, SyntheticEvent } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../utils/Form/FormHandler'
import Field from '../../utils/Form/Widgets/Field'
import AutocompleteSelect from '../../utils/Form/Widgets/AutocompleteSelect'
import Select from '../../utils/Form/Widgets/Select'
import Check from '../../utils/Form/Widgets/Check'
import axios, { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}

class Site {
  name?: string
  code?: string
  company?: number
}

class SiteEditModal extends FormHandler {
  statusOptions = [
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'passive',
      label: 'Disabled',
    },
  ]

  constructor(props: Props) {
    super(props)
    this.state = { entity: new Site() }
  }

  getApiUrl(): string {
    return '/company/sites/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    this.fetchFeatureOptions()
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/company/sites/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      company_sites: this.state.entity,
    }
  }

  featureOptions: Array<{ value: string; label: string }> = []

  fetchFeatureOptions = async () => {
    try {
      const response = await axios.get('/settings_sitefeatures/getOptionsList')
      this.featureOptions = response.data.data
      this.forceUpdate()
    } catch (error) {
      console.error('Error fetching feature options:', error)
    }
  }

  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Site'
    if (this.state.entity && this.props.itemIdForUpdate !== 'add') {
      let entity = this.state.entity as any
      title = entity.name
    }
    let defaultCompanyValue = state.entity.company || 0
    if (this.props.itemIdForUpdate === 'add') {
      defaultCompanyValue = 0
    } else {
      defaultCompanyValue = state.entity.company
    }
    let defaultCountryValue = state.entity?.company_site_addresses?.[0]?.country || 0
    if (this.props.itemIdForUpdate === 'add' || !state.entity?.company_site_addresses?.[0]) {
      // Default United Kingdom
      defaultCountryValue = 231
    }
    const maxFeatures = this.featureOptions?.length ?? 0
    return (
      <Modal
        id='kt_modal_create_company'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={async (event) => {
            event.preventDefault()
            try {
              const response = await this.handleSubmit(event)
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
              this.props.onHide()
            } catch (error) {
              console.error('Error saving site:', error)
              Swal.fire({
                title: 'Error',
                text: 'An error occurred while saving the site. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title='Name'
                className='form-control'
                type='text'
                name='name'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.name}
              />
            </FormGroup>
            <FormGroup>
              <AutocompleteSelect
                title='Company'
                name='company'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultCompanyValue}
                autoCompleteTokenUrl='/company/autocomplete'
              />
            </FormGroup>
            <FormGroup>
              <Check
                title='Status'
                name='status'
                type='switch'
                checked={state.entity.status === 'active'}
                options={['']}
                onChange={(event: any) => {
                  const newValue = event.target.checked ? 'active' : 'passive'
                  this.handleInputChange({ target: { name: 'status', value: newValue } } as any)
                }}
              />
            </FormGroup>

            <div className='accordion mt-5' id='outerAccordion'>
              <div className='accordion-item' key={`site`}>
                <h2 className='accordion-header' id={`siteHeader`}>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target={`#siteCollapse`}
                    aria-expanded='false'
                    aria-controls={`siteCollapse`}
                  >
                    Site Address Section
                  </button>
                </h2>
                <div
                  id={`siteCollapse`}
                  className='accordion-collapse collapse'
                  aria-labelledby={`siteHeader`}
                  data-bs-parent='#outerAccordion'
                >
                  <div className='accordion-body'>
                    <div className='accordion-body'>
                      <FormGroup>
                        <Field
                          title='Address'
                          className='form-control'
                          type='string'
                          name='company_site_addresses.0.address'
                          onInput={(event: any) => {
                            this.handleInputChange(event)
                          }}
                          defaultValue={state.entity?.company_site_addresses?.[0]?.address}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          title='City'
                          className='form-control'
                          type='string'
                          name='company_site_addresses.0.city'
                          pattern='^([A-Za-z \g])+$'
                          onInput={(event: any) => {
                            this.handleInputChange(event)
                          }}
                          defaultValue={state.entity?.company_site_addresses?.[0]?.city}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          title='County'
                          className='form-control'
                          type='string'
                          name='company_site_addresses.0.county'
                          pattern='^([A-Za-z \g])+$'
                          onInput={(event: any) => {
                            this.handleInputChange(event)
                          }}
                          defaultValue={state.entity?.company_site_addresses?.[0]?.county}
                        />
                      </FormGroup>
                      <FormGroup>
                        <AutocompleteSelect
                          title='Country'
                          name='company_site_addresses.0.country'
                          onChange={(event: any) => {
                            this.handleInputChange(event)
                          }}
                          defaultValue={defaultCountryValue}
                          autoCompleteTokenUrl='/company/countriesautocomplete'
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          title='Postcode'
                          className='form-control'
                          type='string'
                          name='company_site_addresses.0.postcode'
                          onInput={(event: any) => {
                            this.handleInputChange(event)
                          }}
                          defaultValue={state.entity?.company_site_addresses?.[0]?.postcode}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          title='Phone'
                          className='form-control'
                          name='company_site_addresses.0.phone'
                          onInput={(event: any) => {
                            this.handleInputChange(event)
                          }}
                          defaultValue={state.entity?.company_site_addresses?.[0]?.phone}
                          pattern='^\d+$'
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          title='Email'
                          className='form-control'
                          type='string'
                          name='company_site_addresses.0.email'
                          onInput={(event: any) => {
                            this.handleInputChange(event)
                          }}
                          defaultValue={state.entity?.company_site_addresses?.[0]?.email}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex mt-5 mb-2'>
              <button
                type='button'
                className='btn btn-primary btn-sm'
                onClick={(event) => {
                  let entity = this.state.entity as any
                  const hasEmptyFeature = entity.company_site_features?.some(
                    (feature: any) => !feature.feature || !feature.value
                  )

                  if (!entity.company_site_features) {
                    entity.company_site_features = []
                  }
                  if (entity.company_site_features.length < maxFeatures) {
                    if (!hasEmptyFeature) {
                      entity.company_site_features.push({ feature: '', value: '' })
                      this.setState({ entity: entity })
                    } else {
                      Swal.fire({
                        title: 'Warning',
                        html: 'Please fill in the empty feature fields before adding a new parameter.',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'OK',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                      })
                    }
                  } else {
                    Swal.fire({
                      title: 'Warning',
                      html: 'You have added all parameter types.',
                      icon: 'warning',
                      showCancelButton: false,
                      confirmButtonText: 'OK',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                    })
                  }
                }}
                disabled={state.entity.company_site_features?.length > maxFeatures}
              >
                <span className='fa fa-plus'></span> Add Benchmark Parameter
              </button>
            </div>
            {state.entity.company_site_features?.map((feature: any, index: number) => {
              return (
                <div className='accordion' id={`featureAccordion${index}`} key={index}>
                  <div className='accordion-item mt-2 mb-2'>
                    <h2
                      className='accordion-header d-flex align-items-center'
                      id={`featureHeader${index}`}
                    >
                      <button
                        className='btn btn-link text-danger p-0 ms-2'
                        type='button'
                        onClick={() => {
                          let entity = this.state.entity as any
                          entity.company_site_features.splice(index, 1)
                          this.setState({ entity: entity })
                        }}
                      >
                        <span className='fa fa-trash'></span>
                      </button>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#featureCollapse${index}`}
                        aria-expanded='false'
                        aria-controls={`featureCollapse${index}`}
                      >
                        Parameter {index + 1}:{' '}
                        {this.featureOptions.find((option) => option.value === feature.feature)
                          ?.label || ''}
                      </button>
                    </h2>
                    <div
                      id={`featureCollapse${index}`}
                      className='accordion-collapse collapse'
                      aria-labelledby={`featureHeader${index}`}
                      data-bs-parent={`#featureAccordion${index}`}
                    >
                      <div className='accordion-body'>
                        <FormGroup>
                          <Select
                            title='Parameter'
                            name={`company_site_features.${index}.feature`}
                            options={this.featureOptions}
                            onChange={(event: any) => {
                              this.handleInputChange(event)
                            }}
                            defaultValue={feature.feature}
                            required={true}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Field
                            title='Value'
                            name={`company_site_features.${index}.value`}
                            defaultValue={feature.value}
                            onChange={(event: any) => {
                              this.handleInputChange(event)
                            }}
                            className='form-control'
                            required={true}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { SiteEditModal }
