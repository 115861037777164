import { useEffect, useState } from 'react'
import { calculateTimeDifference, getBodyField } from './core/_requests'
import { NotificationsItem } from './NotificationsItem'
import { eventNotification } from './core/_models'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { useIntl } from 'react-intl'

var notifications: any = {}
var eventSource: EventSourcePolyfill | null = null
function NotificationsList() {
  const [eventNotifications, setEventNotifications] = useState<eventNotification[]>([])
  const [filteredEventNotifications, setFilteredEventNotifications] = useState<eventNotification[]>(
    []
  )
  const intl = useIntl()

  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem('@auth/token') ?? undefined
  })
  const [isReadFilter, setIsReadFilter] = useState<string>('unread')
  useEffect(() => {
    const authToken = token

    eventSource = new EventSourcePolyfill(
      process.env.REACT_APP_API_BASE + '/notifications/streamList',
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        heartbeatTimeout: 60 * 1000 * 60,
      }
    )
    eventSource.onmessage = (event) => {
      let data = JSON.parse(event.data)
      if (data) {
        for (let notification of data) {
          notifications[notification.ID] = notification
        }
        setEventNotifications(Object.values(notifications))
      }
    }
  }, [])

  useEffect(() => {
    if (eventNotifications.length > 0) {
      let unread = eventNotifications.filter((event) => {
        return !event.is_read
      })
      setFilteredEventNotifications(unread)
    }
  }, [eventNotifications])

  useEffect(() => {
    if (isReadFilter === 'all') {
      setFilteredEventNotifications(eventNotifications)
    } else if (isReadFilter === 'read') {
      let notifications = eventNotifications.filter((event) => {
        return event.is_read
      })
      setFilteredEventNotifications(notifications)
    } else if (isReadFilter === 'unread') {
      let notifications = eventNotifications.filter((event) => {
        return !event.is_read
      })
      setFilteredEventNotifications(notifications)
    }
  }, [isReadFilter])

  const handleFilterChange = (event: any) => {
    const selectedFilter = event.target.value
    setIsReadFilter(selectedFilter)
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <div className='d-flex flex-wrap my-2'>
          <div className='me-4'>
            <select
              id='filterSelect'
              className='form-select form-select-sm form-select-white w-125px'
              onChange={handleFilterChange}
              value={isReadFilter}
            >
              <option value='all'>All</option>
              <option value='read'>Read</option>
              <option value='unread'>Unread</option>
            </select>
          </div>
        </div>
      </div>
      <div id='kt_activities' className='bg-body'>
        <div className='card shadow-none rounded-0'>
          <div className='card-body position-relative' id='kt_activities_body'>
            <div
              id='kt_activities_scroll'
              className='position-relative scroll-y me-n5 pe-5'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#kt_activities_body'
              data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
              data-kt-scroll-offset='5px'
            >
              <div className='timeline'>
                {filteredEventNotifications && filteredEventNotifications.length > 0 ? (
                  filteredEventNotifications.map(
                    (notification) =>
                      notification.entity_id &&
                      notification.created_at &&
                      notification.type &&
                      notification.body && (
                        <NotificationsItem
                          key={notification.entity_id}
                          title={getBodyField(notification.type, notification.body, 'title')}
                          entityDate={getBodyField(notification.type, notification.body, 'date')}
                          notificationDate={calculateTimeDifference(notification.created_at)}
                          type={notification.type}
                          isRead={notification.is_read}
                          entityId={notification.entity_id}
                        />
                      )
                  )
                ) : (
                  <div className='text-center text-gray-600 fs-6'>
                    {intl.formatMessage({ id: 'NOTIFICATIONS.NONOTIFICATIONS' })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotificationsList
