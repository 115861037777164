import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import BenchmarkList from './components/BenchmarkList'
import BenchmarkCharts from './components/BenchmarkCharts'
import BenchmarkHeatmap from './components/BenchmarkHeatmap'
import { BenchmarkRecordsList } from './components/BenchmarkRecordsList'
import BenchmarkRecordEditPage from './components/BenchmarkRecordEditPage'

const benchmarkBreadCrumbs: Array<PageLink> = [
  {
    title: 'Benchmark',
    path: '/benchmark/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BenchmarkPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='records'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Benchmark Views'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={benchmarkBreadCrumbs}>Benchmark Views</PageTitle>
              <BenchmarkRecordsList />
            </>
          }
        />
        <Route
          path='list'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Benchmark Dashboard'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={benchmarkBreadCrumbs}>Benchmark Dashboard</PageTitle>
              <BenchmarkList />
            </>
          }
        />
        <Route
          path='records/edit/:recordId'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Benchmark View Edit '}
              ></DocumentTitle>
              <PageTitle breadcrumbs={benchmarkBreadCrumbs}>Benchmark View Edit Page</PageTitle>
              <BenchmarkRecordEditPage />
            </>
          }
        />
        <Route
          path='charts'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Benchmark Charts'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={benchmarkBreadCrumbs}>Benchmark Charts</PageTitle>
              <BenchmarkCharts />
            </>
          }
        />

        <Route
          path='heatmap'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Benchmark Heatmap'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={benchmarkBreadCrumbs}>Benchmark Heatmap</PageTitle>
              <BenchmarkHeatmap />
            </>
          }
        />
        <Route index element={<Navigate to='/benchmark/list' />} />
      </Route>
    </Routes>
  )
}

export default BenchmarkPage
