import { lazy, FC, Suspense, useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { DashboardWrapper } from '../modules/apps/dashboard/DashboardWrapper'
import CompanyPage from '../modules/company/CompanyPage'
import SettingsPage from '../modules/settings/SettingsPage'
import DataPage from '../modules/data/DataPage'
import SupportPage from '../modules/support/SupportPage'
import DataViewPage from '../modules/data/DataViewPage'
import { meApi } from '../modules/auth/core/_requests'
import { User } from '../modules/auth/core/_models'
import ReportsPage from '../modules/reports/ReportsPage'
import AlarmsPage from '../modules/alarms/AlarmsPage'
import NotificationsPage from '../modules/notifications/NotificationsPage'
import DocumentPage from '../modules/documents/DocumentPage'
import DataRequestsPage from '../modules/data/DataRequestsPage'
import LoraPage from '../modules/lora/LoraPage'
import SecrReportsPage from '../modules/secr_reports/SecrReportsPage'
import SecrDocumentsPage from '../modules/secr_documents/SecrDocumentsPage'
import BenchmarkPage from '../modules/benchmark/BenchmarkPage'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const [user, setUser] = useState<User>()
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem('@auth/token') ?? undefined
  })

  useEffect(() => {
    const fetchUser = async () => {
      const user = await meApi(token) // meApi'yi çağırarak kullanıcı bilgilerini alıyoruz

      if (!user) {
        setToken(undefined)
        return
      }
      setUser(user.data)
    }
    fetchUser()
  }, [])
  let hasValidRole = false
  user?.roles.map((role) => {
    if (role == '1' || role == '2') {
      hasValidRole = true
    } else {
      hasValidRole = false
    }
  })

  if (!hasValidRole) {
    return (
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='dashboard' element={<DashboardWrapper />} />
          <Route path='dashboard/group' element={<DashboardWrapper />} />
          <Route
            path='reports/*'
            element={
              <SuspensedView>
                <ReportsPage />
              </SuspensedView>
            }
          />
          <Route
            path='alarms/*'
            element={
              <SuspensedView>
                <AlarmsPage />
              </SuspensedView>
            }
          />{' '}
        </Route>
      </Routes>
    )
  }
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='dashboard/group' element={<DashboardWrapper />} />

        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='company/*'
          element={
            <SuspensedView>
              <CompanyPage />
            </SuspensedView>
          }
        />
        <Route
          path='data_files/*'
          element={
            <SuspensedView>
              <DataPage />
            </SuspensedView>
          }
        />
        <Route
          path='data_view/*'
          element={
            <SuspensedView>
              <DataViewPage />
            </SuspensedView>
          }
        />
        <Route
          path='data_requests/*'
          element={
            <SuspensedView>
              <DataRequestsPage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='documents/*'
          element={
            <SuspensedView>
              <DocumentPage />
            </SuspensedView>
          }
        />
        <Route
          path='support/*'
          element={
            <SuspensedView>
              <SupportPage />
            </SuspensedView>
          }
        />
        <Route
          path='alarms/*'
          element={
            <SuspensedView>
              <AlarmsPage />
            </SuspensedView>
          }
        />
        <Route
          path='notifications/*'
          element={
            <SuspensedView>
              <NotificationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='lora/*'
          element={
            <SuspensedView>
              <LoraPage />
            </SuspensedView>
          }
        />
        <Route
          path='secr_reports/*'
          element={
            <SuspensedView>
              <SecrReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='secr_documents/*'
          element={
            <SuspensedView>
              <SecrDocumentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='benchmark/*'
          element={
            <SuspensedView>
              <BenchmarkPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<any> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
