import React, { useState } from 'react'
import { Mail } from './core/_models'
import MailDetail from './MailDetail'

const MailCard: React.FC<Mail> = (mail) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false)
  const handleCardClick = () => {
    setIsDetailOpen((prev) => !prev)
  }
  return (
    <div key={mail.id} className='mail-item border-bottom py-3'>
      <div
        className='d-flex justify-content-between'
        onClick={handleCardClick}
        style={{ cursor: 'pointer' }}
      >
        <div className='col-8'>
          <div className='fw-bold'>{mail.subject}</div>
          <div className='fw-light'>{mail.type === 'inbox' ? mail.from : mail.toRecipients}</div>
        </div>
        <div className='col-4 text-end fs-7'>
          <span className='fw-semibold'>{mail.receivedDateTime}</span>
        </div>
      </div>
      <div className={`mt-2 ${isDetailOpen ? 'd-block' : 'd-none'}`}>
        <MailDetail
          type={mail.type}
          id={mail.id}
          mailId={mail.mailId}
          bodyPreview={mail.bodyPreview}
          body={mail.body}
          from={mail.from}
          toRecipients={mail.toRecipients}
          subject={mail.subject}
          receivedDateTime={mail.receivedDateTime}
          sentDateTime={mail.sentDateTime}
          mailAttachments={mail.mailAttachments}
          sender={mail.sender}
        />
      </div>
    </div>
  )
}

export default MailCard
