import React from 'react'

type Props = {
  meterId: string
  meterType: string
  totalData: string
  site: string
}
const GroupChartDataRow: React.FC<Props> = ({ meterId, meterType, totalData, site }) => {
  let unit = 'kWh'
  /*if (meterType == 'Electricity') {
    unit = 'kWh'
  } else if (meterType == 'Gas') {
    unit = 'm3'
  }*/

  return (
    <div className='d-flex mb-7'>
      <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
        <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
          <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>{meterId}</span>
          <span className='text-gray-400 fw-bold fs-7 my-1'>{site}</span>
        </div>
        <div className='text-end py-lg-0 py-2'>
          <span className='text-gray-800 fw-boldest fs-3'>{totalData}</span>
          <span className='text-gray-400 fs-7 fw-bold d-block'>{unit}</span>
        </div>
      </div>
    </div>
  )
}

export default GroupChartDataRow
