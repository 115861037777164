import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../../utils/Form/FormHandler'
import FileInput from '../../../utils/Form/Widgets/FileInput'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}

class Document {
  document?: number
}

class SecrDocumentEditModal extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new Document() }
  }

  getApiUrl(): string {
    return '/secr_documents/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/secr_documents/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      secr_documents: this.state.entity,
    }
  }

  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Document'
    if (this.state.entity && this.props.itemIdForUpdate !== 'add') {
      let entity = this.state.entity as any
      title = 'View ' + (entity?.file_data?.file_name ?? 'PDF')
    }
    return (
      <Modal
        id='modal_create_document'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <FileInput
                title='Name'
                name='name'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.document}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { SecrDocumentEditModal }
