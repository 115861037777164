import ReactApexChart from 'react-apexcharts'
import { getAlarmModalChartData } from './core/_requests'
import { useEffect, useState } from 'react'
import { Datasets } from '../charts/core/_models'
import { AlarmChartDataType } from './core/_model'
import { useIntl } from 'react-intl'

function ChartForAlarmCommentModal(alarmSite: any) {
  let site = alarmSite.siteValue
  let date = alarmSite.dateValue
  let [data, setData] = useState<AlarmChartDataType>({ labels: [], datasets: [] })
  const [isLoading, setLoading] = useState(true)
  const fetchAlarmChartData = async () => {
    try {
      setTimeout(async () => {
        const data = await getAlarmModalChartData(site, date)
        setData(data)
        setLoading(false)
      }, 2000)
    } catch (error) {
      console.log('Error fetching alarm chart data:', error)
    }
  }
  useEffect(() => {
    if (site && date) {
      fetchAlarmChartData()
    }
  }, [site, date])

  const intl = useIntl()
  const chartData = {
    series:
      data.datasets && data.datasets.length > 0
        ? data.datasets.map((dataset: Datasets) => ({
            name: dataset.name,
            data: dataset.data,
          }))
        : [],
    options: {
      chart: {
        zoom: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 1,
      },
      stroke: {
        curve: 'smooth' as const,
      },
      colors: ['#0070FF', '#FFA500'],
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
      xaxis: {
        categories: data.labels,
        tickAmount: 6,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      height: 350,
    },
  }

  return (
    <div>
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          {intl.formatMessage({ id: 'LOADING' })}...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </div>
      ) : data.datasets && data.datasets.length > 0 ? (
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          //@ts-ignore
          height={chartData.options.height}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default ChartForAlarmCommentModal
