import React, { useEffect, useRef, useState } from 'react'
import ApexCharts from 'apexcharts'
import { useThemeMode } from '../../../../_metronic/partials'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { getSummaryScopes, getSummaryScopesForPdf } from '../../annual-reports/core/_requests'
import { SingleValue } from 'react-select'
import { SelectItem } from '../../charts/core/_models'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  company: SingleValue<SelectItem>
  year: SingleValue<SelectItem>
}

const SummarySectionForPdf: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  company,
  year,
}) => {
  const [labels, setLabels] = useState<string[]>(['Scope 1', 'Scope 2', 'Scope 3'])
  const [tCO2eData, setTCO2eData] = useState<any[]>([])
  const [data, setData] = useState<number[]>([])
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const fetchSummaryScopesData = async () => {
    try {
      const params = {
        isLoggedIn: true,
        company: company,
        reportYear: year,
      }
      const summaryDataResponse = await getSummaryScopesForPdf(params)
      if (summaryDataResponse && summaryDataResponse.length > 0) {
        setTCO2eData(summaryDataResponse)
      }
    } catch (error) {
      console.error('Error fetching summary data:', error)
    }
  }

  useEffect(() => {
    if (company && year) {
      setTimeout(() => {
        fetchSummaryScopesData()
      }, 1000)
    }
  }, [company, year])

  useEffect(() => {
    const chart = refreshChart(tCO2eData, labels)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [tCO2eData, labels, mode])

  const refreshChart = (tCO2eData: number[], labels: string[]) => {
    if (!chartRef.current) return

    const chartOptions = getChartOptions(chartColor, chartHeight, tCO2eData, labels)
    const chart = new ApexCharts(chartRef.current, chartOptions)
    chart.render()

    return chart
  }

  const getChartOptions = (
    chartColor: string,
    chartHeight: string,
    data: number[],
    labels: string[]
  ) => {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const secondaryColor = getCSSVariableValue('--bs-gray-300')
    const baseColor = getCSSVariableValue('--bs-' + chartColor)

    return {
      series: [
        {
          name: 'tCO2e',
          data: data,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: labels,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        type: 'solid',
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val: number) {
            return val + ' tCO2e'
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        padding: {
          top: 10,
        },
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className={`fw-bold text-${chartColor} d-block fs-3`}>2024</span>
          </div>

          <div className={`fw-bold fs-3 text-${chartColor}`}>Summary Chart For Scopes</div>
        </div>
        {/* end::Hidden */}
        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

export { SummarySectionForPdf }
