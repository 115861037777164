import { ReactNode } from 'react'
import BlockInterface, {
  BlockAttributes,
  BlockVariation,
  getAvailableBlocks,
} from '../BlockInterface'
import BlockDeleteButton from '../BlockDeleteButton'
import BlockEditButton from '../BlockEditButton'

export type LayoutBlockAttributes = {
  columns: number
  items: BlockAttributes[][]
}

export default class LayoutBlock implements BlockInterface {
  addToIndex = 0
  public attributes: LayoutBlockAttributes = {
    columns: 1,
    items: [],
  }

  getType(): string {
    return 'layout'
  }

  setAttributes(attributes: LayoutBlockAttributes): void {
    this.attributes = attributes
  }

  getAttributes() {
    return this.attributes
  }

  getName(): string {
    return 'Layout'
  }

  getIconClass(): string {
    return 'fa fa-th-large'
  }

  getModalClasses(): string {
    return ''
  }

  getVariationOptions(): BlockVariation[] {
    return [
      {
        name: '1 column',
        icon: 'fa fa-square',
        attribute: {
          columns: 1,
          items: [[]],
        },
      },
      {
        name: '2 columns',
        icon: 'fa fa-table',
        attribute: {
          columns: 2,
          items: [[], []],
        },
      },
      {
        name: '3 columns',
        icon: 'fa fa-th',
        attribute: {
          columns: 3,
          items: [[], [], []],
        },
      },
    ]
  }

  renderTemplateView(
    onChange: () => void,
    openModal: (callback: (item: BlockAttributes) => void) => void
  ): ReactNode {
    let blocks = getAvailableBlocks()
    return (
      <div className='row p-3'>
        {this.attributes.items.map((col, colIndex) => {
          return (
            <div
              key={colIndex}
              className={
                'border border-warning border-dashed my-3 p-2 min-h-100px ' +
                ('col-' + 12 / this.attributes.columns)
              }
            >
              {col.map((blockAttr, placeIndex) => {
                const block = blocks[blockAttr.type as any]
                if (block) {
                  block.setAttributes(blockAttr.attributes)
                  return (
                    <div key={placeIndex} className='position-relative'>
                      <button
                        className='btn btn-outline btn-outline-dashed btn-active-light-warning text-center w-100 d-flex justify-content-center align-items-center'
                        onClick={(e) => {
                          e.preventDefault()
                          this.addToIndex = placeIndex
                          if (this.addToIndex < 0) {
                            this.addToIndex = 0
                          }
                          openModal((item) => {
                            let data = this.attributes.items[colIndex]
                            this.attributes.items[colIndex] = [
                              ...data.slice(0, this.addToIndex),
                              item,
                              ...data.slice(this.addToIndex),
                            ]
                          })
                        }}
                      >
                        <span className='fa fa-plus fs-6'></span>
                      </button>
                      <BlockDeleteButton
                        onDelete={() => {
                          this.attributes.items[colIndex].splice(placeIndex, 1)
                          onChange()
                        }}
                      />
                      {block.getType() === 'layout' ? (
                        <></>
                      ) : (
                        <BlockEditButton
                          block={{
                            type: block.getType(),
                            attributes: block.getAttributes(),
                          }}
                          onSave={(item: BlockAttributes) => {
                            this.attributes.items[colIndex][placeIndex] = item
                            onChange()
                          }}
                        />
                      )}
                      <div className='p-2 border border-primary border-dashed my-3 '>
                        {block.renderTemplateView(onChange, openModal)}
                      </div>
                    </div>
                  )
                }
                return (
                  <div key={placeIndex} className='p-2 border border-primary border-dashed my-3'>
                    {JSON.stringify(blockAttr)}
                  </div>
                )
              })}
              <button
                className={
                  'btn btn-outline btn-outline-dashed btn-active-light-warning text-center w-100 d-flex justify-content-center align-items-center' +
                  (this.attributes.items[colIndex].length === 0 ? ' h-100' : '')
                }
                onClick={(e) => {
                  e.preventDefault()
                  this.addToIndex = this.attributes.items[colIndex].length
                  openModal((item) => {
                    let data = this.attributes.items[colIndex]
                    this.attributes.items[colIndex] = [
                      ...data.slice(0, this.addToIndex),
                      item,
                      ...data.slice(this.addToIndex),
                    ]
                  })
                }}
              >
                <span className='fa fa-plus fs-6'></span>
              </button>
            </div>
          )
        })}
      </div>
    )
  }

  renderTemplateForm(): ReactNode {
    return <>Form</>
  }

  clone() {
    return new LayoutBlock()
  }
}
