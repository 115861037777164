import Filters from './Filters'
import Pagination from './Pagination'
import ResultViewer from './ResultViewer'

/**
 * Table.
 *
 * @class Table
 * @extends {ResultViewer}
 */
class Table extends ResultViewer {
  render() {
    let limit = this.state.data?.pagination.limit ?? 0
    let page = this.state.page ?? 0
    let totalCount = this.state.data?.pagination.total_count ?? 0
    let viewStart = (page - 1) * limit
    if (viewStart === 0) {
      viewStart = 1
    } else {
      viewStart = viewStart + 1
    }

    let viewEnd = page * limit
    if (viewEnd > totalCount) {
      viewEnd = totalCount
    }
    if (!this.state.data) {
      return <>Loading...</>
    } else {
      return (
        <div className='card mb-5 mb-xl-8'>
          <div className='card-header'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>{this.props.title}</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>
                Out of {this.state.data?.pagination.total_count ?? 0} records {viewStart} -&nbsp;
                {viewEnd ?? 1} is monitoring.
              </span>
            </h3>
            <div className='align-self-end align-self-center'>
              <Filters
                search={this.state.data.search ?? []}
                setFilterParams={(name: string, value: any) => {
                  this.setFilterParams(name, value)
                }}
                applyFilter={() => {
                  this.fetchData()
                }}
                clearFilters={() => {
                  this.clearFilterParams()
                }}
              />
              {this.state.actionItems?.map((item, index) => {
                return (
                  <button
                    key={index}
                    className={item.class}
                    onClick={(event) => {
                      item.onClick(event, this.fetchData)
                    }}
                  >
                    <span className={item.icon + ' me-2'}></span>
                    {item.text}
                  </button>
                )
              })}
            </div>
          </div>
          <div className='card-body overflow-auto'>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
              <thead>
                <tr>{this.renderHeaders()}</tr>
              </thead>
              <tbody>{this.renderRows()}</tbody>
            </table>
          </div>
          <div className='card-footer'>
            <Pagination {...this.state.data.pagination} setPage={this.setPage} />
          </div>
        </div>
      )
    }
  }

  renderHeaders() {
    if (this.state.data) {
      return Object.keys(this.state.data.headers).map((key) => {
        return this.renderHeader(key)
      })
    } else {
      return <></>
    }
  }

  renderHeader(key: string) {
    if (isNaN(parseInt(key))) {
      let direction =
        this.state.orderBy === key && this.state.orderDirection === 'DESC' ? 'ASC' : 'DESC'
      return (
        <th
          key={key}
          role='button'
          onClick={() => this.setOrderBy(key, direction)}
          className='pe-5'
        >
          <div className='d-flex justify-content-between'>
            {(this.state.data?.headers as any)[key]}
            <span
              className={
                'fa ' +
                (this.state.orderBy === key && direction === 'ASC'
                  ? 'fa-arrow-down'
                  : 'fa-arrow-up')
              }
            ></span>
          </div>
        </th>
      )
    }
    return <th key={key}>{(this.state.data?.headers as any)[key]}</th>
  }

  renderRows() {
    if (this.state.data) {
      return this.state.data.data.map((row: any, index) => {
        return (
          <tr key={index}>
            {Object.keys(row).map((key, colIndex) => {
              return this.renderRow(row, key, colIndex)
            })}
          </tr>
        )
      })
    } else {
      return <></>
    }
  }

  renderRow(row: any, key: string, colIndex: number) {
    if (key === 'edit_actions') {
      return (
        <td key={colIndex}>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <button
              onClick={() => this.props.editCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <span className='fa fa-edit'></span>
            </button>
            <button
              onClick={() => this.props.deleteCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
            >
              <span className='fa fa-trash'></span>
            </button>
          </div>
        </td>
      )
    }
    return (
      <td
        key={colIndex}
        dangerouslySetInnerHTML={{
          __html: row[key],
        }}
      ></td>
    )
  }
}

export default Table
