import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import ReactSelect, { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem, SelectItem } from '../../charts/core/_models'
import { companyOptionsList, companySitesList } from '../../charts/core/_requests'

type filterProps = {
  companyFilter: SingleValue<SelectItem>
  setCompanyFilter: Dispatch<SetStateAction<SingleValue<SelectItem>>>
  siteFilter: MultiValue<SelectItem>
  setSiteFilter: Dispatch<SetStateAction<MultiValue<SelectItem>>>
  scopeFilter: SingleValue<SelectItem>
  setScopeFilter: Dispatch<SetStateAction<SingleValue<SelectItem>>>
  yearFilter: SingleValue<SelectItem>
  setYearFilter: Dispatch<SetStateAction<SingleValue<SelectItem>>>
}
const ChartGroupSitesFilter: React.FC<filterProps> = ({
  companyFilter,
  setCompanyFilter,
  siteFilter,
  setSiteFilter,
  scopeFilter,
  setScopeFilter,
  yearFilter,
  setYearFilter,
}) => {
  const [selectedCompany, setSelectedCompany] = useState<SingleValue<CompanyListItem>>(null)
  const [companyOptions, setCompanyOptions] = useState<CompanyListItem[]>([])

  const [companySiteOptions, setCompanySiteOptions] = useState<CompanyListItem[]>([])

  const [selectedScope, setSelectedScope] = useState<SingleValue<CompanyListItem>>(null)

  const scopeOptions = [
    { value: 'Scope 1', label: 'Scope 1' },
    { value: 'Scope 2', label: 'Scope 2' },
    { value: 'Scope 3', label: 'Scope 3' },
  ]

  const [selectedYear, setSelectedYear] = useState<SingleValue<CompanyListItem>>(null)

  const yearOptions = [
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
  ]
  useEffect(() => {
    companyOptionsList().then(setCompanyOptions)
  }, [])

  useEffect(() => {
    setSelectedCompany(companyFilter)
  }, [companyFilter])

  useEffect(() => {
    if (selectedCompany) {
      setSiteFilter([])
      companySitesList({ companyId: Number(selectedCompany.value) }).then(setCompanySiteOptions)
    } else {
    }
  }, [selectedCompany])

  useEffect(() => {
    setSelectedScope(scopeFilter)
  }, [scopeFilter])

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col-lg-3'>
            <label htmlFor='site-select'>Company</label>
            <ReactSelect
              defaultValue={companyFilter}
              onChange={setCompanyFilter}
              options={companyOptions}
              placeholder={companyFilter?.label.toUpperCase()}
              value={selectedCompany}
            />
          </div>
          {companyFilter && (
            <div className='col-lg-3'>
              <label htmlFor='site-select'>Company Sites</label>
              <ReactSelect
                defaultValue={companySiteOptions}
                onChange={setSiteFilter}
                options={companySiteOptions}
                isMulti={true}
                value={siteFilter}
              />
            </div>
          )}
          <div className='col-lg-3'>
            <label htmlFor='site-select'>Scope</label>
            <ReactSelect
              defaultValue={scopeFilter}
              onChange={setScopeFilter}
              options={scopeOptions}
              placeholder={scopeFilter?.label.toUpperCase()}
              value={selectedScope}
            />
          </div>
          <div className='col-lg-3'>
            <label htmlFor='site-select'>Year</label>
            <ReactSelect
              defaultValue={yearFilter}
              onChange={setYearFilter}
              options={yearOptions}
              placeholder={yearFilter?.label.toUpperCase()}
              value={selectedYear}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChartGroupSitesFilter
