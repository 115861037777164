import React from 'react'
import { ResultProps } from '../../../../utils/ResultViewer/ResultViewer'
import Table from '../../../../utils/ResultViewer/Table'

type DataRequestProps = ResultProps & {
  sendCallback: Function
  uploadCallback: Function
}
class DataRequestTable extends Table {
  //@ts-ignore
  public state: DataRequestProps
  constructor(props: DataRequestProps) {
    super(props)
    this.state = {
      title: props.title,
      actionItems: props.actionItems,
      dataUrl: props.dataUrl,
      data: {
        headers: {},
        data: [],
        search: [],
        pagination: {
          limit: 100,
          page: 1,
          total_count: 0,
        },
      },
      page: 1,
      orderBy: '',
      orderDirection: '',
      editCallback: props.editCallback,
      deleteCallback: props.deleteCallback,
      filterParams: {},
      sendCallback: props.sendCallback,
      uploadCallback: props.uploadCallback,
    }
  }

  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key === 'data_files') {
      if (
        row['data_request_status'] == 'data_uploaded' &&
        row['all_data_uploaded'] == true &&
        row[key] != null
      ) {
        return (
          <td key={key}>
            <button
              onClick={() => this.state.uploadCallback(row['edit_actions'], this.fetchData)}
              className='text-end btn btn-link'
            >
              <span className='badge badge-light-primary'>Edit Data</span>
              <i className='fas fa-edit'></i>
            </button>
          </td>
        )
      } else {
        return (
          <td key={key}>
            <button
              onClick={() => this.state.uploadCallback(row['edit_actions'], this.fetchData)}
              className='text-end btn btn-link'
            >
              <span className='badge badge-light-primary'>Upload Data</span>
              <i className='fas fa-file-upload'></i>
            </button>
          </td>
        )
      }
    }
    if (key === 'data_request_status') {
      if (row[key] === 'send_data_request') {
        return (
          <td key={key}>
            <button
              onClick={() => this.state.sendCallback(row['edit_actions'], this.fetchData)}
              className='text-end btn btn-link'
            >
              <span className='badge badge-light-danger'>Send Data Request</span>
              <i className='fas fa-envelope'></i>
            </button>
          </td>
        )
      } else if (row[key] === 'pending') {
        return (
          <td key={key}>
            <button
              onClick={() => this.state.sendCallback(row['edit_actions'], this.fetchData)}
              className='text-end btn btn-link'
            >
              <span className='badge badge-light-warning'>Pending</span>
              <i className='fas fa-clock me-1'></i>
            </button>
          </td>
        )
      } else if (row[key] === 'partial') {
        return (
          <td key={key}>
            <button
              onClick={() => this.state.sendCallback(row['edit_actions'], this.fetchData)}
              className='text-end btn btn-link'
            >
              <span className='badge badge-light-info'>Partial</span>
              <i className='fas fa-clock me-1'></i>
            </button>
          </td>
        )
      } else if (row[key] === 'data_uploaded') {
        return (
          <td key={key}>
            <button
              onClick={() => this.state.uploadCallback(row['edit_actions'], this.fetchData)}
              className='text-end btn btn-link'
            >
              <span className='badge badge-light-success'>Data Uploaded</span>
            </button>
          </td>
        )
      } else {
        return (
          <td key={key}>
            <button
              onClick={() => this.state.sendCallback(row['edit_actions'], this.fetchData)}
              className='text-end btn btn-link'
            >
              <span className='badge badge-light-danger'>Send Data Request</span>
              <i className='fas fa-envelope'></i>
            </button>
          </td>
        )
      }
    } else if (key === 'all_data_uploaded') {
      let badgeClass = 'badge-warning'
      let text = 'Waiting Data'
      switch (row[key]) {
        case 1:
          badgeClass = 'badge-success'
          text = 'All Data Uploaded.'
          break
        case 0:
          badgeClass = 'badge-warning'
          text = 'Waiting Data'
          break
      }
      return (
        <td key={colIndex}>
          <div className={'badge ' + badgeClass}>{text}</div>
        </td>
      )
    }
    return super.renderRow(row, key, colIndex)
  }
}

export default DataRequestTable
