import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../../utils/Form/FormHandler'
import Field from '../../../utils/Form/Widgets/Field'

type Props = {
  onHide: () => void
  show: boolean
  refreshFunction?: Function
}

class CreateEnergyTypeModal extends FormHandler {
  // eslint-disable-next-line
  constructor(props: Props) {
    super(props)
  }

  getApiUrl(): string {
    return '/energy_types/createEnergyType'
  }
  getSingleUrl(): string | null {
    return null
  }

  getPostData(): object {
    return this.state.entity
  }
  render(): ReactNode {
    let title = 'Create Energy Type'
    return (
      <Modal
        id='create_energy_type_modal'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction()
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title='Energy Type'
                className='form-control'
                type='text'
                name='energy_type'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Energy Subtype'
                className='form-control'
                type='text'
                name='energy_subtype'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { CreateEnergyTypeModal }
