import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'

import DocumentTitle from 'react-document-title'
import LoraDashboard from './LoraDashboard'
import { LoraLogsList } from './LoraLogsList'

const loraBreadCrumbs: Array<PageLink> = [
  {
    title: 'Lora',
    path: '/lora/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LoraPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='logs'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Lora Logs'}></DocumentTitle>
              <PageTitle breadcrumbs={loraBreadCrumbs}>Lora Logs</PageTitle>
              <LoraLogsList />
            </>
          }
        />
        <Route
          path='dashboard'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Lora Dashboard'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={loraBreadCrumbs}>Lora Dashboard</PageTitle>
              <LoraDashboard />
            </>
          }
        />
        <Route index element={<Navigate to='/lora/dashboard' />} />
      </Route>
    </Routes>
  )
}

export default LoraPage
