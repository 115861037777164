import Table from './ResultViewer/Table'

class EntityFinderTable extends Table {
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key === 'edit_actions') {
      return (
        <td key={colIndex}>
          <button
            onClick={() => this.props.editCallback(row['edit_actions'], this.fetchData)}
            className='btn btn-primary btn-sm me-1'
          >
            Select
          </button>
        </td>
      )
    } else {
      return super.renderRow(row, key, colIndex)
    }
  }
}

export default EntityFinderTable
