import React, { useState } from 'react'
import moment from 'moment'

interface DatePickerProps {
  onChange: (date: Date | null) => void
  title: string
}

function DatePicker({ onChange, title }: DatePickerProps) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateString = event.target.value
    const date = moment(dateString, 'YYYY-MM-DD', true)
    if (date.isValid()) {
      setSelectedDate(date.toDate())
      if (onChange) {
        onChange(date.toDate())
      }
    } else {
      setSelectedDate(null)
      if (onChange) {
        onChange(null)
      }
    }
  }

  return (
    <div className='position-relative'>
      <span className='fw-bold fs-6 text-gray-400'>{title}</span>
      <input
        type='date'
        className='form-control'
        value={selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : ''}
        onChange={handleDateChange}
      />
      <span
        className='position-absolute end-0 top-50 mt-1 me-3'
        style={{
          cursor: 'pointer',
        }}
      ></span>
    </div>
  )
}

export default DatePicker
