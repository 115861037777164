import ApexChart from 'react-apexcharts'
type Props = {
  date: string
  thisYearData: number[]
  lastYearData: number[]
  alarm: string
}
const CellChart: React.FC<Props> = ({ date, thisYearData, lastYearData, alarm }) => {
  let alarmBackgroundColor = ''
  if (alarm == '1') {
    alarmBackgroundColor = '#ededed'
  }

  return (
    <div className='chart' style={{ backgroundColor: alarmBackgroundColor }}>
      <ApexChart
        options={{
          chart: {
            type: 'line',
            height: '100%',
            width: '100%',
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: 1,
          },
          grid: {
            show: false,
          },
          xaxis: {
            labels: {
              show: false,
            },
            categories: [
              '00:30',
              '01:00',
              '01:30',
              '02:00',
              '02:30',
              '03:00',
              '03:30',
              '04:00',
              '04:30',
              '05:00',
              '05:30',
              '06:00',
              '06:30',
              '07:00',
              '07:30',
              '08:00',
              '08:30',
              '09:00',
              '09:30',
              '10:00',
              '10:30',
              '11:00',
              '11:30',
              '12:00',
              '12:30',
              '13:00',
              '13:30',
              '14:00',
              '14:30',
              '15:00',
              '15:30',
              '16:00',
              '16:30',
              '17:00',
              '17:30',
              '18:00',
              '18:30',
              '19:00',
              '19:30',
              '20:00',
              '20:30',
              '21:00',
              '21:30',
              '22:00',
              '22:30',
              '23:00',
              '23:30',
              '00:00',
            ],
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
          markers: {
            size: 0,
          },
          legend: {
            show: false,
          },
        }}
        series={[
          {
            name: 'This Year',
            data: thisYearData,
          },
          {
            name: 'Last Year',
            data: lastYearData,
          },
        ]}
        type='line'
      />
    </div>
  )
}

export default CellChart
