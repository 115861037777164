import { PropsWithChildren, createContext, useEffect, useState } from 'react'
import { useAuth } from '../auth/core/AuthProvider'
import { getUserSettings, saveUserSettings } from './core/_requests'
import { CustomizationContextType, userSettings, userSettingsParams } from './core/_models'

const CustomizationContext = createContext({})

const CustomizationProvider = ({ children }: PropsWithChildren<{}>) => {
  const { user } = useAuth()
  const [settings, setSettings] = useState<userSettings | undefined>(user?.settings || {})
  const setSettingsAndUpdate = (data: userSettings) => {
    let requestBody = { ...settings, ...data }
    if (requestBody) {
      setSettings(requestBody)
      saveUserSettings({
        userId: user?.userId,
        settings: requestBody,
      })
        .then((response) => {})
        .catch((error) => {
          console.log(error)
        })
    }
  }

  useEffect(() => {
    setSettings(user ? user.settings : {})
  }, [user])
  const values: CustomizationContextType = {
    setSettingsAndUpdate,
    settings,
  }

  return <CustomizationContext.Provider value={values}>{children}</CustomizationContext.Provider>
}

export { CustomizationContext, CustomizationProvider }
