import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../../utils/Form/FormHandler'
import Field from '../../../utils/Form/Widgets/Field'
import AutocompleteSelect from '../../../utils/Form/Widgets/AutocompleteSelect'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}
class Reports {
  title?: string
  template?: string
}
class ReportsAddModal extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new Reports() }
  }

  getApiUrl(): string {
    return '/reports/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/reports/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      reports: this.state.entity,
    }
  }
  render(): ReactNode {
    var state = this.state as any
    let defaultTemplateValue = state.entity.template || 0
    if (this.props.itemIdForUpdate === 'add') {
      defaultTemplateValue = 0
    } else {
      defaultTemplateValue = state.entity.template
    }

    return (
      <Modal
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data?.data?.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === 'add' ? 'Add' : 'Edit'} Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title='Title'
                className='form-control'
                type='text'
                name='title'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.title}
              />
              <AutocompleteSelect
                title='Template'
                name='template'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultTemplateValue}
                autoCompleteTokenUrl='/templates/autocomplete'
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            <button type='submit' className='btn btn-success btn-sm'>
              <span className='fa fa-floppy-save'></span>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { ReportsAddModal }
