import React, { FC, SyntheticEvent, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl'
import DocumentsTable from './DocumentsTable'
import { DocumentEditModal } from './DocumentEditModal'
import { useNavigate, useParams } from 'react-router-dom'

const DocumentsList: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [itemIdForUpdate, setItemIdForUpdate] = useState(params.id !== 'list' ? params.id : '')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const intl = useIntl()
  return (
    <>
      <DocumentsTable
        title={intl.formatMessage({ id: 'REPORTS.DOCUMENTS' })}
        dataUrl='/documents/list'
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForUpdate(id)
          navigate('/documents/' + id)
        }}
        deleteCallback={(id: any, refresh: Function) => {
          axios.get('/documents/save/' + id).then((response) => {
            let csrfData = response.data.data
            Swal.fire({
              title: 'Warning',
              html: 'Are you want to delete this document?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                axios
                  .delete('documents/save/' + id, {
                    data: csrfData,
                  })
                  .then(() => {
                    navigate('/documents/list')
                    refresh()
                  })
              }
            })
          })
        }}
        actionItems={[
          {
            text: intl.formatMessage({ id: 'DOCUMENTS.ADD_NEW_DOCUMENT' }),
            class: 'btn btn-sm btn-primary',
            icon: 'far fa-building',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              setItemIdForUpdate('add')
              setRefreshFunction(() => refresh)
            },
          },
        ]}
      />
      {itemIdForUpdate !== '' ? (
        <DocumentEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onHide={() => {
            setItemIdForUpdate('')
            navigate('/documents/list')
          }}
          refreshFunction={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
              navigate('/documents/list')
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export { DocumentsList }
