import axios, { AxiosResponse } from 'axios'
import { userParams, userSettings, userSettingsParams } from './_models'

export function getUserSettings(params: userParams) {
  const { userId } = params
  const url = `/user_settings/single/${userId}`

  return axios
    .get(url)
    .then((response: AxiosResponse) => {
      if (response.data && response.data.data) {
        return response.data.data
      } else {
        throw new Error('Failed to retrieve user settings.')
      }
    })
    .catch((error) => {
      console.log(error)
      throw new Error('An error occurred while fetching user settings.')
    })
}

export async function saveUserSettings(params: userSettingsParams) {
  const { userId, settings } = params
  const defaultUserSettings: Partial<userSettings> = {
    companyS: settings?.companyS ? settings.companyS : '',
    companySitesS: settings?.companySitesS ? settings.companySitesS : [],
    metersElectricS: settings?.metersElectricS ? settings.metersElectricS : [],
    metersGasS: settings?.metersGasS ? settings.metersGasS : [],
    yesterdayElectricChartS: settings?.yesterdayElectricChartS
      ? settings.yesterdayElectricChartS
      : 'line',
    lastOneWeekElectricChartS: settings?.lastOneWeekElectricChartS
      ? settings.lastOneWeekElectricChartS
      : 'bar',
    lastOneMonthElectricChartS: settings?.lastOneMonthElectricChartS
      ? settings.lastOneMonthElectricChartS
      : 'line',
    lastOneYearElectricChartS: settings?.lastOneYearElectricChartS
      ? settings.lastOneYearElectricChartS
      : 'bar',
    yesterdayGasChartS: settings?.yesterdayGasChartS ? settings.yesterdayGasChartS : 'line',
    lastOneWeekGasChartS: settings?.lastOneWeekGasChartS ? settings.lastOneWeekGasChartS : 'bar',
    lastOneMonthGasChartS: settings?.lastOneMonthGasChartS
      ? settings.lastOneMonthGasChartS
      : 'line',
    lastOneYearGasChartS: settings?.lastOneYearGasChartS ? settings.lastOneYearGasChartS : 'bar',
    companyG: settings ? settings.companyG : '',
    companySitesG: settings ? settings.companySitesG : [],
    metersElectricG: settings ? settings.metersElectricG : [],
    metersGasG: settings ? settings.metersGasG : [],
    yesterdayElectricChartG: settings?.yesterdayElectricChartG
      ? settings.yesterdayElectricChartG
      : 'line',
    lastOneWeekElectricChartG: settings?.lastOneWeekElectricChartG
      ? settings.lastOneWeekElectricChartG
      : 'bar',
    lastOneMonthElectricChartG: settings?.lastOneMonthElectricChartG
      ? settings.lastOneMonthElectricChartG
      : 'line',
    lastOneYearElectricChartG: settings?.lastOneYearElectricChartG
      ? settings.lastOneYearElectricChartG
      : 'bar',
    yesterdayGasChartG: settings?.yesterdayGasChartG ? settings.yesterdayGasChartG : 'line',
    lastOneWeekGasChartG: settings?.lastOneWeekGasChartG ? settings.lastOneWeekGasChartG : 'bar',
    lastOneMonthGasChartG: settings?.lastOneMonthGasChartG
      ? settings.lastOneMonthGasChartG
      : 'line',
    lastOneYearGasChartG: settings?.lastOneYearGasChartG ? settings.lastOneYearGasChartG : 'bar',
  }
  if (!userId) {
    throw new Error('userId is required')
  }

  const url = `/user_settings/save/${userId}`
  const requestBody = {
    users: {
      settings: defaultUserSettings,
    },
  }
  const response = await axios.post(url, requestBody)
  return response
}
