import axios from 'axios'
import { ChangeEventHandler, useEffect, useState } from 'react'

type FileUploadProps = {
  fileUploadKey?: string
  fileUploadKeyUrl?: string
  title: string
  name: string
  defaultValue?: number
  onChange: ChangeEventHandler
  accept?: string
}

function FileInput(props: FileUploadProps) {
  const [fileUploadKey, setFileUploadKey] = useState<any>(props.fileUploadKey)
  const [uploadedFile, setUploadedFile] = useState<any>(null)

  useEffect(() => {
    if (!props.fileUploadKey && props.fileUploadKeyUrl) {
      axios.get(props.fileUploadKeyUrl).then((response) => {
        if (response.data.data) {
          setFileUploadKey(response.data.data)
        }
      })
    }
  }, [props.fileUploadKeyUrl, props.fileUploadKey])

  useEffect(() => {
    if (props.defaultValue && !uploadedFile) {
      axios.get('/files/getFileData/' + props.defaultValue).then((response) => {
        setUploadedFile(response.data.data)
      })
    }
  }, [props.defaultValue, uploadedFile])

  async function uploadNewFiles(files: File[]) {
    for (let file of files) {
      var formData = new FormData()
      formData.append('file', file)
      formData.append('key', fileUploadKey)
      let uploadApi = '/files/uploadFile'
      await axios
        .post(uploadApi, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })
        .then((response) => {
          setUploadedFile(response.data.data)
        })
    }
  }
  useEffect(() => {
    let event: any = {
      target: {
        name: props.name,
        value: uploadedFile?.ID,
      },
    }
    props.onChange(event)
  }, [uploadedFile])
  return (
    <>
      <label className='form-label fw-bolder fs-6 w-100'>{props.title}</label>
      {uploadedFile !== null ? (
        <div>
          {uploadedFile.is_image ? (
            // eslint-disable-next-line
            <a
              href={process.env.REACT_APP_API_BASE_URL + 'files/uploaded/' + uploadedFile.file_path}
              target='_blank'
            >
              <img
                src={
                  process.env.REACT_APP_API_BASE_URL + 'files/uploaded/' + uploadedFile.file_path
                }
                className='img-thumbnail ms-2 w-200px rounded'
                alt={uploadedFile.file_name}
              />
            </a>
          ) : (
            <>
              <a
                href={
                  process.env.REACT_APP_API_BASE_URL + 'files/uploaded/' + uploadedFile.file_path
                }
                target='_blank'
                className='fw-bolder'
              >
                <span className={uploadedFile.icon_class + ' fs-2 mx-2 text-primary'}></span>
                {uploadedFile.file_name} ({uploadedFile.file_size})
              </a>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
      <label className='btn btn-sm btn-primary text-light m-2'>
        <span className='fa fa-file-upload'></span>
        Upload File
        <input
          className='d-none'
          type='file'
          accept={props.accept}
          onChange={(event: any) => {
            event.preventDefault()
            uploadNewFiles(event.target.files)
          }}
        />
      </label>
    </>
  )
}
export default FileInput
