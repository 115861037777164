import { useEffect, useState } from 'react'
import AddTemplateWidgetModal from './AddTemplateWidgetModal'
import BlockInterface, { BlockAttributes, getAvailableBlocks } from './BlockInterface'
import BlockDeleteButton from './BlockDeleteButton'
import BlockEditModal from './BlockEditModal'
import PreviewOffcanvas from './PreviewOffcanvas'

type TemplateGeneratorWidgetProps = {
  name: string
  title: string
  defaultValue?: string
  onChange: Function
  company?: number
}

var addToIndex = 0
var callback: null | ((item: BlockAttributes) => void) = null

export default function TemplateGeneratorWidget(props: TemplateGeneratorWidgetProps) {
  let [showModal, setShowModal] = useState(false)
  let [filters, setFilters] = useState(['layout'])
  let [data, setData] = useState<BlockAttributes[]>([])
  let [buildData, setBuildData] = useState<BlockInterface[]>([])

  let [blockToUpdate, setBlockToUpdate] = useState<BlockAttributes | null>(null)
  useEffect(() => {
    if (props.defaultValue) {
      setData(JSON.parse(props.defaultValue))
    } else {
      setData([])
    }
  }, [props.defaultValue])
  useEffect(() => {
    let newBuildData: BlockInterface[] = []
    data.forEach((item: BlockAttributes, index) => {
      let block = getAvailableBlocks()[item.type as any].clone()
      if (block) {
        block.setAttributes(item.attributes)
        newBuildData.push(block)
      }
    })
    setBuildData(newBuildData)
  }, [data])

  function getDataFromBuildData() {
    return buildData.map((block: BlockInterface): BlockAttributes => {
      return {
        type: block.getType(),
        attributes: block.getAttributes(),
      }
    })
  }
  return (
    <>
      <PreviewOffcanvas body={data} company={props.company} />
      <div className='fv-row my-5 position-relative p-3'>
        <label className='form-label'>{props.title}</label>
        {buildData.map((block: BlockInterface, index) => {
          return (
            <div key={index}>
              <button
                className='btn btn-outline btn-outline-dashed btn-active-light-warning text-center w-100 d-flex justify-content-center align-items-center'
                onClick={(e) => {
                  e.preventDefault()
                  addToIndex = index
                  setFilters(['layout'])
                  setShowModal(true)
                }}
              >
                <span className='fa fa-plus fs-6'></span>
              </button>
              <BlockDeleteButton
                onDelete={() => {
                  buildData.splice(index, 1)
                  let newData = getDataFromBuildData()
                  setData(newData)
                  let event = {
                    target: {
                      name: props.name,
                      value: JSON.stringify(newData),
                    },
                  }
                  props.onChange(event)
                }}
              />
              {block.renderTemplateView(
                () => {
                  let newData = getDataFromBuildData()
                  setData(newData)
                  let event = {
                    target: {
                      name: props.name,
                      value: JSON.stringify(newData),
                    },
                  }
                  props.onChange(event)
                },
                (callbackF) => {
                  setFilters([])
                  setShowModal(true)
                  callback = callbackF
                }
              )}
            </div>
          )
        })}
        <div
          className='btn btn-outline btn-outline-dashed btn-active-light-warning text-center w-100 min-h-100px d-flex justify-content-center align-items-center'
          onClick={() => {
            addToIndex = data.length
            setFilters(['layout'])
            setShowModal(true)
          }}
        >
          <span className='fa fa-plus fs-1'></span>
        </div>
      </div>
      <AddTemplateWidgetModal
        show={showModal}
        blocks={filters}
        onHide={() => {
          setShowModal(false)
        }}
        onSelect={(item: BlockAttributes) => {
          let newData: any = null
          if (callback) {
            if (item.type === 'layout') {
              callback(item)
              callback = null
              newData = getDataFromBuildData()
            } else {
              setBlockToUpdate(item)
            }
          } else {
            newData = [...data.slice(0, addToIndex), item, ...data.slice(addToIndex)]
          }
          if (newData) {
            let event = {
              target: {
                name: props.name,
                value: JSON.stringify(newData),
              },
            }
            props.onChange(event)
          }
        }}
      />

      <BlockEditModal
        show={blockToUpdate !== null}
        onHide={() => {
          setBlockToUpdate(null)
        }}
        onSave={(item: BlockAttributes) => {
          setBlockToUpdate(null)
          if (callback) {
            callback(item)
            callback = null
            let newData = getDataFromBuildData()
            let event = {
              target: {
                name: props.name,
                value: JSON.stringify(newData),
              },
            }
            props.onChange(event)
          }
        }}
        block={blockToUpdate as BlockAttributes}
      />
    </>
  )
}
