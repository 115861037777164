import { ReactNode } from 'react'
import FormHandler from '../../../utils/Form/FormHandler'
import { FormGroup } from 'react-bootstrap'
import AutocompleteSelect from '../../../utils/Form/Widgets/AutocompleteSelect'
import { Link } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import Field from '../../../utils/Form/Widgets/Field'
import TemplateGeneratorWidget from '../../../utils/Template/TemplateGeneratorWidget'
import axios from 'axios'
import PreviewOffcanvas from '../../../utils/Template/PreviewOffcanvas'

class Reports {
  title?: string
  template?: string
  company?: number
  body?: string
}

type Props = {
  itemIdForUpdate?: string
  refreshFunction?: Function
}

class TemplateReportEditForm extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new Reports() }
  }

  getApiUrl(): string {
    return '/reports/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/reports/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      reports: this.state.entity,
    }
  }

  async handleSendToApprove(event: any) {
    await this.handleSubmit(event)
    axios.get('/reports/sendToApprove/' + this.props.itemIdForUpdate).then((response) => {
      this.getObjectData()
    })
  }

  async handleRequestChanges(event: any) {
    await this.handleSubmit(event)
    axios.get('/reports/requestChanges/' + this.props.itemIdForUpdate).then((response) => {
      this.getObjectData()
    })
  }

  async handleGenerateReport(event: any) {
    await this.handleSubmit(event)
    axios.get('/reports/generateReport/' + this.props.itemIdForUpdate).then((response) => {
      this.getObjectData()
    })
  }

  render(): ReactNode {
    var state = this.state as any
    let defaultCompanyValue = state.entity.company || 0
    if (this.props.itemIdForUpdate === 'add') {
      defaultCompanyValue = 0
    } else {
      defaultCompanyValue = state.entity.company
    }

    let title = (this.props.itemIdForUpdate === 'add' ? 'Add' : 'Edit') + ' Report'
    return (
      <div className='card mb-5 mb-xl-8'>
        <DocumentTitle title={process.env.REACT_APP_NAME + ' | ' + title} />
        <Link to={'/reports/template-reports'} className='btn btn-light-primary w-100px mt-5 ms-8'>
          <span className='fa fa-chevron-left'></span>
          Back
        </Link>
        <div className='card-header'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </h3>
        </div>
        <div className='card-body overflow-auto'>
          <form
            onSubmit={(event) => {
              this.handleSubmit(event).then((response) => {
                if (this.props.refreshFunction) {
                  this.props.refreshFunction(response.data.data.entity_id)
                }
              })
            }}
          >
            <h2 className='fs-1 text-primary mb-3'>
              Template: {state.entity.template_data?.title}
            </h2>
            <h2 className='fs-1 text-primary mb-3'>Status: {state.entity.status}</h2>
            <FormGroup>
              <Field
                title='Title'
                className='form-control'
                type='text'
                name='title'
                disabled={state.entity.status !== 'draft'}
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.title}
              />
            </FormGroup>
            <FormGroup>
              <AutocompleteSelect
                title='Company'
                name='company'
                isDisabled={state.entity.status !== 'draft'}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultCompanyValue}
                autoCompleteTokenUrl='/company/autocomplete'
              />
            </FormGroup>
            {state.entity.status === 'draft' ? (
              <FormGroup>
                <TemplateGeneratorWidget
                  title='Body'
                  name='body'
                  defaultValue={state.entity.body}
                  company={state.entity.company}
                  onChange={(event: any) => {
                    this.handleInputChange(event)
                  }}
                />
              </FormGroup>
            ) : state.entity.status === 'in_approve' ? (
              <>
                <PreviewOffcanvas
                  body={state.entity.body ? JSON.parse(state.entity.body) : []}
                  company={state.entity.company}
                  previewMode='pdf'
                />
                <button
                  type='button'
                  className='btn btn-danger btn-sm'
                  onClick={() => {
                    document.getElementById('previewOffcanvasButton')?.click()
                  }}
                >
                  <span className='fa fa-file-pdf me-2'></span>
                  <span className=''>Preview Document</span>
                </button>
              </>
            ) : (
              <Link to={'/documents/' + state.entity.document} className='btn btn-danger btn-sm'>
                <span className='fa fa-file-pdf me-2'></span>
                <span className=''>View Document</span>
              </Link>
            )}
            <div className='position-fixed bottom-0 mb-20 end-0 me-20'>
              {state.entity.status === 'draft' ? (
                <button
                  type='button'
                  className='btn btn-success btn-sm'
                  onClick={(event) => {
                    this.handleSendToApprove(event)
                  }}
                >
                  <span className='me-2'>Send to approve</span>
                  <span className='fa fa-chevron-right'></span>
                </button>
              ) : state.entity.status === 'in_approve' ? (
                <>
                  <button
                    type='button'
                    className='btn btn-warning btn-sm me-2'
                    onClick={(event) => {
                      this.handleRequestChanges(event)
                    }}
                  >
                    <span className='me-2'>Request changes</span>
                    <span className='fa fa-chevron-left'></span>
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary btn-sm'
                    onClick={(event) => {
                      this.handleGenerateReport(event)
                    }}
                  >
                    <span className='fa fa-hdd me-2'></span>
                    <span className=''>Generate report</span>
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className='position-fixed bottom-0 mb-8 end-0 me-20'>
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm me-2'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
              <button type='submit' className='btn btn-success btn-sm'>
                <span className='fa fa-floppy-save'></span>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default TemplateReportEditForm
