import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import CalendarViewPage from './calendar/CalendarViewPage'
import AnalyticsPage from '../analytics/AnalyticsPage'
import { useIntl } from 'react-intl'
import { ReportsList } from './reports/ReportsList'
import { TemplatesList } from './reports/TemplatesList'
import TemplateEdit from './reports/TemplateEdit'
import TemplateReportEdit from './reports/TemplateReportEdit'

const reportsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Reports',
    path: '/reports/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportsPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='templates'
          element={
            <>
              <DocumentTitle
                title={
                  process.env.REACT_APP_NAME +
                  ' | ' +
                  intl.formatMessage({ id: 'REPORTS.TEMPLATES' })
                }
              ></DocumentTitle>
              <PageTitle breadcrumbs={reportsBreadcrumbs}>
                {intl.formatMessage({ id: 'REPORTS.TEMPLATES' })}
              </PageTitle>
              <TemplatesList />
            </>
          }
        />
        <Route path='/templates/:id' Component={TemplateEdit} />
        <Route
          path='template-reports'
          element={
            <>
              <DocumentTitle
                title={
                  process.env.REACT_APP_NAME + ' | ' + intl.formatMessage({ id: 'REPORTS.REPORTS' })
                }
              ></DocumentTitle>
              <PageTitle breadcrumbs={reportsBreadcrumbs}>
                {intl.formatMessage({ id: 'REPORTS.REPORTS' })}
              </PageTitle>
              <ReportsList />
            </>
          }
        />
        <Route path='/template-reports/:id' Component={TemplateReportEdit} />
        <Route
          path='calendar'
          element={
            <>
              <DocumentTitle
                title={
                  process.env.REACT_APP_NAME +
                  ' | ' +
                  intl.formatMessage({ id: 'REPORTS.CALENDARVIEW' })
                }
              ></DocumentTitle>
              <PageTitle breadcrumbs={reportsBreadcrumbs}>
                {intl.formatMessage({ id: 'REPORTS.CALENDARVIEW' })}
              </PageTitle>
              <CalendarViewPage />
            </>
          }
        />
        <Route
          path='analytics-charts'
          element={
            <>
              <DocumentTitle
                title={
                  process.env.REACT_APP_NAME +
                  ' | ' +
                  intl.formatMessage({ id: 'REPORTS.ANALYTICSCHART' })
                }
              ></DocumentTitle>
              <PageTitle breadcrumbs={reportsBreadcrumbs}>
                {intl.formatMessage({ id: 'REPORTS.ANALYTICSCHART' })}
              </PageTitle>
              <AnalyticsPage />
            </>
          }
        />

        <Route index element={<Navigate to='/reports/templates' />} />
      </Route>
    </Routes>
  )
}

export default ReportsPage
