import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Select, { MultiValue, SingleValue } from 'react-select'
import { useIntl } from 'react-intl'
import { CompanyListItem, SelectItem } from '../../charts/core/_models'
import { companyOptionsList, companySitesList } from '../../charts/core/_requests'
import { Button } from 'react-bootstrap'
import { DateRangeSelector } from '../../../utils/Form/Widgets/DateRangeInput'
import moment from 'moment'
import { Range } from 'react-date-range'
import { getSiteFeatureOptions } from '../core/_requests'

type Props = {
  companyFilter: SingleValue<SelectItem>
  setCompanyFilter: Dispatch<SetStateAction<SingleValue<SelectItem>>>
  siteFilter: MultiValue<SelectItem>
  setSiteFilter: Dispatch<SetStateAction<MultiValue<SelectItem>>>
  period: string
  setPeriod: Dispatch<SetStateAction<string>>
  startDate: moment.Moment
  setStartDate: Dispatch<SetStateAction<moment.Moment>>
  endDate: moment.Moment
  setEndDate: Dispatch<SetStateAction<moment.Moment>>
  selectedFeature: string
  setSelectedFeature: Dispatch<SetStateAction<string>>
  setSelectedFeatureName: Dispatch<
    SetStateAction<{
      name: string
      unit: string
    }>
  >
}
const BenchmarkFilter: React.FC<Props> = ({
  companyFilter,
  setCompanyFilter,
  siteFilter,
  setSiteFilter,
  period,
  setPeriod,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedFeature,
  setSelectedFeature,
  setSelectedFeatureName,
}) => {
  const [selectedCompany, setSelectedCompany] = useState<SingleValue<CompanyListItem>>(null)
  const [selectedSite, setSelectedSite] = useState<MultiValue<CompanyListItem>>([])
  const [companyOptions, setCompanyOptions] = useState<CompanyListItem[]>([])
  const [companySiteOptions, setCompanySiteOptions] = useState<CompanyListItem[]>([])
  const [rangeStart, setRangeStart] = useState<moment.Moment>(moment())
  const [rangeEnd, setRangeEnd] = useState<moment.Moment>(moment())
  const [featureOptions, setFeatureOptions] = useState<any>([])

  const intl = useIntl()

  const fetchCompanySiteFeatures = async (companyId: number) => {
    try {
      const response = await getSiteFeatureOptions(companyId).then((response) => {
        if (!response) {
          setFeatureOptions([])
        } else {
          setFeatureOptions(response)
        }
      })
    } catch (error) {
      console.error('Failed to fetch features:', error)
      return []
    }
  }

  useEffect(() => {
    companyOptionsList().then(setCompanyOptions)
  }, [])

  useEffect(() => {
    setSelectedCompany(companyFilter)
    if (companyFilter) {
      fetchCompanySiteFeatures(Number(companyFilter.value))
    }
  }, [companyFilter])

  useEffect(() => {
    if (selectedCompany) {
      companySitesList({ companyId: Number(selectedCompany.value) }).then(setCompanySiteOptions)
    }
  }, [selectedCompany])

  useEffect(() => {
    if (companySiteOptions) {
      setSiteFilter(companySiteOptions)
    }
  }, [companySiteOptions])

  useEffect(() => {
    setSelectedSite(siteFilter)
  }, [siteFilter])
  let placeholderSite = intl.formatMessage({ id: 'SELECTSITES' })

  const handlePeriodChange = (period: string) => () => {
    setPeriod(period)
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col-lg-3'>
            <label htmlFor='site-select'>
              {' '}
              {intl.formatMessage({ id: 'FILTERFORM.COMPANY' })}:
            </label>
            <Select
              defaultValue={companyOptions.find((option) => option.value === companyFilter?.value)}
              onChange={setCompanyFilter}
              options={companyOptions}
              placeholder={'Select Company'}
              value={selectedCompany}
            />
          </div>

          {companyFilter && (
            <div className='col-lg-3'>
              <label htmlFor='site-select'>
                {intl.formatMessage({ id: 'FILTERFORM.SITEELECT' })}:
              </label>
              <Select
                defaultValue={companySiteOptions}
                onChange={setSiteFilter}
                options={companySiteOptions}
                isMulti={true}
                placeholder={placeholderSite}
                value={siteFilter}
              />
            </div>
          )}
          {companyFilter && (
            <div className='col-lg-3'>
              <label htmlFor='period-buttons'>
                {intl.formatMessage({ id: 'BENCHMARK.PERIOD' })}:
              </label>
              <div id='period-buttons' className='d-inline-flex' style={{ gap: '10px' }}>
                <Button
                  variant='secondary'
                  style={{ minWidth: '120px', maxHeight: '40px' }}
                  onClick={handlePeriodChange('this_year')}
                >
                  {intl.formatMessage({ id: 'BENCHMARK.THIS_YEAR' })}
                </Button>
                <Button
                  variant='secondary'
                  style={{ minWidth: '120px', maxHeight: '40px' }}
                  onClick={handlePeriodChange('last_one_month')}
                >
                  {intl.formatMessage({ id: 'BENCHMARK.LAST_MONTH' })}
                </Button>
                <Button
                  variant='secondary'
                  style={{ minWidth: '120px', maxHeight: '40px' }}
                  onClick={handlePeriodChange('last_one_week')}
                >
                  {intl.formatMessage({ id: 'BENCHMARK.LAST_WEEK' })}
                </Button>
                <Button
                  variant='secondary'
                  style={{ minWidth: '120px', maxHeight: '40px' }}
                  onClick={handlePeriodChange('custom')}
                >
                  {intl.formatMessage({ id: 'BENCHMARK.CUSTOM' })}
                </Button>
              </div>
              {period === 'custom' && (
                <div style={{ minWidth: '250px', height: '60px', marginTop: '10px' }}>
                  <DateRangeSelector
                    name='custom_range'
                    title='Date Range'
                    seperator='to'
                    ranges={[
                      {
                        startDate: rangeStart.toDate(),
                        endDate: rangeEnd.toDate(),
                      },
                    ]}
                    rangeSelected={(range: Range) => {
                      setStartDate(moment(range.startDate))
                      setEndDate(moment(range.endDate))
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {companyFilter && period && (
        <div className='card-body'>
          <div className='row'>
            {/* Feature Checkboxes */}
            <div className='col-lg-12'>
              {featureOptions != null ? (
                <label className='d-block mb-2'>Select Parameter for Benchmark:</label>
              ) : (
                <label className='d-block mb-2'>
                  No Benchmark Parameters Available For This Company
                </label>
              )}

              <div className='d-flex'>
                {featureOptions != null &&
                  featureOptions.map((feature: any) => (
                    <div key={feature.value} className='form-check me-3'>
                      <input
                        type='radio'
                        id={`radio-${feature.value}`}
                        name='feature'
                        checked={selectedFeature === feature.value}
                        onChange={() => {
                          setSelectedFeature(feature.value)
                          setSelectedFeatureName({ name: feature.label, unit: feature.unit })
                        }}
                        className='form-check-input'
                      />
                      <label htmlFor={`radio-${feature.value}`} className='form-check-label'>
                        {feature.label}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default BenchmarkFilter
