import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import { DataViewList } from './components/DataViewList'

const dataViewsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Data View',
    path: '/data_view/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DataViewPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Data View'}></DocumentTitle>
              <PageTitle breadcrumbs={dataViewsBreadcrumbs}>View</PageTitle>
              <DataViewList />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default DataViewPage
