/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useThemeMode } from '../../../../_metronic/partials'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { summaryChartDataSeries } from '../core/_models'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  company: string | any
  year: string | any
  scope: summaryChartDataSeries
  unit: string
  notes: string
}

const SummaryBox: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  company,
  year,
  scope,
  unit,
  notes,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const refreshChart = (data: number, unit: string) => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(data, unit, chartColor, chartHeight)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart(scope.total, unit)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, scope, unit])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{scope.scope ?? 'Scope 1'}</span>
        </h3>

        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{year}</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>

        <div className='pt-5'>
          <p className='text-center fs-6 pb-5'>
            <span className='badge badge-light-danger fs-8'>Notes:</span>&nbsp; {notes}
          </p>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (
  data: number,
  unit: string,
  chartColor: string,
  chartHeight: string
): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--bs-gray-700')
  return {
    series: [data],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '12px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + ' ' + unit
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
  }
}

export { SummaryBox }
