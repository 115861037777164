import ReactApexChart from 'react-apexcharts'
import { ChartData, Datasets } from '../core/_models'
import { useContext, useEffect, useState } from 'react'
import { CustomizationContext } from '../../customization/CustomizationContext'
import { useIntl } from 'react-intl'

const LineChart: React.FC<ChartData> = ({ labels, datasets, meterType }) => {
  const dashboardType = window.location.pathname === '/dashboard' ? 'single' : 'group'
  // @ts-ignore
  const { setSettingsAndUpdate, settings } = useContext(CustomizationContext)
  const availableGraphTypes = [
    { value: 'line', label: 'Line' },
    { value: 'area', label: 'Area' },
    { value: 'radar', label: 'Radar' },
    { value: 'bar', label: 'Bar' },
  ]
  const intl = useIntl()
  const [graphType, setGraphType] = useState<string>('line')
  useEffect(() => {
    const defaultGraphType = getDefaultGraphType(labels.length)
    setGraphType(defaultGraphType)
  }, [labels])

  const getDefaultGraphType = (labelsLength: number) => {
    let yesterdayType = 'line'
    let oneWeekType = 'bar'
    let oneMonthType = 'line'
    let oneYearType = 'bar'
    if (dashboardType == 'single') {
      if (meterType == 'electricity') {
        yesterdayType = settings.yesterdayElectricChartS ? settings.yesterdayElectricChartS : 'line'
        oneWeekType = settings.lastOneWeekElectricChartS
          ? settings.lastOneWeekElectricChartS
          : 'bar'
        oneMonthType = settings.lastOneMonthElectricChartS
          ? settings.lastOneMonthElectricChartS
          : 'line'
        oneYearType = settings.lastOneYearElectricChartS
          ? settings.lastOneYearElectricChartS
          : 'bar'
      } else if ('gas') {
        yesterdayType = settings.yesterdayGasChartS ? settings.yesterdayGasChartS : 'line'
        oneWeekType = settings.lastOneWeekGasChartS ? settings.lastOneWeekGasChartS : 'bar'
        oneMonthType = settings.lastOneMonthGasChartS ? settings.lastOneMonthGasChartS : 'line'
        oneYearType = settings.lastOneYearGasChartS ? settings.lastOneYearGasChartS : 'bar'
      }
    } else {
      if (meterType == 'electricity') {
        yesterdayType = settings.yesterdayElectricChartG ? settings.yesterdayElectricChartG : 'line'
        oneWeekType = settings.lastOneWeekElectricChartG
          ? settings.lastOneWeekElectricChartG
          : 'bar'
        oneMonthType = settings.lastOneMonthElectricChartG
          ? settings.lastOneMonthElectricChartG
          : 'line'
        oneYearType = settings.lastOneYearElectricChartG
          ? settings.lastOneYearElectricChartG
          : 'bar'
      } else if ('gas') {
        yesterdayType = settings.yesterdayGasChartG ? settings.yesterdayGasChartG : 'line'
        oneWeekType = settings.lastOneWeekGasChartG ? settings.lastOneWeekGasChartG : 'bar'
        oneMonthType = settings.lastOneMonthGasChartG ? settings.lastOneMonthGasChartG : 'line'
        oneYearType = settings.lastOneYearGasChartG ? settings.lastOneYearGasChartG : 'bar'
      }
    }
    if (labelsLength == 48) {
      //yesterday
      return yesterdayType
    } else if (labelsLength == 7) {
      // one week
      return oneWeekType
    } else if (labelsLength == 30) {
      //one month
      return oneMonthType
    } else if (labelsLength == 12) {
      //one year
      return oneYearType
    } else {
      return 'line'
    }
  }
  const setNewGraphType = (type: string) => {
    if (dashboardType == 'single') {
      if (meterType == 'electricity') {
        setSettingsAndUpdate({
          yesterdayElectricChartS: labels.length === 48 ? type : settings.yesterdayElectricChartS,
          lastOneWeekElectricChartS:
            labels.length === 7 ? type : settings.lastOneWeekElectricChartS,
          lastOneMonthElectricChartS:
            labels.length === 30 ? type : settings.lastOneMonthElectricChartS,
          lastOneYearElectricChartS:
            labels.length === 12 ? type : settings.lastOneYearElectricChartS,
        })
      } else if (meterType == 'gas') {
        setSettingsAndUpdate({
          yesterdayGasChartS: labels.length === 48 ? type : settings.yesterdayGasChartS,
          lastOneWeekGasChartS: labels.length === 7 ? type : settings.lastOneWeekGasChartS,
          lastOneMonthGasChartS: labels.length === 30 ? type : settings.lastOneMonthGasChartS,
          lastOneYearGasChartS: labels.length === 12 ? type : settings.lastOneYearGasChartS,
        })
      }
    } else {
      if (meterType == 'electricity') {
        setSettingsAndUpdate({
          yesterdayElectricChartG: labels.length === 48 ? type : settings.yesterdayElectricChartG,
          lastOneWeekElectricChartG:
            labels.length === 7 ? type : settings.lastOneWeekElectricChartG,
          lastOneMonthElectricChartG:
            labels.length === 30 ? type : settings.lastOneMonthElectricChartG,
          lastOneYearElectricChartG:
            labels.length === 12 ? type : settings.lastOneYearElectricChartG,
        })
      } else if (meterType == 'gas') {
        setSettingsAndUpdate({
          yesterdayGasChartG: labels.length === 48 ? type : settings.yesterdayGasChartG,
          lastOneWeekGasChartG: labels.length === 7 ? type : settings.lastOneWeekGasChartG,
          lastOneMonthGasChartG: labels.length === 30 ? type : settings.lastOneMonthGasChartG,
          lastOneYearGasChartG: labels.length === 12 ? type : settings.lastOneYearGasChartG,
        })
      }
    }
    setGraphType(type)
  }

  const chartData = {
    series: [
      ...datasets.map((dataset: Datasets) => ({
        name: dataset.name,
        data: dataset.data,
      })),
    ],
    options: {
      chart: {
        zoom: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 1,
      },
      stroke: {
        curve: 'smooth' as const,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
      xaxis: {
        categories: labels,
        tickAmount: 6,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      height: 350,
    },
  }

  const renderChart = () => {
    const chartType = availableGraphTypes.find((type) => type.value === graphType)?.value

    return (
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        //@ts-ignore
        type={chartType}
        //@ts-ignore
        height={chartData.options.height}
      />
    )
  }

  return (
    <div className='w-100'>
      <select
        className='form-select form-select-sm w-125px'
        value={graphType}
        onChange={(e) => setNewGraphType(e.target.value)}
      >
        <option value=''>{intl.formatMessage({ id: 'LINECHART.SELECTCHARTTYPE' })}</option>
        {availableGraphTypes.map((type) => (
          <option key={type.value} value={type.value}>
            {intl.formatMessage({ id: type.value })}
          </option>
        ))}
      </select>
      {renderChart()}
    </div>
  )
}

export default LineChart
