import React, { useEffect, useState } from 'react'
import BenchmarkFilter from './BenchmarkFilter'
import { SingleValue, MultiValue } from 'react-select'
import { CompanyListItem, SelectItem } from '../../charts/core/_models'
import moment from 'moment'
import BenchmarkTable from './BenchmarkTable'
import { benchmarkList } from '../core/_requests'
function BenchmarkList() {
  let [companyFilter, setCompanyFilter] = useState<SingleValue<CompanyListItem>>(null)
  let [siteFilter, setSiteFilter] = useState<MultiValue<CompanyListItem>>([])
  let [period, setPeriod] = useState<string>('this_year')
  let [startDate, setStartDate] = useState<moment.Moment>(moment())
  let [endDate, setEndDate] = useState<moment.Moment>(moment())
  let [isLoading, setIsLoading] = useState<boolean>(false)
  let [listData, setListData] = useState<any>([])
  let [selectedFeature, setSelectedFeature] = useState<string>('')
  let [selectedFeatureName, setSelectedFeatureName] = useState<{
    name: string
    unit: string
  }>({
    name: '',
    unit: '',
  })
  const getDateRangeLabel = () => {
    if (period === 'custom') {
      return `${startDate.format('DD MMMM YYYY')} - ${endDate.format('DD MMMM YYYY')}`
    }
    if (period === 'this_year') {
      return `${moment().startOf('year').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    if (period === 'last_one_month') {
      return `${moment().subtract(1, 'month').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    if (period === 'last_one_week') {
      return `${moment().subtract(1, 'week').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    return ''
  }
  const fetchListData = async () => {
    try {
      setIsLoading(true)
      const params = {
        isLoggedIn: true,
        companyId: Number(companyFilter?.value),
        siteId: siteFilter,
        period: period,
        customStartDate: startDate.format('YYYY-MM-DD'),
        customEndDate: endDate.format('YYYY-MM-DD'),
        siteFeature: selectedFeature,
      }
      const listData = await benchmarkList(params)
      setListData(listData)
    } catch (error) {
      console.error('Error fetching chart data:', error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (companyFilter && selectedFeature) {
      fetchListData()
    }
  }, [companyFilter, siteFilter, selectedFeature])

  useEffect(() => {
    if (companyFilter && selectedFeature && period === 'custom' && startDate && endDate) {
      fetchListData()
    } else if (companyFilter && selectedFeature && period !== 'custom') {
      fetchListData()
    }
  }, [startDate, endDate, period])

  useEffect(() => {
    setSiteFilter([])
  }, [companyFilter])
  return (
    <>
      <div className='row mb-2'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'>
                <i className='bi bi-calendar'></i> {getDateRangeLabel()}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <BenchmarkFilter
        companyFilter={companyFilter}
        setCompanyFilter={setCompanyFilter}
        siteFilter={siteFilter}
        setSiteFilter={setSiteFilter}
        period={period}
        setPeriod={setPeriod}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        setSelectedFeatureName={setSelectedFeatureName}
      />
      <br></br>
      <BenchmarkTable
        parameter={selectedFeatureName}
        data={listData}
        company={companyFilter?.value || ''}
        start_date={startDate.format('YYYY-MM-DD')}
        end_date={endDate.format('YYYY-MM-DD')}
      />
    </>
  )
}

export default BenchmarkList
