import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../../utils/Form/FormHandler'
import Select from '../../../utils/Form/Widgets/Select'
import { selectOptionsRange } from '../../../utils/utils'
import AutocompleteSelect from '../../../utils/Form/Widgets/AutocompleteSelect'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}
class SecrReport {
  company?: number
  report_year?: string
  executive_summary_section?: string
  data_reporting_section?: string
  methodology_section?: string
  environmental_strategy_section?: string
  target_setting_section?: string
  verification_section?: string
  appendices_section?: string
}
class SecrReportsEditModal extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new SecrReport() }
  }

  getApiUrl(): string {
    return '/secr_reports/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/secr_reports/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      secr_reports: this.state.entity,
    }
  }
  render(): ReactNode {
    var state = this.state as any
    let defaultCompanyValue = state.entity.company || 0
    if (this.props.itemIdForUpdate === 'add') {
      defaultCompanyValue = 0
    } else {
      defaultCompanyValue = state.entity.company
    }
    return (
      <Modal
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === 'add' ? 'Add' : 'Edit'} Secr Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <FormGroup>
                <AutocompleteSelect
                  title='Company'
                  name='company'
                  onChange={(event: any) => {
                    this.handleInputChange(event)
                  }}
                  defaultValue={defaultCompanyValue}
                  autoCompleteTokenUrl='/company/autocomplete'
                />
              </FormGroup>
              <Select
                title='Report Year'
                name='report_year'
                options={selectOptionsRange(2021, 2025)}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.report_year}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              <span className='fa fa-floppy-save'></span>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { SecrReportsEditModal }
