import React, { useEffect, useState } from 'react'
import { MultiValue, SingleValue } from 'react-select'
import { useNavigate, useParams } from 'react-router-dom'
import { CompanyListItem } from '../../charts/core/_models'
import { companyOptionsList } from '../../charts/core/_requests'
import { benchmarkRecordData } from '../core/_models'
import moment from 'moment'
import {
  benchmarkList,
  getBenchmarkRecordDataById,
  getSiteFeatureOptions,
  saveBenchmarkRecordByKey,
} from '../core/_requests'
import BenchmarkTable from './BenchmarkTable'
import BenchmarkRecordFilter from './BenchmarkRecordFilter'

class benchmarkPeriod {
  value!: string
  start_date?: string
  end_date?: string
}
function BenchmarkRecordEditPage() {
  const navigate = useNavigate()
  const { recordId } = useParams()
  let [companyFilter, setCompanyFilter] = useState<SingleValue<CompanyListItem>>(null)
  let [siteFilter, setSiteFilter] = useState<MultiValue<CompanyListItem>>([])
  let [period, setPeriod] = useState<string>('this_year')
  let [customPeriod, setCustomPeriod] = useState<benchmarkPeriod>()
  let [startDate, setStartDate] = useState<moment.Moment>(moment())
  let [endDate, setEndDate] = useState<moment.Moment>(moment())
  let [isLoading, setIsLoading] = useState<boolean>(false)
  let [listingData, setListingData] = useState<any>([])
  let [benchmarkRecordData, setBenchmarkRecordData] = useState<benchmarkRecordData>()
  let [benchmarkParameter, setBenchmarkParameter] = useState<string>('')
  // Editable fields with edit mode states
  const [benchmarkRecordName, setBenchmarkRecordName] = useState<string>('')
  const [isEditingName, setIsEditingName] = useState<boolean>(false)

  const [benchmarkRecordDesc, setBenchmarkRecordDesc] = useState<string>('')
  const [isEditingDesc, setIsEditingDesc] = useState<boolean>(false)
  let [benchmarkRecordStatus, setBenchmarkRecordStatus] = useState<string>('')
  const [companyOptions, setCompanyOptions] = useState<CompanyListItem[]>([])
  const [featureOptions, setFeatureOptions] = useState<any[]>([])

  const getDateRangeLabel = () => {
    if (period === 'custom') {
      return `${startDate.format('DD MMMM YYYY')} - ${endDate.format('DD MMMM YYYY')}`
    }
    if (period === 'this_year') {
      return `${moment().startOf('year').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    if (period === 'last_one_month') {
      return `${moment().subtract(1, 'month').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    if (period === 'last_one_week') {
      return `${moment().subtract(1, 'week').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    return ''
  }
  const fetchListData = async () => {
    try {
      setIsLoading(true)
      const params = {
        isLoggedIn: true,
        companyId: Number(companyFilter?.value),
        siteId: siteFilter,
        period: period,
        customStartDate: startDate.format('YYYY-MM-DD'),
        customEndDate: endDate.format('YYYY-MM-DD'),
        siteFeature: benchmarkParameter,
      }
      const listData = await benchmarkList(params).then((response) => {
        setListingData(response)
      })
    } catch (error) {
      console.error('Error fetching chart data:', error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (
      companyFilter &&
      benchmarkParameter &&
      siteFilter &&
      period === 'custom' &&
      startDate &&
      endDate
    ) {
      fetchListData()
    } else if (
      companyFilter &&
      siteFilter &&
      benchmarkParameter &&
      period !== 'custom' &&
      startDate &&
      endDate
    ) {
      fetchListData()
    }
  }, [companyFilter, siteFilter, benchmarkParameter, startDate, endDate, period])

  useEffect(() => {
    setSiteFilter([])
    if (companyFilter) {
      fetchCompanySiteFeatures(Number(companyFilter.value))
    }
  }, [companyFilter])

  useEffect(() => {
    companyOptionsList().then(setCompanyOptions)
    if (companyFilter) {
      fetchCompanySiteFeatures(Number(companyFilter.value))
    }
  }, [])
  const fetchBenchmarkRecordData = async () => {
    try {
      if (recordId) {
        const responseDataView = await getBenchmarkRecordDataById(recordId).then((responseData) => {
          if (responseData?.company) {
            setCompanyFilter(
              companyOptions.find((company) => company.value === responseData?.company) || null
            )
          }
          if (responseData?.company_sites) {
            setSiteFilter(responseData?.company_sites)
          }

          if (responseData?.period) {
            let period = JSON.parse(responseData?.period)
            setPeriod(period.value)
            if (period.start_date) {
              setStartDate(moment(period.start_date))
            }
            if (period.end_date) {
              setEndDate(moment(period.end_date))
            }
          }
          if (responseData?.benchmark_parameter) {
            setBenchmarkParameter(responseData?.benchmark_parameter)
          }
          if (responseData?.name) {
            setBenchmarkRecordName(responseData?.name)
          }
          if (responseData?.description) {
            setBenchmarkRecordDesc(responseData?.description)
          }
          if (responseData?.status) {
            setBenchmarkRecordStatus(responseData?.status)
          }
          setBenchmarkRecordData(responseData)
        })
      } else {
        console.error('NO RECORD ID')
      }
    } catch (error) {
      console.error('Error fetching summary data:', error)
    }
  }

  useEffect(() => {
    if (companyOptions && companyOptions.length > 0 && recordId) {
      fetchBenchmarkRecordData()
    }
  }, [companyOptions, recordId])

  const fetchCompanySiteFeatures = async (companyId: number) => {
    try {
      const responseOptions = await getSiteFeatureOptions(companyId).then((response) => {
        if (!response) {
          setFeatureOptions([])
        } else {
          setFeatureOptions(response)
        }
      })
    } catch (error) {
      setFeatureOptions([])
    }
  }

  const handleSaveBenchmarkRecord = () => {
    if (recordId) {
      saveBenchmarkRecordByKey(recordId, {
        company: Number(companyFilter?.value),
        name: benchmarkRecordName,
        description: benchmarkRecordDesc,
        company_sites: '[' + siteFilter.map((site) => site.value).join(',') + ']',
        period: {
          value: 'custom',
          start_date: startDate.format('YYYY-MM-DD'),
          end_date: endDate.format('YYYY-MM-DD'),
        },
        benchmark_parameter: benchmarkParameter,
        status: benchmarkRecordStatus,
      }).catch((error) => {
        console.error('Error saving benchmark record:', error)
      })
    }
  }
  let [selectedFeatureName, setSelectedFeatureName] = useState<{
    name: string
    unit: string
  }>({
    name: '',
    unit: '',
  })
  useEffect(() => {
    if (benchmarkParameter && featureOptions && featureOptions.length > 0) {
      let feature = featureOptions.find((feature) => feature.value === benchmarkParameter)
      setSelectedFeatureName({
        name: feature?.label,
        unit: feature?.unit,
      })
    }
  }, [benchmarkParameter, featureOptions])
  const handleSaveName = () => {
    setIsEditingName(false)
    handleSaveBenchmarkRecord()
  }

  const handleSaveDesc = () => {
    setIsEditingDesc(false)
    handleSaveBenchmarkRecord()
  }

  return (
    <div>
      <div className='mb-4'>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  {/* Editable benchmarkRecordName */}
                  <div className='col-md-3 mb-3'>
                    <div className='card p-2 text-center'>
                      <i
                        className='bi bi-file-earmark-text'
                        style={{ fontSize: '2rem', marginBottom: '4px' }}
                      ></i>
                      {isEditingName ? (
                        <div className='d-flex'>
                          <input
                            type='text'
                            value={benchmarkRecordName}
                            onChange={(e) => setBenchmarkRecordName(e.target.value)}
                            className='form-control'
                          />
                          <button
                            className='btn btn-success ms-2'
                            onClick={handleSaveName}
                            style={{ fontSize: '1rem' }}
                          >
                            <i className='bi bi-check'></i>
                          </button>
                        </div>
                      ) : (
                        <div className='d-flex justify-content-between align-items-center'>
                          <p onClick={() => setIsEditingName(true)} style={{ cursor: 'pointer' }}>
                            {benchmarkRecordName || 'Click to edit'}
                          </p>
                          <i
                            className='bi bi-pencil'
                            style={{ cursor: 'pointer', fontSize: '1rem' }}
                            onClick={() => setIsEditingName(true)}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Editable benchmarkRecordDesc */}
                  <div className='col-md-3 mb-3'>
                    <div className='card p-2 text-center'>
                      <i
                        className='bi bi-file-text'
                        style={{ fontSize: '2rem', marginBottom: '4px' }}
                      ></i>
                      {isEditingDesc ? (
                        <div className='d-flex'>
                          <input
                            type='text'
                            value={benchmarkRecordDesc}
                            onChange={(e) => setBenchmarkRecordDesc(e.target.value)}
                            className='form-control'
                          />
                          <button
                            className='btn btn-success ms-2'
                            onClick={handleSaveDesc}
                            style={{ fontSize: '1rem' }}
                          >
                            <i className='bi bi-check'></i>
                          </button>
                        </div>
                      ) : (
                        <div className='d-flex justify-content-between align-items-center'>
                          <p onClick={() => setIsEditingDesc(true)} style={{ cursor: 'pointer' }}>
                            {benchmarkRecordDesc || 'Click to edit'}
                          </p>
                          <i
                            className='bi bi-pencil'
                            style={{ cursor: 'pointer', fontSize: '1rem' }}
                            onClick={() => setIsEditingDesc(true)}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Other fields */}
                  <div className='col-md-3 mb-3'>
                    <div className='card p-2 text-center'>
                      <i
                        className='bi bi-building'
                        style={{ fontSize: '2rem', marginBottom: '4px' }}
                      ></i>
                      <p>{companyFilter?.label}</p>
                    </div>
                  </div>

                  <div className='col-md-3 mb-3'>
                    <div className='card p-2 text-center'>
                      <i
                        className='bi bi-calendar'
                        style={{ fontSize: '2rem', marginBottom: '4px' }}
                      ></i>
                      <p>{getDateRangeLabel()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Other components */}
      <BenchmarkRecordFilter
        companyFilter={companyFilter}
        setCompanyFilter={setCompanyFilter}
        siteFilter={siteFilter}
        setSiteFilter={setSiteFilter}
        period={period}
        setPeriod={setPeriod}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedFeature={benchmarkParameter}
        setSelectedFeature={setBenchmarkParameter}
        setSelectedFeatureName={setSelectedFeatureName}
      />
      <section>
        <div style={{ textAlign: 'right' }}>
          <div className='d-flex justify-content-right gap-5 mt-3 mb-3'>
            <button className='btn btn-secondary' onClick={() => navigate('/benchmark/records')}>
              <i className='fas fa-arrow-left' style={{ marginRight: '5px' }}></i>
              Back To Benchmark Views
            </button>
            <button className='btn btn-danger' onClick={handleSaveBenchmarkRecord}>
              <i className='fa fa-save' style={{ marginRight: '5px' }}></i>
              Save
            </button>
          </div>
        </div>
      </section>
      <BenchmarkTable
        parameter={selectedFeatureName}
        data={listingData}
        company={companyFilter?.value || ''}
        start_date={startDate.format('YYYY-MM-DD')}
        end_date={endDate.format('YYYY-MM-DD')}
      />
    </div>
  )
}

export default BenchmarkRecordEditPage
