import { ResultProps } from '../../../utils/ResultViewer/ResultViewer'
import Table from '../../../utils/ResultViewer/Table'
type BenchmarkRecordsProps = ResultProps & {
  viewCallback: Function
}
class BenchmarkRecordsTable extends Table {
  //@ts-ignore
  public state: BenchmarkRecordsProps
  constructor(props: BenchmarkRecordsProps) {
    super(props)
    this.state = {
      title: props.title,
      actionItems: props.actionItems,
      dataUrl: props.dataUrl,
      data: {
        headers: {},
        data: [],
        search: [],
        pagination: {
          limit: 100,
          page: 1,
          total_count: 0,
        },
      },
      page: 1,
      orderBy: '',
      orderDirection: '',
      editCallback: props.editCallback,
      deleteCallback: props.deleteCallback,
      filterParams: {},
      viewCallback: props.viewCallback,
    }
  }
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key === 'edit_actions') {
      return (
        <td key={colIndex}>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <button
              onClick={() => this.props.editCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <span className='fa fa-edit'></span>
            </button>
            <button
              onClick={() => this.props.deleteCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
            >
              <span className='fa fa-trash'></span>
            </button>
            <button
              onClick={() => this.state.viewCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <span className='fas fa-eye'></span>
            </button>
          </div>
        </td>
      )
    } else if (key == 'status') {
      let badgeClass = 'badge-success'
      switch (row[key]) {
        case 'Active':
          badgeClass = 'badge-success'
          break
        case 'Passive':
          badgeClass = 'badge-warning'
          break
        case 'Disabled':
          badgeClass = 'badge-warning'
          break
      }
      return (
        <td key={colIndex}>
          <div className={'badge ' + badgeClass}>{row[key] == 'active' ? 'Active' : 'Passive'}</div>
        </td>
      )
    } else if (key == 'period') {
      const periodData = JSON.parse(row[key])
      const startDate = periodData?.start_date
      const endDate = periodData?.end_date
      return (
        <td key={colIndex}>
          <div className='d-flex flex-column'>
            {startDate && (
              <div className='badge badge-success'>
                {new Date(startDate).toLocaleDateString('tr-TR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </div>
            )}
            {endDate && (
              <div className='badge badge-danger mt-1'>
                {new Date(endDate).toLocaleDateString('tr-TR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </div>
            )}
            {!startDate && !endDate && <div className='badge badge-secondary'>N/A</div>}
          </div>
        </td>
      )
    } else {
      return super.renderRow(row, key, colIndex)
    }
  }
}

export default BenchmarkRecordsTable
