import axios, { AxiosResponse } from 'axios'
import { secrReportParams } from './_models'

export function getSecrReportDataById(id: string) {
  const url = `/secr_reports/single/${id}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export async function saveSecrReportByKey(secrReportId: string, params: secrReportParams) {
  let csrfUrl = '/secr_reports/save/' + secrReportId
  let form_id = ''
  let form_build_id = ''
  let form_token = ''
  const csrf = await axios
    .get(csrfUrl)
    .then((response) => {
      let data = response.data.data
      form_id = data.form_id
      form_build_id = data.form_build_id
      form_token = data.form_token
    })
    .catch((error) => {})
  const url = '/secr_reports/save/' + secrReportId

  let postData = {
    secr_reports: params,
    form_id: form_id,
    form_build_id: form_build_id,
    form_token: form_token,
  }
  const response = await axios.post(url, postData)
  return response
}
