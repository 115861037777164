/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { eventNotification } from './core/_models'

import { calculateTimeDifference, formatDate, getBodyField } from './core/_requests'
import { useIntl } from 'react-intl'

type Props = {
  setAlarmStatus: Dispatch<SetStateAction<boolean>>
}

var notifications: any = {}
var eventSource: EventSourcePolyfill | null = null

const NotificationsMenu: FC<Props> = ({ setAlarmStatus }) => {
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem('@auth/token') ?? undefined
  })
  const [eventNotifications, setEventNotifications] = useState<eventNotification[]>([])
  const intl = useIntl()

  useEffect(() => {
    const authToken = token

    if (!eventSource) {
      eventSource = new EventSourcePolyfill(
        process.env.REACT_APP_API_BASE + '/notifications/notificationsEvent',
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          heartbeatTimeout: 60 * 1000 * 60,
        }
      )
      eventSource.onmessage = (event) => {
        let data = JSON.parse(event.data)
        if (data) {
          for (let notification of data) {
            notifications[notification.ID] = notification
          }
          setEventNotifications(Object.values(notifications))
        }
      }
    }
  }, [])

  let unreadNotifications = eventNotifications.filter((event) => {
    return !event.is_read
  })
  useEffect(() => {
    if (unreadNotifications.length > 0) {
      setAlarmStatus(true)
    } else {
      setAlarmStatus(false)
    }
  }, [eventNotifications])
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          {intl.formatMessage({ id: 'MENU.NOTIFICATIONS' })}{' '}
          <span className='fs-8 opacity-75 ps-3'>
            {unreadNotifications.length > 0 &&
              unreadNotifications.length + ' ' + intl.formatMessage({ id: 'MENU.NOTIFICATIONS' })}
          </span>
        </h3>
      </div>

      <div className='scroll-y mh-325px my-5 px-8'>
        {unreadNotifications.length === 0 ? (
          <div className='text-center text-gray-600 fs-6'>
            {intl.formatMessage({ id: 'NOTIFICATIONS.NONOTIFICATIONS' })}
          </div>
        ) : (
          unreadNotifications.map((event, index) => (
            <div key={`alert${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                  <span className={clsx('symbol-label', `bg-light-danger`)}>
                    {' '}
                    <KTSVG
                      path={`/media/icons/duotune/general/gen044.svg`}
                      className={`svg-icon-2 svg-icon-danger`}
                    />
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a
                    href={process.env.PUBLIC_URL + `/alarms/list?alarm_id=${event.entity_id}`}
                    className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                  >
                    {getBodyField(event.type, event.body, 'title')}
                  </a>
                  <div className='text-gray-400 fs-7'>
                    {formatDate(getBodyField(event.type, event.body, 'date'))}
                  </div>
                </div>
              </div>

              <span className='badge badge-light fs-8'>
                {calculateTimeDifference(event.created_at)}
              </span>
            </div>
          ))
        )}
      </div>

      <div className='py-3 text-center border-top'>
        <Link to='/notifications/list' className='btn btn-color-gray-600 btn-active-color-primary'>
          {intl.formatMessage({ id: 'VIEWALL' })}{' '}
          <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
        </Link>
      </div>
    </div>
  )
}

export { NotificationsMenu }
