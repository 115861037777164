import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { AlarmsEditModal } from '../AlarmsEditModal'
import AlarmsTable from './tables/AlarmsTable'
import { useLocation } from 'react-router-dom'
const AlarmsList: FC = () => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const intl = useIntl()

  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const alarmId = searchParams.get('alarm_id')
    if (alarmId && itemIdForUpdate === '') {
      setItemIdForUpdate(alarmId)
    }
  }, [location.search])

  return (
    <>
      <AlarmsTable
        title={intl.formatMessage({ id: 'MENU.ALARMS' })}
        dataUrl='/alarms/list'
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForUpdate(id)
          window.history.pushState({}, '', `/alarms/list?alarm_id=${id}`)
        }}
        deleteCallback={() => {}}
      />
      {itemIdForUpdate !== '' ? (
        <AlarmsEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onHide={() => {
            setItemIdForUpdate('')
            window.history.pushState({}, '', '/alarms/list')
          }}
          refreshFunction={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export { AlarmsList }
