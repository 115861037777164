import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../../utils/Form/FormHandler'
import AutocompleteSelect from '../../../utils/Form/Widgets/AutocompleteSelect'
import Field from '../../../utils/Form/Widgets/Field'
import Check from '../../../utils/Form/Widgets/Check'
import { companySitesList } from '../../charts/core/_requests'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}
class BenchmarkRecord {
  company?: number
  name?: string
  description?: string
  company_sites?: string
  period?: string
  benchmark_parameter?: string
  status?: string
}
class BenchmarkRecordEditModal extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new BenchmarkRecord() }
  }

  getApiUrl(): string {
    return '/benchmark_records/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/benchmark_records/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      benchmark_records: this.state.entity,
    }
  }
  render(): ReactNode {
    var state = this.state as any
    let defaultCompanyValue = state.entity.company || 0
    let defaultBenchmarkParameter = state.entity.benchmark_parameter || ''
    if (this.props.itemIdForUpdate === 'add') {
      defaultCompanyValue = 0
      defaultBenchmarkParameter = 0
    } else {
      defaultCompanyValue = state.entity.company
      defaultBenchmarkParameter = state.entity.benchmark_parameter
    }
    return (
      <Modal
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === 'add' ? 'Add' : 'Edit'} Benchmark View
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title='Name'
                className='form-control'
                name='name'
                defaultValue={state.entity.name}
                onInput={(event: any) => {
                  ;(state.entity as any).name = event.target.value
                }}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Description'
                className='form-control'
                name='description'
                defaultValue={state.entity.description}
                onInput={(event: any) => {
                  ;(state.entity as any).description = event.target.value
                }}
              />
            </FormGroup>
            <FormGroup>
              <AutocompleteSelect
                title='Company'
                name='company'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultCompanyValue}
                autoCompleteTokenUrl='/company/autocomplete'
                isDisabled={this.props.itemIdForUpdate !== 'add'}
              />
            </FormGroup>
            <FormGroup>
              <AutocompleteSelect
                title='Benchmark Parameter'
                name='benchmark_parameter'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultBenchmarkParameter}
                autoCompleteTokenUrl='/settings_sitefeatures/autocomplete'
                isDisabled={this.props.itemIdForUpdate !== 'add'}
              />
            </FormGroup>
            {/*
            <FormGroup>
              <Field
                title='Period'
                className='form-control'
                name='period'
                defaultValue={state.entity.period}
                onInput={(event: any) => {
                  ;(state.entity as any).period = event.target.value
                }}
                disabled={this.props.itemIdForUpdate !== 'add'}
              />
            </FormGroup>
            */}
            <FormGroup>
              <Check
                title='Status'
                name='status'
                type='switch'
                checked={state.entity.status === 'active'}
                options={['']}
                onChange={(event: any) => {
                  const newValue = event.target.checked ? 'active' : 'passive'
                  this.handleInputChange({ target: { name: 'status', value: newValue } } as any)
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              <span className='fa fa-floppy-save'></span>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { BenchmarkRecordEditModal }
