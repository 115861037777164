import { PropsWithChildren } from 'react'
import { Form } from 'react-bootstrap'

export type BaseProps = {
  title: string
  required?: boolean
}

export default function Element(props: PropsWithChildren<BaseProps>) {
  return (
    <Form.Group className='fv-row mb-1 position-relative'>
      <Form.Label className={props.required ? 'required' : ''}>{props.title}</Form.Label>
      {props.children}
    </Form.Group>
  )
}
