import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import { DataRequestsList } from './components/DataRequestsList'
import ImportedData from './components/ImportedData'
import DataCollectionsMailbox from './components/mailbox/DataCollectionsMailbox'

const dataRequestsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Data Requests',
    path: '/data_requests/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const dataMailboxBreadcrumbs: Array<PageLink> = [
  {
    title: 'Data Collection Mail Box',
    path: '/data_collections/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DataRequestsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Data Requests'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={dataRequestsBreadcrumbs}>Data Requests</PageTitle>
              <DataRequestsList />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='mailbox'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Data Collections Mail Box'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={dataMailboxBreadcrumbs}>Data Collections Mail Box</PageTitle>
              <DataCollectionsMailbox />
            </>
          }
        />
      </Route>
      <Route
        path='imported_data'
        element={
          <>
            <DocumentTitle title={process.env.REACT_APP_NAME + ' | Imported Data'}></DocumentTitle>
            <PageTitle breadcrumbs={dataRequestsBreadcrumbs}>Imported Data</PageTitle>
            <ImportedData />
          </>
        }
      />
    </Routes>
  )
}

export default DataRequestsPage
