import axios, { AxiosResponse } from 'axios'
import {
  CompanyListItem,
  CompanyParams,
  CompareParams,
  GroupChartRowsParams,
  MeterParams,
  ReadingParams,
} from './_models'
import { ChartData } from './_models'
import { WithData } from '../../common/utils/axiosWithCSRF'

export function readingGetApi(params: ReadingParams): Promise<ChartData> {
  const { companyId, siteId, meterType, meterId, readingType, customStartDate, customEndDate } =
    params
  const meters: string[] = []

  meterId?.map((meter) => {
    meters.push(meter.value)
  })
  const metersString = meters.join(',')
  const metersIdEncoded = encodeURIComponent(metersString)
  const sites: string[] = []
  siteId.forEach((element) => {
    sites.push(element.value)
  })
  const sitesString = sites.join(',')
  const siteIdEncoded = encodeURIComponent(sitesString)
  const paramsArr = [
    companyId && `company=${companyId}`,
    siteIdEncoded && `company_sites=${siteIdEncoded}`,
    meterType && `meter_type=${meterType}`,
    meterId && `meter_number=${metersIdEncoded}`,
    readingType && `chart_type=${readingType}`,
    readingType === 'custom' && customStartDate && `start_date=${customStartDate}`,
    readingType === 'custom' && customEndDate && `end_date=${customEndDate}`,
  ]

  const url = `/readings/chart?${paramsArr.filter(Boolean).join('&')}`

  return axios
    .get(url)
    .then((response: AxiosResponse<WithData<ChartData>>) => response.data)
    .then((response) => response.data)
}

export function getCompareDataApi(params: CompareParams) {
  const { companyId, siteId, metersId, readingType, meterType } = params
  const sites: string[] = []
  siteId.forEach((element) => {
    sites.push(element.value)
  })
  const sitesString = sites.join(',')
  const siteIdEncoded = encodeURIComponent(sitesString)

  const meters: string[] = []
  metersId?.forEach((meter) => {
    //map e çevirebiliriz
    meters.push(meter.value)
  })
  const metersString = meters.join(',')
  const metersIdEncoded = encodeURIComponent(metersString)

  const paramsArr = [
    companyId && `companyId=${companyId}`,
    siteIdEncoded && `company_sites=${siteIdEncoded}`,
    metersId && `meter_number=${metersIdEncoded}`,
    meterType && `meterType=${meterType}`,
    readingType && `readingType=${readingType}`,
  ]
  const url = `/readings/compareTotalData?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getGroupChartDataRows(params: GroupChartRowsParams) {
  let { companyId, meters, meterType, readingType, startDate, endDate } = params
  const metersString = meters.join(',')
  const metersIdEncoded = encodeURIComponent(metersString)

  const paramsArr = [
    companyId && `companyId=${companyId}`,
    meters && `meters=${metersIdEncoded}`,
    meterType && `meterType=${meterType}`,
    readingType && `readingType=${readingType}`,
  ]
  if (readingType == 'custom') {
    if (startDate && endDate) {
      const startDateParam = `start_date=${startDate}`
      const endDateParam = `end_date=${endDate}`
      params.startDate = ''
      params.endDate = ''
      paramsArr.push(startDateParam, endDateParam)
    }
  }

  const url = `/readings/getGroupChartDataRows?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function companyOptionsList(): Promise<CompanyListItem[]> {
  const url = `/company/getCompanySelect`
  return axios
    .get(url)
    .then((response: AxiosResponse<WithData<{ ID: string; name: string }[]>>) => response.data)
    .then((response) =>
      response.data.map((a) => ({
        label: a.name,
        value: a.ID,
      }))
    )
}

export function companySitesList(params: CompanyParams): Promise<CompanyListItem[]> {
  const { companyId } = params
  const url = `/company/getCompanySites/` + companyId
  return axios
    .get(url)
    .then((response: AxiosResponse<WithData<{ ID: string; name: string }[]>>) => response.data)
    .then((response) =>
      response.data.map((a) => ({
        label: a.name,
        value: a.ID,
      }))
    )
}

export function companyMeterList(params: MeterParams): Promise<CompanyListItem[]> {
  const { siteId, meterType } = params
  if (!siteId || siteId.length === 0) {
    return Promise.resolve([])
  }
  const promises = siteId.map((site) => {
    return axios
      .get(`/company/getCompanyMeters/` + site.value + `/` + meterType)
      .then((response: AxiosResponse<WithData<{ ID: string; code: string }[]>>) => response.data)
      .then((response) => {
        if (!response.data) {
          return []
        }
        return response.data.map((a) => ({
          label: a.code,
          value: a.ID,
        }))
      })
  })

  return Promise.all(promises)
    .then((results) => {
      const mergedArray = results.flat()
      return mergedArray
    })
    .catch((error) => {
      console.error('error:', error)
      throw error
    })
}

export function getStatesByKey(params: string) {
  let type: string = params
  const url = `/generalsettings/getStatesByKey?key=` + type
  return axios.get(url).then((response) => {
    if (response.data) {
      return response.data
    }
  })
}
