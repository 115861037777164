import React, { useEffect, useState } from 'react'
import { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem } from '../../charts/core/_models'
import moment from 'moment'
import BenchmarkFilter from './BenchmarkFilter'
import { benchmarkHeatmapData } from '../core/_requests'
import { BenchmartHeatmapData } from '../core/_models'
import ApexCharts from 'react-apexcharts'

function BenchmarkHeatmap() {
  let [companyFilter, setCompanyFilter] = useState<SingleValue<CompanyListItem>>(null)
  let [siteFilter, setSiteFilter] = useState<MultiValue<CompanyListItem>>([])
  let [period, setPeriod] = useState<string>('this_year')
  let [startDate, setStartDate] = useState<moment.Moment>(moment())
  let [endDate, setEndDate] = useState<moment.Moment>(moment())
  let [isLoading, setIsLoading] = useState<boolean>(false)
  let [chartData, setChartData] = useState<BenchmartHeatmapData>()
  let [selectedFeature, setSelectedFeature] = useState<string>('')
  let [selectedFeatureName, setSelectedFeatureName] = useState<{
    name: string
    unit: string
  }>({
    name: '',
    unit: '',
  })
  const getDateRangeLabel = () => {
    if (period === 'custom') {
      return `${startDate.format('DD MMMM YYYY')} - ${endDate.format('DD MMMM YYYY')}`
    }
    if (period === 'this_year') {
      return `${moment().startOf('year').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    if (period === 'last_one_month') {
      return `${moment().subtract(1, 'month').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    if (period === 'last_one_week') {
      return `${moment().subtract(1, 'week').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    return ''
  }

  const fetchChartData = async () => {
    try {
      setIsLoading(true)
      const params = {
        isLoggedIn: true,
        companyId: Number(companyFilter?.value),
        siteId: siteFilter,
        period: period,
        customStartDate: startDate.format('YYYY-MM-DD'),
        customEndDate: endDate.format('YYYY-MM-DD'),
        siteFeature: selectedFeature,
      }
      const data = await benchmarkHeatmapData(params)
      setChartData(data)
    } catch (error) {
      console.error('Error fetching chart data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (companyFilter && selectedFeature) {
      fetchChartData()
    }
  }, [companyFilter, siteFilter, selectedFeature])

  useEffect(() => {
    if (companyFilter && selectedFeature && period === 'custom' && startDate && endDate) {
      fetchChartData()
    } else if (companyFilter && period !== 'custom') {
      fetchChartData()
    }
  }, [startDate, endDate, period])

  useEffect(() => {
    setSiteFilter([])
  }, [companyFilter])

  const allDataValues = chartData
    ? [
        ...chartData.electricity_consumption.data.map((item) => item.y),
        ...chartData.gas_consumption.data.map((item) => item.y),
        ...chartData.total_combined_consumption.data.map((item) => item.y),
        ...chartData.consumption_per_feature.data.map((item) => item.y),
      ]
    : []

  const minVal = Math.min(...allDataValues)
  const maxVal = Math.max(...allDataValues)

  const seriesData = chartData
    ? [
        {
          name: 'Electricity Consumption (kWh)',
          data: chartData.electricity_consumption.data.map((item) => ({ x: item.x, y: item.y })),
        },
        {
          name: 'Gas Consumption (kWh)',
          data: chartData.gas_consumption.data.map((item) => ({ x: item.x, y: item.y })),
        },
        {
          name: 'Total Consumption (kWh)',
          data: chartData.total_combined_consumption.data.map((item) => ({ x: item.x, y: item.y })),
        },
        {
          name: `Consumption Per ${selectedFeatureName.name}`,
          data: chartData.consumption_per_feature.data.map((item) => ({ x: item.x, y: item.y })),
        },
      ]
    : []

  const options = {
    chart: {
      type: 'heatmap' as const,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          min: minVal,
          max: maxVal,
          ranges: [
            {
              from: minVal,
              to: minVal + (maxVal - minVal) / 3,
              name: 'Low',
              color: '#00A100',
            },
            {
              from: minVal + (maxVal - minVal) / 3,
              to: minVal + (2 * (maxVal - minVal)) / 3,
              name: 'Medium',
              color: '#128FD9',
            },
            {
              from: minVal + (2 * (maxVal - minVal)) / 3,
              to: maxVal,
              name: 'High',
              color: '#FF4560',
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff'],
      },
    },
    xaxis: {
      type: 'category' as 'category',
    },
  }

  return (
    <>
      <div className='row mb-4'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'>
                <i className='bi bi-calendar'></i> {getDateRangeLabel()}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <BenchmarkFilter
        companyFilter={companyFilter}
        setCompanyFilter={setCompanyFilter}
        siteFilter={siteFilter}
        setSiteFilter={setSiteFilter}
        period={period}
        setPeriod={setPeriod}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        setSelectedFeatureName={setSelectedFeatureName}
      />
      <div className='row gy-10 gx-xl-10 mt-2'>
        {chartData ? (
          <ApexCharts options={options} series={seriesData} type='heatmap' height={350} />
        ) : (
          <div className='row mt-5'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='card-title'>
                    <i className='bi bi-error'></i> No data available for the selected filters
                  </h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default BenchmarkHeatmap
