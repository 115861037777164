import { FC, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import DashboardSingleChart from '../../charts/components/DashboardSingleChart'
import FilterForm from '../../charts/components/FilterForm'
import { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem, SelectItem } from '../../charts/core/_models'
import DashboardGroupChart from '../../charts/components/DashboardGroupChart'
import { DashboardToolbar } from './DashboardToolbar'
import { companyMeterList, companyOptionsList, companySitesList } from '../../charts/core/_requests'
import DocumentTitle from 'react-document-title'
import TotalDataBox from '../../charts/components/TotalDataBox'
import { GroupChartDataList } from '../../charts/components/GroupChartDataList'
import { CustomizationContext } from '../../customization/CustomizationContext'
import TotalDataCo2Box from '../../charts/components/TotalDataCo2Box'

const DashboardPage: FC<{ dashboardType: string }> = ({ dashboardType }) => {
  // @ts-ignore
  const { setSettingsAndUpdate, settings } = useContext(CustomizationContext)

  let [companyFilter, setCompanyFilter] = useState<SingleValue<CompanyListItem>>(null)
  let [siteFilter, setSiteFilter] = useState<MultiValue<CompanyListItem>>([])
  let [meterElectricityFilter, setMeterElectricityFilter] = useState<MultiValue<SelectItem>>([])
  let [meterGasFilter, setMeterGasFilter] = useState<MultiValue<SelectItem>>([])
  let [selectedElectricity, setSelectedElectricity] = useState<MultiValue<CompanyListItem>>([])
  let [selectedGas, setSelectedGas] = useState<MultiValue<CompanyListItem>>([])
  const [companyOptions, setCompanyOptions] = useState<CompanyListItem[]>([])
  const [companySiteOptions, setCompanySiteOptions] = useState<CompanyListItem[]>([])
  let [readingTypeElectiric, setReadingTypeElectiric] = useState('yesterday')
  let [readingTypeGas, setReadingTypeGas] = useState('yesterday')
  let [startCustomDate, setStartCustomDate] = useState('')
  let [endCustomDate, setEndCustomDate] = useState('')
  const [meterElectiricityOptions, setMeterElectricityOptions] = useState<CompanyListItem[]>([])
  const [meterGasOptions, setMeterGasOptions] = useState<CompanyListItem[]>([])

  useEffect(() => {
    let userSettingsCompany: CompanyListItem | null | undefined = null
    let userSettingsCompanySites: MultiValue<CompanyListItem> = []
    let userSettingsElectricMeters: string[] = []
    let userSettingsGasMeters: string[] = []
    if (settings) {
      companyOptionsList().then((options) => {
        setCompanyOptions(options)

        if (dashboardType == 'single' && settings.companyS) {
          userSettingsCompany = options.find((company) => company.value == settings.companyS)
        } else if (dashboardType == 'group' && settings.companyG) {
          userSettingsCompany = options.find((company) => company.value == settings.companyG)
        }

        if (userSettingsCompany) {
          setCompanyFilter({ label: userSettingsCompany.label, value: userSettingsCompany.value })
        }
      })
      if (settings.companySitesS || settings.companySitesG) {
        if (dashboardType == 'single') {
          setCompanyFilter(null)
          setSiteFilter([])
          setMeterElectricityFilter([])
          setMeterGasFilter([])
          userSettingsCompany = settings.companyS
          userSettingsCompanySites = settings.companySitesS
          userSettingsElectricMeters = settings.metersElectricS
          userSettingsGasMeters = settings.metersGasS
        } else {
          setCompanyFilter(null)
          setSiteFilter([])
          setMeterElectricityFilter([])
          setMeterGasFilter([])
          userSettingsCompany = settings.companyG
          userSettingsCompanySites = settings.companySitesG
          userSettingsElectricMeters = settings.metersElectricG
          userSettingsGasMeters = settings.metersGasG
        }
        if (userSettingsCompanySites) {
          companySitesList({ companyId: Number(userSettingsCompany) }).then((sites) => {
            setCompanySiteOptions(sites)
            let initialCompanySites = sites.filter((site) =>
              //@ts-ignore
              userSettingsCompanySites.includes(site.value)
            )
            if (initialCompanySites) {
              setSiteFilter(initialCompanySites)
              if (userSettingsElectricMeters) {
                companyMeterList({ siteId: initialCompanySites, meterType: 'electricity' }).then(
                  (meterElectiricityOptions) => {
                    setMeterElectricityOptions(meterElectiricityOptions)
                    let initialElectricMeters = meterElectiricityOptions.filter((meter) =>
                      userSettingsElectricMeters.includes(meter.value)
                    )
                    if (initialElectricMeters) {
                      setMeterElectricityFilter(initialElectricMeters)
                    }
                  }
                )
              }
              if (userSettingsGasMeters) {
                companyMeterList({ siteId: initialCompanySites, meterType: 'gas' }).then(
                  (meterGasOptions) => {
                    setMeterGasOptions(meterGasOptions)
                    let initialGasMeters = meterGasOptions.filter((meter) =>
                      userSettingsGasMeters.includes(meter.value)
                    )
                    if (initialGasMeters) {
                      setMeterGasFilter(initialGasMeters)
                    }
                  }
                )
              }
            }
          })
        }
      }
    }
  }, [dashboardType])

  useEffect(() => {
    let c = settings ? settings.companyS : null
    if (dashboardType == 'group') {
      c = settings.companyG
    }

    if (!siteFilter || companyFilter?.value != c) {
      setSiteFilter([])
    }
    if (companyFilter) {
      if (dashboardType == 'single') {
        setSettingsAndUpdate({ companyS: companyFilter?.value })
      } else {
        setSettingsAndUpdate({ companyG: companyFilter?.value })
      }
    }
  }, [companyFilter])

  useEffect(() => {
    if (companyFilter && siteFilter) {
      let siteFilterArray = siteFilter.map((site) => site.value)
      if (dashboardType == 'single') {
        setSettingsAndUpdate({
          companyS: companyFilter?.value,
          companySitesS: siteFilterArray,
          metersElectricS: [],
          metersGasS: [],
        })
      } else {
        setSettingsAndUpdate({
          companyG: companyFilter?.value,
          companySitesG: siteFilterArray,
          metersElectricG: [],
          metersGasG: [],
        })
      }
    }
    setMeterElectricityFilter([])
    setMeterGasFilter([])
  }, [siteFilter])

  useEffect(() => {
    let siteFilterArray = siteFilter.map((site) => site.value)
    let metersElectricArray = meterElectricityFilter.map((meter) => meter.value)
    setMeterElectricityFilter(meterElectricityFilter)
    if (companyFilter) {
      let metersGasArray: string[] = []
      if (meterGasFilter) {
        metersGasArray = meterGasFilter.map((meter) => meter.value)
      }
      if (dashboardType == 'single') {
        setSettingsAndUpdate({
          companyS: companyFilter?.value,
          companySitesS: siteFilterArray,
          metersElectricS: metersElectricArray,
          metersGasS: metersGasArray,
        })
      } else {
        setSettingsAndUpdate({
          companyG: companyFilter?.value,
          companySitesG: siteFilterArray,
          metersElectricG: metersElectricArray,
          metersGasG: metersGasArray,
        })
      }
    }
  }, [meterElectricityFilter])

  useEffect(() => {
    let siteFilterArray = siteFilter.map((site) => site.value)
    let metersGasArray = meterGasFilter.map((meter) => meter.value)
    setMeterGasFilter(meterGasFilter)
    if (companyFilter) {
      let metersElectricArray: string[] = []
      if (meterElectricityFilter) {
        metersElectricArray = meterElectricityFilter.map((meter) => meter.value)
      }
      if (dashboardType == 'single') {
        setSettingsAndUpdate({
          companyS: companyFilter?.value,
          companySitesS: siteFilterArray,
          metersGasS: metersGasArray,
          metersElectricS: metersElectricArray,
        })
      } else {
        setSettingsAndUpdate({
          companyG: companyFilter?.value,
          companySitesG: siteFilterArray,
          metersGasG: metersGasArray,
          metersElectricG: metersElectricArray,
        })
      }
    }
  }, [meterGasFilter])

  const [selectedMeterTypeForCo2Box, setSelectedMeterTypeForCo2Box] = useState('Electric')
  const [selectedMeterTypeForTotalBox, setSelectedMeterTypeForTotalBox] = useState('Electric')

  const [selectedMetersForCo2Box, setSelectedMetersForCo2Box] =
    useState<MultiValue<SelectItem>>(meterElectricityFilter)

  const [selectedMetersForTotalBox, setSelectedMetersForTotalBox] =
    useState<MultiValue<SelectItem>>(meterElectricityFilter)
  useEffect(() => {
    if (selectedMeterTypeForCo2Box === 'Electric') {
      setSelectedMetersForCo2Box(meterElectricityFilter)
    } else if (selectedMeterTypeForCo2Box === 'Gas') {
      setSelectedMetersForCo2Box(meterGasFilter)
    }
  }, [meterElectricityFilter, meterGasFilter, selectedMeterTypeForCo2Box])

  useEffect(() => {
    if (selectedMeterTypeForTotalBox === 'Electric') {
      setSelectedMetersForTotalBox(meterElectricityFilter)
    } else if (selectedMeterTypeForTotalBox === 'Gas') {
      setSelectedMetersForTotalBox(meterGasFilter)
    }
  }, [meterElectricityFilter, meterGasFilter, selectedMeterTypeForTotalBox])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-12'>
          <FilterForm
            companyFilter={companyFilter}
            setCompanyFilter={setCompanyFilter}
            siteFilter={siteFilter}
            setSiteFilter={setSiteFilter}
            meterElectricityFilter={meterElectricityFilter}
            setMeterElectricityFilter={setMeterElectricityFilter}
            meterGasFilter={meterGasFilter}
            setMeterGasFilter={setMeterGasFilter}
            selectedElectricity={selectedElectricity}
            setSelectedElectricity={setSelectedElectricity}
            selectedGas={selectedGas}
            setSelectedGas={setSelectedGas}
            dashboardType={dashboardType}
          />
        </div>
        {dashboardType === 'single' && companyFilter && (
          <div className='col-xxl-6'>
            <DashboardSingleChart
              meterType='electricity'
              company={companyFilter}
              companySite={siteFilter}
              electricityMeters={meterElectricityFilter}
            />
          </div>
        )}
        {dashboardType === 'single' && companyFilter && (
          <div className='col-xxl-6'>
            <DashboardSingleChart
              meterType='gas'
              company={companyFilter}
              companySite={siteFilter}
              gasMeters={meterGasFilter}
            />
          </div>
        )}
        {dashboardType === 'group' && companyFilter && (
          <div className='col-xxl-6'>
            <DashboardGroupChart
              meterType='electricity'
              company={companyFilter}
              companySite={siteFilter}
              meters={meterElectricityFilter}
              setReadingTypeElectiric={setReadingTypeElectiric}
              setReadingTypeGas={setReadingTypeGas}
              setCustomStart={setStartCustomDate}
              setCustomEnd={setEndCustomDate}
            />
          </div>
        )}
        {dashboardType === 'group' && companyFilter && (
          <div className='col-xxl-6'>
            <DashboardGroupChart
              meterType='gas'
              company={companyFilter}
              companySite={siteFilter}
              meters={meterGasFilter}
              setReadingTypeElectiric={setReadingTypeElectiric}
              setReadingTypeGas={setReadingTypeGas}
              setCustomStart={setStartCustomDate}
              setCustomEnd={setEndCustomDate}
            />
          </div>
        )}
      </div>

      {dashboardType === 'single' && companyFilter && (
        <div className='row g-5 g-xl-8'>
          {/* begin::Row */}
          <div className='col-xl-3'>
            <select
              className='form-select form-select-sm w-125px'
              value={selectedMeterTypeForTotalBox}
              onChange={(c) => setSelectedMeterTypeForTotalBox(c.target.value)}
            >
              <option value='Electric'>Electric</option>
              <option value='Gas'>Gas</option>
            </select>
          </div>
          <div className='row g-5 g-xl-8'>
            {/* begin::Col */}

            <div className='col-xl-3'>
              <TotalDataBox
                company={companyFilter}
                companySite={siteFilter}
                meters={selectedMetersForTotalBox}
                readingType='yesterday'
                meterType={selectedMeterTypeForTotalBox}
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-xl-3'>
              <TotalDataBox
                company={companyFilter}
                companySite={siteFilter}
                meters={selectedMetersForTotalBox}
                readingType='last_one_week'
                meterType={selectedMeterTypeForTotalBox}
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-xl-3'>
              <TotalDataBox
                company={companyFilter}
                companySite={siteFilter}
                meters={selectedMetersForTotalBox}
                readingType='last_one_month'
                meterType={selectedMeterTypeForTotalBox}
              />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-3'>
              <TotalDataBox
                company={companyFilter}
                companySite={siteFilter}
                meters={selectedMetersForTotalBox}
                readingType='last_one_year'
                meterType={selectedMeterTypeForTotalBox}
              />
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
          <div className='col-xl-3'>
            <select
              className='form-select form-select-sm w-125px'
              value={selectedMeterTypeForCo2Box}
              onChange={(e) => setSelectedMeterTypeForCo2Box(e.target.value)}
            >
              <option value='Electric'>Electric</option>
              <option value='Gas'>Gas</option>
            </select>
          </div>
          <div className='row g-5 g-xl-8'>
            {/* begin::Col */}
            <div className='col-xl-3'>
              <TotalDataCo2Box
                company={companyFilter}
                companySite={siteFilter}
                meters={selectedMetersForCo2Box}
                readingType='yesterday'
                meterType={selectedMeterTypeForCo2Box}
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-xl-3'>
              <TotalDataCo2Box
                company={companyFilter}
                companySite={siteFilter}
                meters={selectedMetersForCo2Box}
                readingType='last_one_week'
                meterType={selectedMeterTypeForCo2Box}
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-xl-3'>
              <TotalDataCo2Box
                company={companyFilter}
                companySite={siteFilter}
                meters={selectedMetersForCo2Box}
                readingType='last_one_month'
                meterType={selectedMeterTypeForCo2Box}
              />
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-xl-3'>
              <TotalDataCo2Box
                company={companyFilter}
                companySite={siteFilter}
                meters={selectedMetersForCo2Box}
                readingType='last_one_year'
                meterType={selectedMeterTypeForCo2Box}
              />
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
      )}
      <div className='row g-5 g-xl-8'>
        {dashboardType == 'group' && companyFilter && meterElectricityFilter.length > 0 && (
          <div className='col-xl-6'>
            <GroupChartDataList
              className='card-xl-stretch mb-xl-8'
              meterType='Electricity'
              company={companyFilter}
              readingType={readingTypeElectiric}
              meters={selectedElectricity}
              startCustomDate={startCustomDate}
              endCustomDate={endCustomDate}
            />
          </div>
        )}
        {dashboardType == 'group' && companyFilter && meterGasFilter.length > 0 && (
          <div className='col-xl-6'>
            <GroupChartDataList
              className='card-xl-stretch mb-xl-8'
              meterType='Gas'
              company={companyFilter}
              readingType={readingTypeGas}
              meters={selectedGas}
              startCustomDate={startCustomDate}
              endCustomDate={endCustomDate}
            />
          </div>
        )}
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  let dashboardType = window.location.pathname === '/dashboard' ? 'single' : 'group'
  return (
    <>
      <DocumentTitle title={process.env.REACT_APP_NAME + ' | Dashboard'}></DocumentTitle>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardToolbar />

      <br></br>

      <DashboardPage dashboardType={dashboardType} />
    </>
  )
}

export { DashboardWrapper }
