import { ReactNode } from 'react'
import FormHandler from '../../../utils/Form/FormHandler'
import { FormGroup } from 'react-bootstrap'
import Field from '../../../utils/Form/Widgets/Field'
import { Link } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import TemplateGeneratorWidget from '../../../utils/Template/TemplateGeneratorWidget'

class Templates {
  title?: string
  body?: string
}

type Props = {
  itemIdForUpdate?: string
  refreshFunction?: Function
}

class TemplateEditForm extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new Templates() }
  }

  getApiUrl(): string {
    return '/templates/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/templates/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      templates: this.state.entity,
    }
  }
  render(): ReactNode {
    var state = this.state as any
    let title = (this.props.itemIdForUpdate === 'add' ? 'Add' : 'Edit') + ' Template'
    return (
      <div className='card mb-5 mb-xl-8'>
        <DocumentTitle title={process.env.REACT_APP_NAME + ' | ' + title} />
        <Link to={'/reports/templates'} className='btn btn-light-primary w-100px mt-5 ms-8'>
          <span className='fa fa-chevron-left'></span>
          Back
        </Link>
        <div className='card-header'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          </h3>
        </div>
        <div className='card-body overflow-auto'>
          <form
            onSubmit={(event) => {
              this.handleSubmit(event).then((response) => {
                if (this.props.refreshFunction) {
                  this.props.refreshFunction(response.data.data.entity_id)
                }
              })
            }}
          >
            <FormGroup>
              <Field
                title='Title'
                className='form-control'
                type='text'
                name='title'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.title}
              />
              <TemplateGeneratorWidget
                title='Body'
                name='body'
                defaultValue={state.entity.body}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>
            <div className='position-fixed bottom-0 mb-10 end-0 me-20'>
              {this.props.itemIdForUpdate !== 'add' ? (
                <button
                  type='button'
                  onClick={(event) => {
                    this.handleDelete(event).then((response) => {
                      if (this.props.refreshFunction) {
                        this.props.refreshFunction('')
                      }
                    })
                  }}
                  className='btn btn-danger btn-sm me-2'
                >
                  <span className='fa fa-trash'></span>
                  Delete
                </button>
              ) : (
                <></>
              )}
              <button type='submit' className='btn btn-success btn-sm'>
                <span className='fa fa-floppy-save'></span>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default TemplateEditForm
