export function range(start: any, end: any, step = 1) {
  var array = []
  for (let i = start; i < end; i += step) {
    array[i] = i
  }
  return array
}

export function selectOptionsRange(start: any, end: any, step = 1) {
  var array = []
  for (let i = start; i < end; i += step) {
    array[i] = {
      value: i,
      label: i,
    }
  }
  return array
}
