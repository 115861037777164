import { FC } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'

interface NotificationsItemProps {
  title: string
  entityDate: string
  notificationDate: string
  type: string
  isRead: boolean
  entityId: string
}
const NotificationsItem: FC<NotificationsItemProps> = (props) => {
  const { title, entityDate, notificationDate, type, isRead, entityId } = props
  const intl = useIntl()
  let statusType = 'danger'
  let icon = ''
  if (type === 'alarms_site') {
    statusType = 'danger'
    icon = `/media/icons/duotune/general/gen007.svg`
  } else {
    statusType = 'warning'
    icon = `/media/icons/duotune/general/gen007.svg`
  }

  let readClass = 'bi bi-check-circle'
  let readIconColor = '#009ef7'

  if (isRead == true) {
    readClass = 'bi bi-check-circle'
    readIconColor = '#009ef7'
  } else if (isRead == false) {
    readClass = 'bi bi-exclamation-diamond'
    readIconColor = 'red'
  }
  return (
    <div className='timeline-item' key={entityId}>
      <div className='timeline-line w-40px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
        <div className='symbol-label bg-light'>
          <KTSVG path={icon} className={`svg-icon-2 svg-icon-${statusType}`} />
        </div>
      </div>

      <div className='timeline-content mb-10 mt-n1'>
        <div className='pe-3 mb-5'>
          <div className='d-flex' style={{ alignItems: 'center' }}>
            <div className='fs-5 fw-bold' style={{ minWidth: '150px' }}>
              <a href={process.env.PUBLIC_URL + `/alarms/list?alarm_id=${entityId}`}>{title}</a>
            </div>
            <div style={{ margin: '0px 15px' }}>
              <a
                href={process.env.PUBLIC_URL + `/alarms/list?alarm_id=${entityId}`}
                className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
              >
                {intl.formatMessage({ id: 'NOTIFICATIONS.DETAILS' })}
              </a>
            </div>

            <div style={{ marginLeft: 'auto' }} className='text-muted fs-7'>
              {notificationDate}
            </div>
            <div style={{ margin: '0px 15px' }}>
              <i className={readClass} style={{ color: readIconColor }}></i>
            </div>
          </div>
          <div className='d-flex align-items-center mt-1 fs-6'>
            <div className='text-muted me-2 fs-7'>{entityDate}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { NotificationsItem }
