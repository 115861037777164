import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TemplateEditForm from './TemplateEditForm'

const TemplateEdit: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  return (
    <TemplateEditForm
      itemIdForUpdate={params.id}
      refreshFunction={(itemId: any) => {
        navigate('/reports/templates/' + itemId)
      }}
    />
  )
}

export default TemplateEdit
