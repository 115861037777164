import { FC } from 'react'
import BlockInterface, { BlockAttributes, getAvailableBlocks } from './BlockInterface'
import { Modal } from 'react-bootstrap'

export type Props = {
  onHide: () => void
  onSave: (item: BlockAttributes) => void
  show: boolean
  block?: BlockAttributes
}

const BlockEditModal: FC<Props> = (props: Props) => {
  let block: BlockInterface | null = null
  if (props.block) {
    block = getAvailableBlocks()[props.block.type as any]
    block.setAttributes(props.block.attributes)
  }
  let variation = block
    ?.getVariationOptions()
    .find((variation) => variation?.attribute.type === block?.getAttributes().type)
  return (
    <Modal
      id='template_block_edit_modal'
      aria-hidden='true'
      dialogClassName={'modal-dialog modal-dialog-centered ' + block?.getModalClasses()}
      size='xl'
      show={props.show}
      backdrop={true}
      onHide={props.onHide}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit {variation?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{block ? block.renderTemplateForm() : <>{JSON.stringify(block)}</>}</Modal.Body>
      <Modal.Footer className='justify-content-end'>
        <button
          type='button'
          onClick={(event) => {
            props.onHide()
          }}
          className='btn btn-danger btn-sm'
        >
          Cancel
        </button>
        <button
          type='submit'
          className='btn btn-success btn-sm'
          onClick={() => {
            props.onSave({
              type: block?.getType() || '',
              attributes: block?.getAttributes() || {},
            })
          }}
        >
          Save Block
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default BlockEditModal
