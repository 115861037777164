import ReactApexChart from 'react-apexcharts'
import { getAlarmMetersData, getChartDataForMeterAlarms } from './core/_requests'
import { useEffect, useState } from 'react'
import { AlarmChartDataType } from './core/_model'
import { useIntl } from 'react-intl'

function MetersForAlarmCommentModal(alarmSite: any) {
  let site = alarmSite.siteValue
  let date = alarmSite.dateValue
  let [data, setData] = useState<AlarmChartDataType>({
    labels: [],
    datasets: [],
  })

  let [info, setInfo] = useState([])

  const intl = useIntl()

  const fetchAlarmChartData = async () => {
    try {
      setTimeout(async () => {
        const data = await getChartDataForMeterAlarms(site, date)
        setData(data)
      }, 2000)
    } catch (error) {
      console.log('Error fetching alarm chart data:', error)
    }
  }

  const fetchAlarmMetersData = async () => {
    try {
      setTimeout(async () => {
        const info = await getAlarmMetersData(site, date)
        setInfo(info)
      }, 2000)
    } catch (error) {
      console.log('Error fetching alarm chart data:', error)
    }
  }

  useEffect(() => {
    if (site && date) {
      fetchAlarmChartData()
      fetchAlarmMetersData()
    }
  }, [site, date])

  let seriesData: any = []
  data.datasets.map((dataset, index) => {
    seriesData[index] = dataset
  })

  return (
    <div style={{ margin: '10px' }}>
      {data.datasets && data.datasets.length > 0 && info && info.length > 0 ? (
        <div className='accordion' id='kt_accordion_1'>
          {data.datasets.map((dataset, index: number) => (
            <div key={index}>
              {info[index] && (
                <div className='accordion-item' key={index}>
                  <h2 className='accordion-header' id={`kt_accordion_1_header_${index}`}>
                    <button
                      className='accordion-button fs-4 fw-bold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#kt_accordion_1_body_${index}`}
                      aria-expanded='false'
                      aria-controls={`kt_accordion_1_body_${index}`}
                    >
                      {info[index]['code']}
                    </button>
                  </h2>
                  <div
                    id={`kt_accordion_1_body_${index}`}
                    className='accordion-collapse collapse'
                    aria-labelledby={`kt_accordion_1_header_${index}`}
                    data-bs-parent='#kt_accordion_1'
                  >
                    <div className='accordion-body'>
                      <ReactApexChart
                        options={{
                          chart: {
                            zoom: {
                              enabled: true,
                            },
                            dropShadow: {
                              enabled: true,
                              color: '#000',
                              top: 18,
                              left: 7,
                              blur: 10,
                              opacity: 0.2,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          markers: {
                            size: 2,
                            strokeWidth: 0,
                            fillOpacity: 1,
                          },
                          stroke: {
                            curve: 'smooth' as const,
                          },
                          colors: ['#0070FF', '#FFA500'],
                          xaxis: {
                            categories: data.labels,
                            tickAmount: 12,
                            labels: {
                              style: {
                                fontSize: '12px',
                              },
                            },
                            axisTicks: {
                              show: true,
                              borderType: 'solid',
                              color: '#78909C',
                              height: 6,
                              offsetX: 0,
                              offsetY: 0,
                            },
                          },
                          legend: {
                            show: false,
                          },
                        }}
                        series={seriesData ? seriesData[index] : []}
                        height={200}
                      />
                    </div>
                    <table className='table table-row-dashed table-row-gray-300 gy-7'>
                      <thead>
                        <tr className='fw-bolder fs-6 text-gray-800'>
                          <th>{intl.formatMessage({ id: 'ALARMS.DATE' })}</th>
                          <th>{intl.formatMessage({ id: 'ALARMS.COMPANYSITE' })}</th>
                          <th>{intl.formatMessage({ id: 'ALARMS.METERID' })}</th>
                          <th>{intl.formatMessage({ id: 'ALARMS.METERTYPE' })}</th>
                          <th>{intl.formatMessage({ id: 'ALARMS.METERCODE' })}</th>
                          <th>{intl.formatMessage({ id: 'ALARMS.TOTALCONSUMPTION' })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={index}>
                          <td key={index + 'date'}>
                            {info[index] && info[index]['date'] ? info[index]['date'] : 'N/A'}
                          </td>
                          <td key={index + 'name'}>
                            {info[index] && info[index]['name'] ? info[index]['name'] : 'N/A'}
                          </td>
                          <td key={index + 'meterId'}>
                            {info[index] && info[index]['meterId'] ? info[index]['meterId'] : 'N/A'}
                          </td>
                          <td key={index + 'meterType'}>
                            {info[index] && info[index]['meter_type']
                              ? info[index]['meter_type']
                              : 'N/A'}
                          </td>
                          <td key={index + 'code'}>
                            {info[index] && info[index]['code'] ? info[index]['code'] : 'N/A'}
                          </td>
                          <td key={index + 'total'}>
                            {info[index] && info[index]['total']
                              ? info[index]['total'] + ' kWh'
                              : 'N/A'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default MetersForAlarmCommentModal
