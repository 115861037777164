import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import ReactSelect, { SingleValue } from 'react-select'
import { CompanyListItem, SelectItem } from '../charts/core/_models'
import { companyOptionsList } from '../charts/core/_requests'

type filterProps = {
  companyFilter: SingleValue<SelectItem>
  setCompanyFilter: Dispatch<SetStateAction<SingleValue<SelectItem>>>
  yearFilter: SingleValue<SelectItem>
  setYearFilter: Dispatch<SetStateAction<SingleValue<SelectItem>>>
  isDisabled: boolean
}
const AnnualReportFilter: React.FC<filterProps> = ({
  companyFilter,
  setCompanyFilter,
  yearFilter,
  setYearFilter,
  isDisabled,
}) => {
  const [selectedCompany, setSelectedCompany] = useState<SingleValue<CompanyListItem>>(null)
  const [companyOptions, setCompanyOptions] = useState<CompanyListItem[]>([])

  const [selectedYear, setSelectedYear] = useState<SingleValue<CompanyListItem>>(null)

  const yearOptions = [
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
  ]

  useEffect(() => {
    companyOptionsList().then(setCompanyOptions)
  }, [])

  useEffect(() => {
    setSelectedCompany(companyFilter)
  }, [companyFilter])

  useEffect(() => {
    setSelectedYear(yearFilter)
  }, [yearFilter])

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col-lg-3'>
            <label htmlFor='site-select'>Company</label>
            <ReactSelect
              defaultValue={companyFilter}
              onChange={setCompanyFilter}
              options={companyOptions}
              placeholder={companyFilter?.label.toUpperCase()}
              value={selectedCompany}
              isDisabled={isDisabled}
            />
          </div>
          <div className='col-lg-3'>
            <label htmlFor='site-select'>Report Year</label>
            <ReactSelect
              defaultValue={yearFilter}
              onChange={setYearFilter}
              options={yearOptions}
              placeholder={yearFilter?.label.toUpperCase()}
              value={selectedYear}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnnualReportFilter
