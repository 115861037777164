import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'

import DocumentTitle from 'react-document-title'
import { AlarmsList } from './components/AlarmsList'

const alarmsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Alarms',
    path: '/alarms/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AlarmsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Alarms'}></DocumentTitle>
              <AlarmsList />
            </>
          }
        />
        <Route index element={<Navigate to='/alarms/list' />} />
      </Route>
    </Routes>
  )
}

export default AlarmsPage
