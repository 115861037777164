import { useEffect, useRef, useState } from 'react'
import { useThemeMode } from '../../../_metronic/partials'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { ApexOptions } from 'apexcharts'
import { MultiValue, SingleValue } from 'react-select'
import { SelectItem } from '../charts/core/_models'
import { meterType } from '../reports/core/_models'
import { getCompareChartData } from '../reports/core/_requests'
import { useIntl } from 'react-intl'
import moment from 'moment'
import DatePicker from '../../utils/Form/Widgets/DatePicker'

type Props = {
  companyFilter: SingleValue<SelectItem>
  siteFilter: MultiValue<SelectItem>
  metersFilter: MultiValue<SelectItem>
  meterTypeFilter: SingleValue<meterType>
}
const CompareChart: React.FC<Props> = ({
  companyFilter,
  siteFilter,
  metersFilter,
  meterTypeFilter,
}) => {
  const [chartType, setChartType] = useState('line')
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const intl = useIntl()

  const getDefaultDate = (defaultType: any) => {
    switch (defaultType) {
      case 1:
        return moment().subtract(1, 'days').toDate() // Dünün tarihi
      case 2:
        return moment().subtract(1, 'weeks').toDate() // Geçen hafta bugünün tarihi
      case 3:
        return moment().subtract(1, 'months').toDate() // 1 ay önceki gün
      default:
        return null
    }
  }

  const defaultDate1 = getDefaultDate(1)

  const defaultDate2 = getDefaultDate(2)

  const defaultDate3 = getDefaultDate(3)

  const [rangeStart1, setRangeStart1] = useState<moment.Moment>(moment(defaultDate1))
  const [rangeStart2, setRangeStart2] = useState<moment.Moment>(moment(defaultDate2))
  const [rangeStart3, setRangeStart3] = useState<moment.Moment>(moment(defaultDate3))
  let [compareData, setCompareData] = useState(null)

  const compareParams = {
    isLoggedIn: true,
    companyId: companyFilter ? companyFilter.value : '',
    siteId: siteFilter,
    metersId: metersFilter,
    meterType: meterTypeFilter,
    firstDate: rangeStart1.format('YYYY-MM-DD'),
    secondDate: rangeStart2.format('YYYY-MM-DD'),
    thirdDate: rangeStart3.format('YYYY-MM-DD'),
  }
  const fetchCompareChartData = async () => {
    try {
      getCompareChartData({ ...compareParams }).then((response) => {
        setTimeout(async () => {
          const compareData = await getCompareChartData(compareParams)
          setCompareData(compareData)
        }, 2000)
      })
    } catch (error) {
      console.log('Error fetching compare chart data:', error)
    }
  }

  useEffect(() => {
    if (companyFilter) {
      fetchCompareChartData()
    }
  }, [
    companyFilter,
    siteFilter,
    metersFilter,
    meterTypeFilter,
    rangeStart1,
    rangeStart2,
    rangeStart3,
  ])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartType, compareData))
    if (chart) {
      chart.render()
    }

    return chart
  }
  const handleChartTypeChange = (e: any) => {
    const newChartType = e.target.value
    setChartType(newChartType)
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartType, compareData])

  let title = 'Alarm Chart'
  let className = 'card-xl-stretch mb-5 mb-xl-8'

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
        <DatePicker
          onChange={(selectedDate) => {
            if (selectedDate) {
              setRangeStart1(moment(selectedDate))
            }
          }}
          title='Select First Date'
        />
        <DatePicker
          onChange={(selectedDate) => {
            if (selectedDate) {
              setRangeStart2(moment(selectedDate))
            }
          }}
          title='Select Second Date'
        />
        <DatePicker
          onChange={(selectedDate) => {
            if (selectedDate) {
              setRangeStart3(moment(selectedDate))
            }
          }}
          title='Select Third Date'
        />
        <div className='card'>
          <select
            className='form-select form-select-sm w-125px'
            style={{ margin: '10px' }}
            value={chartType}
            onChange={handleChartTypeChange}
          >
            <option key='area' value='area'>
              {intl.formatMessage({ id: 'area' })}
            </option>
            <option key='bar' value='bar'>
              {intl.formatMessage({ id: 'bar' })}
            </option>
            <option key='line' value='line'>
              {intl.formatMessage({ id: 'line' })}
            </option>
            <option key='radar' value='radar'>
              {intl.formatMessage({ id: 'radar' })}
            </option>
          </select>
        </div>
      </div>

      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_2_chart' style={{ height: '350px' }}></div>
      </div>
    </div>
  )
}

function getChartOptions(height: number, type: string, data: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  return {
    series: data?.datasets,
    chart: {
      fontFamily: 'inherit',
      //@ts-ignore
      type: type,
      height: height,
      toolbar: {
        show: false,
      },
    },

    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
    },
    xaxis: {
      categories: data?.labels,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' kWh'
        },
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export default CompareChart
