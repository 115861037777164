import React, { PropsWithChildren, useEffect, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'

interface TinyMceWidgetProps {
  onChange: (content: string) => void
  initialValue?: string
  height?: string
}

export default function HtmlEditor(props: PropsWithChildren<TinyMceWidgetProps>) {
  const { initialValue, onChange, height, children, ...restProps } = props
  const editorRef = useRef<any>(null)

  const handleEditorChange = (content: any, editor: any) => {
    onChange(content.level.content)
  }
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      onInit={(evt, editor) => {
        editorRef.current = editor
      }}
      onChange={handleEditorChange}
      initialValue={initialValue}
      toolbar='undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image table media blockquote | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code visualblocks fullscreen preview'
      plugins='preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion'
      init={{
        height: height ? height : '350px',
        width: '100%',
        language: 'en',
        branding: false,
        toolbar_mode: 'wrap',
        setup: function (editor) {
          editor.on('change keyup input', function () {
            editor.save()
          })
        },
      }}
    />
  )
}

HtmlEditor.defaultProps = {
  type: 'text',
  rows: 8,
}
