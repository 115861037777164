import { useIntl } from 'react-intl'
type Props = {
  id: string
  value: string
  isActive?: boolean
  onClick: (id: string) => void
}

const FilterButton = ({ id, value, isActive = false, onClick }: Props) => {
  const intl = useIntl()
  const activeClass = isActive ? 'btn-active btn-active-primary active' : 'btn-color-muted'
  return (
    <button className={`btn btn-sm ${activeClass} px-4 me-1`} id={id} onClick={() => onClick(id)}>
      {intl.formatMessage({ id: id })}
    </button>
  )
}

export default FilterButton
