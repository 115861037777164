import React from 'react'

type SummaryDataToolbarProps = {
  activeUnit: string
  onUnitChange: (unit: string) => void
}

const SummaryDataToolbar: React.FC<SummaryDataToolbarProps> = ({ activeUnit, onUnitChange }) => {
  return (
    <div className='card-toolbar' data-kt-buttons='true' style={{ marginBottom: '5px' }}>
      <div className='d-flex align-items-center'>
        <button
          className={`btn btn-sm btn-color-muted ${
            activeUnit === 'tCO2e' ? 'btn-active btn-active-primary active' : ''
          } px-4 me-1 shadow-sm border border-secondary`}
          onClick={() => onUnitChange('tCO2e')}
        >
          tCO2e
        </button>
        <button
          className={`btn btn-sm btn-color-muted ${
            activeUnit === 'kWh' ? 'btn-active btn-active-primary active' : ''
          } px-4 me-1 shadow-sm border border-secondary`}
          onClick={() => onUnitChange('kWh')}
        >
          kWh
        </button>
      </div>
    </div>
  )
}

export default SummaryDataToolbar
