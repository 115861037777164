import axios, { AxiosResponse } from 'axios'
import {
  alarmDataForCalendarParams,
  analyticChartParams,
  cellChartParams,
  compareChartParams,
} from './_models'

export function getCellChartData(params: cellChartParams) {
  const { companyId, siteId, metersId, meterType, startDate, endDate } = params
  const sites: string[] = []
  siteId.forEach((element) => {
    sites.push(element.value)
  })
  const sitesString = sites.join(',')
  const siteIdEncoded = encodeURIComponent(sitesString)

  const meters: string[] = []
  metersId?.forEach((meter) => {
    meters.push(meter.value)
  })
  const metersString = meters.join(',')
  const metersIdEncoded = encodeURIComponent(metersString)

  const paramsArr = [
    companyId && `companyId=${companyId}`,
    siteIdEncoded && `companySites=${siteIdEncoded}`,
    metersId && `meterNumber=${metersIdEncoded}`,
    meterType && `meterType=${meterType ? meterType.value : 'Electiricity'}`,
    startDate && `startDate=${startDate}`,
    endDate && `endDate=${endDate}`,
  ]
  const url = `/analytics/getDataForCalendarView?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getAnalyticChartData(params: analyticChartParams) {
  const { companyId, siteId, metersId, meterType, readingType, algorithmType, startDate, endDate } =
    params
  const sites: string[] = []
  siteId.forEach((element) => {
    sites.push(element.value)
  })
  const sitesString = sites.join(',')
  const siteIdEncoded = encodeURIComponent(sitesString)

  const meters: string[] = []
  metersId?.forEach((meter) => {
    meters.push(meter.value)
  })
  const metersString = meters.join(',')
  const metersIdEncoded = encodeURIComponent(metersString)

  const predictionAlgorithms: string[] = []
  algorithmType?.forEach((alg) => {
    predictionAlgorithms.push(alg.value)
  })
  const predictionAlgorithmsString = predictionAlgorithms.join(',')
  const predictionAlgorithmsEncoded = encodeURIComponent(predictionAlgorithmsString)

  const paramsArr = [
    companyId && `companyId=${companyId}`,
    siteIdEncoded && `companySites=${siteIdEncoded}`,
    metersId && `meterNumber=${metersIdEncoded}`,
    meterType && `meterType=${meterType ? meterType.value : 'Electiricity'}`,
    readingType && `readingType=${readingType ? readingType : 'last_one_year'}`,
    algorithmType && `algorithmType=${predictionAlgorithmsEncoded}`,
    startDate && `startDate=${startDate}`,
    endDate && `endDate=${endDate}`,
  ]
  const url = `/analytics/getAnalyticsData?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getCompareChartData(params: compareChartParams) {
  const { companyId, siteId, metersId, meterType, firstDate, secondDate, thirdDate } = params
  const sites: string[] = []
  siteId.forEach((element) => {
    sites.push(element.value)
  })
  const sitesString = sites.join(',')
  const siteIdEncoded = encodeURIComponent(sitesString)

  const meters: string[] = []
  metersId?.forEach((meter) => {
    meters.push(meter.value)
  })
  const metersString = meters.join(',')
  const metersIdEncoded = encodeURIComponent(metersString)

  const paramsArr = [
    companyId && `companyId=${companyId}`,
    siteIdEncoded && `companySites=${siteIdEncoded}`,
    metersId && `meterNumber=${metersIdEncoded}`,
    meterType && `meterType=${meterType ? meterType.value : 'Electiricity'}`,
    `readingType=custom`,
    firstDate && `firstDate=${firstDate}`,
    secondDate && `secondDate=${secondDate}`,
    thirdDate && `thirdDate=${thirdDate}`,
  ]
  const url = `/analytics/getCompareChartData?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getAlarmDataForCalendarView(params: alarmDataForCalendarParams) {
  const { companyId, siteId, metersId, meterType, selectedDate } = params
  const sites: string[] = []
  siteId.forEach((element) => {
    sites.push(element.value)
  })
  const sitesString = sites.join(',')
  const siteIdEncoded = encodeURIComponent(sitesString)

  const meters: string[] = []
  metersId?.forEach((meter) => {
    meters.push(meter.value)
  })
  const metersString = meters.join(',')
  const metersIdEncoded = encodeURIComponent(metersString)

  const paramsArr = [
    companyId && `companyId=${companyId}`,
    siteIdEncoded && `companySites=${siteIdEncoded}`,
    metersId && `meterNumber=${metersIdEncoded}`,
    selectedDate && `date=${selectedDate}`,
  ]
  const url = `/analytics/getAlarmDataForCalendarView?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}
