import axios from 'axios'

class CsrfHandler {
  protected apiUrl: string
  protected form_id: string
  protected form_build_id: string
  protected form_token: string

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl
    this.form_id = ''
    this.form_build_id = ''
    this.form_token = ''
  }

  refreshCsrf() {
    axios
      .get(this.apiUrl)
      .then((response) => {
        let data = response.data.data
        this.form_id = data.form_id
        this.form_build_id = data.form_build_id
        this.form_token = data.form_token
      })
      .catch((error) => {})
  }

  getTokenForRequest() {
    let token = {
      form_id: this.form_id,
      form_build_id: this.form_build_id,
      form_token: this.form_token,
    }
    this.refreshCsrf()
    return token
  }
}

export default CsrfHandler
