import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { DataFilesList } from './components/DataFilesList'
import DocumentTitle from 'react-document-title'

const dataFilesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Data Files',
    path: '/data_files/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DataPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Data File List'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={dataFilesBreadcrumbs}>Lists</PageTitle>
              <DataFilesList />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default DataPage
