import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Select, { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem, SelectItem } from '../core/_models'
import { companyMeterList, companyOptionsList, companySitesList } from '../core/_requests'
import { useIntl } from 'react-intl'

type Props = {
  companyFilter: SingleValue<SelectItem>
  setCompanyFilter: Dispatch<SetStateAction<SingleValue<SelectItem>>>
  siteFilter: MultiValue<SelectItem>
  setSiteFilter: Dispatch<SetStateAction<MultiValue<SelectItem>>>
  meterElectricityFilter: MultiValue<SelectItem>
  setMeterElectricityFilter: Dispatch<SetStateAction<MultiValue<SelectItem>>>
  meterGasFilter: MultiValue<SelectItem>
  setMeterGasFilter: Dispatch<SetStateAction<MultiValue<SelectItem>>>
  selectedElectricity: MultiValue<SelectItem>
  setSelectedElectricity: Dispatch<SetStateAction<MultiValue<SelectItem>>>
  selectedGas: MultiValue<SelectItem>
  setSelectedGas: Dispatch<SetStateAction<MultiValue<SelectItem>>>
  dashboardType: string
}
const FilterForm: React.FC<Props> = ({
  companyFilter,
  setCompanyFilter,
  siteFilter,
  setSiteFilter,
  meterElectricityFilter,
  setMeterElectricityFilter,
  meterGasFilter,
  setMeterGasFilter,
  selectedElectricity,
  setSelectedElectricity,
  selectedGas,
  setSelectedGas,
  dashboardType,
}) => {
  const [selectedCompany, setSelectedCompany] = useState<SingleValue<CompanyListItem>>(null)
  const [selectedSite, setSelectedSite] = useState<MultiValue<CompanyListItem>>([])
  const [companyOptions, setCompanyOptions] = useState<CompanyListItem[]>([])
  const [companySiteOptions, setCompanySiteOptions] = useState<CompanyListItem[]>([])
  const [meterElectiricityOptions, setMeterElectricityOptions] = useState<CompanyListItem[]>([])
  const [meterGasOptions, setMeterGasOptions] = useState<CompanyListItem[]>([])
  const intl = useIntl()
  useEffect(() => {
    companyOptionsList().then(setCompanyOptions)
  }, [])

  useEffect(() => {
    setSelectedCompany(companyFilter)
  }, [companyFilter])

  useEffect(() => {
    if (selectedCompany) {
      companySitesList({ companyId: Number(selectedCompany.value) }).then(setCompanySiteOptions)
    }
  }, [selectedCompany])

  useEffect(() => {
    setSelectedSite(siteFilter)
  }, [siteFilter])

  useEffect(() => {
    if (selectedSite) {
      companyMeterList({ siteId: selectedSite, meterType: 'electricity' }).then(
        setMeterElectricityOptions
      )
      companyMeterList({ siteId: selectedSite, meterType: 'gas' }).then(setMeterGasOptions)
    }
  }, [selectedSite])

  useEffect(() => {
    setSelectedElectricity(meterElectricityFilter)
  }, [meterElectricityFilter])

  useEffect(() => {
    setSelectedGas(meterGasFilter)
  }, [meterGasFilter])
  let placeholderMeter = ''
  let placeholderSite = ''
  if (dashboardType == 'single') {
    placeholderMeter = intl.formatMessage({ id: 'ALLMETERS' })
    placeholderSite = intl.formatMessage({ id: 'ALLSITES' })
  } else {
    placeholderMeter = intl.formatMessage({ id: 'SELECTMETERS' })
    placeholderSite = intl.formatMessage({ id: 'SELECTSITES' })
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col-lg-3'>
            <label htmlFor='site-select'>
              {' '}
              {intl.formatMessage({ id: 'FILTERFORM.COMPANY' })}:
            </label>
            <Select
              defaultValue={companyFilter}
              onChange={setCompanyFilter}
              options={companyOptions}
              placeholder={companyFilter?.label.toUpperCase()}
              value={selectedCompany}
            />
          </div>

          {companyFilter && (
            <div className='col-lg-3'>
              <label htmlFor='site-select'>
                {intl.formatMessage({ id: 'FILTERFORM.SITEELECT' })}:
              </label>
              <Select
                defaultValue={companySiteOptions}
                onChange={setSiteFilter}
                options={companySiteOptions}
                isMulti={true}
                placeholder={placeholderSite}
                value={siteFilter}
              />
            </div>
          )}

          {companyFilter && siteFilter && (
            <div className='col-lg-3'>
              <label htmlFor='site-select'>
                {' '}
                {intl.formatMessage({ id: 'FILTERFORM.ELECTRICMETERSSELECT' })}:
              </label>
              <Select
                defaultValue={meterElectricityFilter}
                onChange={setMeterElectricityFilter}
                options={meterElectiricityOptions}
                isMulti={true}
                placeholder={placeholderMeter}
                value={selectedElectricity}
              />
            </div>
          )}

          {companyFilter && siteFilter && (
            <div className='col-lg-3'>
              <label htmlFor='site-select'>
                {' '}
                {intl.formatMessage({ id: 'FILTERFORM.GASMETERSSELECT' })}:
              </label>
              <Select
                defaultValue={meterGasFilter}
                onChange={setMeterGasFilter}
                options={meterGasOptions}
                isMulti={true}
                placeholder={placeholderMeter}
                value={selectedGas}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FilterForm
