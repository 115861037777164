import { readingGetApi } from '../core/_requests'
import React, { useEffect, useState } from 'react'
import FilterButton from './FilterButton'
import LineChart from './LineChart'
import { MultiValue, SingleValue } from 'react-select'
import { ChartData, CompanyListItem, SelectItem } from '../core/_models'
import { DateRangeSelector } from '../../../utils/Form/Widgets/DateRangeInput'
import moment from 'moment'
import { Range } from 'react-date-range'
import { useIntl } from 'react-intl'

type Props = {
  meterType: string
  className?: string
  company?: SingleValue<SelectItem>
  companySite: MultiValue<CompanyListItem>
  electricityMeters?: MultiValue<CompanyListItem>
  gasMeters?: MultiValue<CompanyListItem>
}

const DashboardSingleChart: React.FC<Props> = ({
  meterType,
  className,
  company,
  companySite,
  electricityMeters,
  gasMeters,
}) => {
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        name: '',
        data: [],
      },
    ],
    meterType: '',
  })
  const intl = useIntl()
  const [chartType, setChartType] = useState('yesterday')
  const [rangeStart, setRangeStart] = useState<moment.Moment>(moment())
  const [rangeEnd, setRangeEnd] = useState<moment.Moment>(moment())

  const handleButtonClick = (id: string) => {
    setChartType(id)
  }
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    fetchChartData()
  }, [chartType, company, companySite, electricityMeters, gasMeters, rangeStart, rangeEnd])

  const fetchChartData = async () => {
    try {
      setIsLoading(true)
      const params = {
        isLoggedIn: true,
        companyId: Number(company?.value),
        siteId: companySite,
        meterType: meterType,
        meterId: electricityMeters,
        readingType: chartType,
        customStartDate: rangeStart.format('YYYY-MM-DD'),
        customEndDate: rangeEnd.format('YYYY-MM-DD'),
      }
      setTimeout(async () => {
        const chartData = await readingGetApi(params)
        setChartData(chartData)
        setIsLoading(false)
      }, 500)
    } catch (error) {
      console.log('Error fetching chart data:', error)
      setIsLoading(false)
    }
  }
  return (
    <div>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1 text-uppercase'>
              {company?.label} /{' '}
              {meterType === 'electricity'
                ? intl.formatMessage({ id: 'CHARTTITLE.ELECTRIC' })
                : intl.formatMessage({ id: 'CHARTTITLE.GAS' })}
            </span>
          </h3>

          <div className='card-toolbar' data-kt-buttons='true'>
            <FilterButton
              id='yesterday'
              value='Yesterday'
              isActive={chartType === 'yesterday'}
              onClick={handleButtonClick}
            />
            <FilterButton
              id='last_one_week'
              value='Last 7 Days'
              isActive={chartType === 'last_one_week'}
              onClick={handleButtonClick}
            />
            <FilterButton
              id='last_one_month'
              value='Last 30 Days'
              isActive={chartType === 'last_one_month'}
              onClick={handleButtonClick}
            />
            <FilterButton
              id='last_one_year'
              value='Last 365 Days'
              isActive={chartType === 'last_one_year'}
              onClick={handleButtonClick}
            />
            <FilterButton
              id='custom'
              value='Custom'
              isActive={chartType === 'custom'}
              onClick={handleButtonClick}
            />
          </div>
        </div>
        <div className='card-body'>
          {chartType == 'custom' ? (
            <div className='w-md-50'>
              <DateRangeSelector
                name='custom_range'
                title={intl.formatMessage({ id: 'DATERANGE' })}
                seperator='to'
                ranges={[
                  {
                    startDate: rangeStart.toDate(),
                    endDate: rangeStart.toDate(),
                  },
                ]}
                rangeSelected={(range: Range) => {
                  setRangeStart(moment(range.startDate))
                  setRangeEnd(moment(range.endDate))
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {isLoading ? (
            <>
              <span className='indicator-progress' style={{ display: 'block' }}>
                {intl.formatMessage({ id: 'LOADING' })}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>{' '}
            </>
          ) : (
            chartData &&
            chartData.datasets &&
            chartData.datasets.length > 0 &&
            chartData.labels && (
              <>
                <LineChart
                  datasets={chartData.datasets}
                  labels={chartData.labels}
                  meterType={meterType}
                />
              </>
            )
          )}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}
export default DashboardSingleChart
