import React, { useEffect, useState } from 'react'
import ChartGroupSitesFilter from './ChartGroupSitesFilter'
import { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem, SelectItem } from '../../charts/core/_models'
import { ChartGroup } from './ChartGroup'

type chartGroupSitesProps = {
  companyItem: SingleValue<SelectItem>
  yearItem: SingleValue<SelectItem>
}
const ChartsGroupSitesSection: React.FC<chartGroupSitesProps> = ({ companyItem, yearItem }) => {
  let [companyFilter, setCompanyFilter] = useState<SingleValue<CompanyListItem>>(companyItem)
  let [siteFilter, setSiteFilter] = useState<MultiValue<CompanyListItem>>([])
  let [scopeFilter, setScopeFilter] = useState<SingleValue<CompanyListItem>>(null)
  let [yearFilter, setYearFilter] = useState<SingleValue<CompanyListItem>>(yearItem)
  useEffect(() => {
    setCompanyFilter(companyItem)
  }, [companyItem])

  useEffect(() => {
    setYearFilter(yearItem)
  }, [yearItem])
  return (
    <div>
      <section>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='col-12'>
            <ChartGroupSitesFilter
              companyFilter={companyFilter}
              setCompanyFilter={setCompanyFilter}
              siteFilter={siteFilter}
              setSiteFilter={setSiteFilter}
              scopeFilter={scopeFilter}
              setScopeFilter={setScopeFilter}
              yearFilter={yearFilter}
              setYearFilter={setYearFilter}
            />
          </div>
        </div>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6'>
            <ChartGroup
              className='card-xl-stretch mb-xl-8'
              chartColor='orange'
              chartHeight='300px'
              company={companyFilter?.value}
              sites={siteFilter}
              year={yearFilter?.value}
              scope={scopeFilter?.value}
              dataPeriod={'monthly'}
            />
          </div>
          <div className='col-xl-6'>
            <ChartGroup
              className='card-xl-stretch mb-5 mb-xl-8'
              chartColor='orange'
              chartHeight='300px'
              company={companyFilter?.value}
              sites={siteFilter}
              year={yearFilter?.value}
              scope={scopeFilter?.value}
              dataPeriod={'yearly'}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default ChartsGroupSitesSection
