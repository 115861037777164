import React, { useEffect, useState } from 'react'
import { Modal, FormGroup } from 'react-bootstrap'
import axios from 'axios'
import Field from '../../../../utils/Form/Widgets/Field'
import Textarea from '../../../../utils/Form/Widgets/Textarea'

type SendDataModalProps = {
  show: boolean
  dataRequestId: string
  onHide: () => void
  refreshFunction?: Function
}

const NewMailModal: React.FC<SendDataModalProps> = ({
  show,
  dataRequestId,
  onHide,
  refreshFunction,
}) => {
  const [formData, setFormData] = useState({
    subject: '',
    mailTo: '',
    mailBody: '',
  })
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      mailBody: 'Enter mail body.',
    }))
  }, [show])
  const handleSubjectInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      subject: value,
    }))
  }
  const handleMailToInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      mailTo: value,
    }))
  }

  const handleMailBodyInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      mailBody: value,
    }))
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const response = await axios.post('/data_requests/sendNewMail', {
        subject: formData.subject,
        mail_to: formData.mailTo,
        mail_body: formData.mailBody,
      })

      if (response.status === 200) {
        console.log('Email sent successfully.')
        onHide()
      } else {
        console.log('API error:', response)
      }
    } catch (error) {
      console.log('An error occurred:', error)
    }
  }

  return (
    <Modal
      id='kt_modal_create_company'
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      size='lg'
      show={show}
      backdrop={true}
      onHide={onHide}
    >
      <form
        onSubmit={(event) => {
          handleSubmit(event).then((response) => {
            if (refreshFunction) {
              refreshFunction(response)
            }
          })
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormGroup>
              <Field
                title='Subject'
                className='form-control'
                type='text'
                name='subject'
                onInput={handleSubjectInputChange}
                placeholder='Subject'
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Field
                title='Mail To'
                className='form-control'
                type='text'
                name='mail_to'
                onInput={handleMailToInputChange}
                placeholder='test@energyimpact.com'
                required={true}
              />
            </FormGroup>
            <FormGroup>
              <Textarea
                title='Mail Body'
                className='form-control'
                type='textarea'
                name='mail_body'
                onInput={handleMailBodyInputChange}
                placeholder='Mail body'
                required={true}
              />
            </FormGroup>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <button type='submit' className='btn btn-primary btn-sm'>
            Send Mail
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default NewMailModal
