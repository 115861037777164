import React, { FC, SyntheticEvent, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import Table from '../../../utils/ResultViewer/Table'
import { DataFileEditModal } from '../DataFileEditModal'
import { ManuelFileInputModal } from './ManuelFileInputModal'
import { useIntl } from 'react-intl'

const DataFilesList: FC = () => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const [manuelFileInput, setManuelFileInput] = useState(false)
  const intl = useIntl()
  return (
    <>
      <Table
        title={intl.formatMessage({ id: 'DATA.FILESLIST' })}
        dataUrl='/data_files/list'
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForUpdate(id)
        }}
        deleteCallback={(id: any, refresh: Function) => {
          axios.get('/data_files/save/' + id).then((response) => {
            let csrfData = response.data.data
            Swal.fire({
              title: 'Warning',
              html: 'Are you want to delete this data file path?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                axios
                  .delete('data_files/save/' + id, {
                    data: csrfData,
                  })
                  .then(() => {
                    refresh()
                  })
              }
            })
          })
        }}
        actionItems={[
          {
            text: intl.formatMessage({ id: 'DATA.MANUALFILEINPUT' }),
            class: 'btn btn-sm btn-success me-2',
            icon: 'fa fa-plus',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              setManuelFileInput(true)
              setRefreshFunction(() => refresh)
            },
          },
        ]}
      />
      {itemIdForUpdate !== '' ? (
        <DataFileEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onHide={() => {
            setItemIdForUpdate('')
          }}
          refreshFunction={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
      {manuelFileInput ? (
        <ManuelFileInputModal
          show={manuelFileInput}
          onHide={() => {
            setManuelFileInput(false)
          }}
          refreshFunction={() => {
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export { DataFilesList }
