/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useThemeMode } from '../../../../_metronic/partials'
import { KTSVG } from '../../../../_metronic/helpers'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { useIntl } from 'react-intl'

type Props = {
  className: string
  chartHeight: string
  chartPercent: number
  thisYearTotalData: number
  lastYearTotalData: number
  readingType: string
  type: string
}

const CompareTotalData: React.FC<Props> = ({
  className,
  chartHeight,
  chartPercent,
  thisYearTotalData,
  lastYearTotalData,
  readingType,
  type,
}) => {
  let chartColor = 'yellow'
  if (chartPercent < 0) {
    chartColor = 'success'
  } else if (chartPercent == 0) {
    chartColor = 'yellow'
  } else {
    chartColor = 'danger'
  }

  let unit = ''
  if (type == 'co2') {
    unit = 'tCO2e'
  } else {
    unit = 'kWh'
  }

  const arrowPathUp = '/media/icons/duotune/arrows/arr066.svg'
  const arrowPathDown = '/media/icons/duotune/arrows/arr065.svg'
  let arrowPath = '/media/icons/duotune/arrows/arr064.svg'
  if (chartPercent < 0) {
    arrowPath = arrowPathDown
  } else if (chartPercent == 0) {
    arrowPath = arrowPath
  } else {
    arrowPath = arrowPathUp
  }
  const intl = useIntl()
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartColor, chartHeight, chartPercent)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartPercent])
  return (
    <div className={`card ${className}`}>
      <div className='card-body d-flex flex-column'>
        <div className='fs-4 fw' style={{ textAlign: 'center' }}>
          {intl.formatMessage({ id: readingType })}
        </div>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>
        {/* begin::Stat */}
        <div style={{ textAlign: 'center' }}>
          <div className='fs-12'>
            {thisYearTotalData} {unit} ({intl.formatMessage({ id: 'THISYEAR' })})
          </div>
          <div className='fs-12'>
            {lastYearTotalData} {unit} ({intl.formatMessage({ id: 'LASTYEAR' })})
          </div>
          <KTSVG path={arrowPath} className={`svg-icon-5 svg-icon-${chartColor} ms-1`} />
        </div>
        {/* end::Stat */}
      </div>
    </div>
  )
}

const chartOptions = (
  chartColor: string,
  chartHeight: string,
  chartPercent: number
): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--bs-gray-700')
  return {
    series: [Math.abs(chartPercent)],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '50%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '350',
          },
          value: {
            color: labelColor,
            fontSize: '18px',
            fontWeight: '350',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export { CompareTotalData }
