import React from 'react'
import Table from '../../../utils/ResultViewer/Table'

class SecrDocumentsTable extends Table {
  renderRow(row: any, key: string, colIndex: number) {
    if (key === 'edit_actions') {
      return (
        <td key={colIndex}>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <button
              onClick={() => this.props.editCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <span className='fa fa-edit'></span>
            </button>
          </div>
        </td>
      )
    }
    if (key === 'file_path') {
      return <React.Fragment key={colIndex}></React.Fragment>
    }
    if (key === 'file_name') {
      return (
        <td key={colIndex}>
          <a href={row['file_path']} target='_blank' rel='noreferrer'>
            {row['file_name']}
          </a>
        </td>
      )
    }
    return super.renderRow(row, key, colIndex)
  }
}

export default SecrDocumentsTable
