import { Link, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'

const DashboardToolbar = () => {
  const { pathname } = useLocation()
  const intl = useIntl()
  const isDashboardPage = pathname === '/dashboard'
  const isDashboardGroupPage = pathname === '/dashboard/group'

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      {isDashboardPage && (
        <Link to='/dashboard/group' className='btn btn-sm fw-bold btn-primary'>
          {intl.formatMessage({ id: 'DASHBOARD.GROUPVIEW' })}
        </Link>
      )}

      {isDashboardGroupPage && (
        <Link to='/dashboard' className='btn btn-sm fw-bold btn-primary'>
          {intl.formatMessage({ id: 'DASHBOARD.SINGLEVIEW' })}
        </Link>
      )}
    </div>
  )
}

export { DashboardToolbar }
