import React, { useState } from 'react'
import { Mail } from './core/_models'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

import ReplyMailModal from './ReplyMailModal'
import ForwardMailModal from './ForwardMailModal'
import axios from 'axios'

const MailDetail: React.FC<Mail> = (mail) => {
  const [replyMail, setReplyMail] = useState('')
  const [forwardMail, setForwardMail] = useState('')
  const handleReply = (mail: Mail) => {
    setReplyMail('add')
  }
  const handleForward = (mail: Mail) => {
    setForwardMail('add')
  }

  const handleDownloadClick = async (messageId: string, attachmentId: string) => {
    try {
      const downloadUrl = `/microsoft_integration/downloadAttachment?messageId=${messageId}&attachmentId=${attachmentId}`
      axios({
        url: downloadUrl,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url2 = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url2
        link.setAttribute('download', 'file.csv')
        document.body.appendChild(link)
        link.click()
      })
      window.URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      console.error('Download error:', error)
    }
  }
  return (
    <div className='card-body'>
      {/* Message content */}
      <div className='collapse fade show' data-kt-inbox-message='message'>
        <div dangerouslySetInnerHTML={{ __html: mail.body.content }} className='py-5' />
      </div>
      {/* Message content */}
      <div className='border-bottom border-gray-200 mt-4 mb-3' key={mail.id}></div>
      <div className='row'>
        {mail.mailAttachments && mail.mailAttachments.length > 0 ? (
          mail.mailAttachments.map((attachment) => (
            <div className='col-2 col-sm-2 col-sm' key={attachment.fileName}>
              <div className='card h-100'>
                <div
                  className='card-body text-center'
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDownloadClick(attachment.mailId, attachment.id)}
                >
                  <div className='symbol symbol-45px mb-3'>
                    <img src={toAbsoluteUrl('/media/svg/files/csv.svg')} alt='' />
                  </div>
                  <div className='fs-5 fw-bolder mb-2'>{attachment.fileName}</div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ fontStyle: 'italic', color: 'grey' }}>There isn't any attachment.</div>
        )}
      </div>
      {/* Divider */}
      <div className='border-bottom border-gray-200 mt-4 mb-3'></div>
      {/* Divider */}

      {/* Reply and Forward icons */}
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center'>
          <i
            className='bi bi-reply me-2'
            onClick={() => {
              handleReply(mail)
            }}
            style={{ cursor: 'pointer' }}
          >
            <span>Reply</span>
          </i>
        </div>
        {replyMail !== '' ? (
          <ReplyMailModal
            show={replyMail !== ''}
            dataRequestId={replyMail}
            onHide={() => {
              setReplyMail('')
            }}
            refreshFunction={(itemId: any) => {
              if (itemId !== undefined) {
                setReplyMail(itemId)
              }
            }}
            mail={mail}
          />
        ) : (
          <></>
        )}

        <div className='d-flex align-items-center'>
          <i
            className='bi bi-arrow-right-circle me-2'
            onClick={() => {
              handleForward(mail)
            }}
            style={{ cursor: 'pointer' }}
          >
            <span>Forward</span>
          </i>
        </div>
        {forwardMail !== '' ? (
          <ForwardMailModal
            show={forwardMail !== ''}
            dataRequestId={forwardMail}
            onHide={() => {
              setForwardMail('')
            }}
            refreshFunction={(itemId: any) => {
              if (itemId !== undefined) {
                setForwardMail(itemId)
              }
            }}
            mail={mail}
          />
        ) : (
          <></>
        )}
      </div>
      {/* Reply and Forward icons */}
    </div>
  )
}

export default MailDetail
