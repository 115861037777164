import axios from 'axios'
import React, { FC, useEffect, useState } from 'react'
import { Modal, Button, Badge } from 'react-bootstrap'
import { NoteEditModal } from './NoteEditModal'
import { Navigate, useNavigate } from 'react-router-dom'

interface CompanyNotesModalProps {
  show: boolean
  itemId: string
  onHide: () => void
}

interface Note {
  ID: number
  created_at: string
  last_updated: string
  title: string
  status: string
  note: string
}

const CompanyNotesModal: FC<CompanyNotesModalProps> = ({ show, itemId, onHide }) => {
  const navigate = useNavigate()
  const [notes, setNotes] = useState<Note[]>([])
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')

  const getNotes = () => {
    axios.get('/company/notes/getNotes/' + itemId).then((response) => {
      if (response.data.data) {
        setNotes(response.data.data)
      }
    })
  }

  const handleView = (id: number) => {
    setItemIdForUpdate(id.toString())
  }

  const handleAdd = () => {
    setItemIdForUpdate('add')
  }

  useEffect(() => {
    if (show) {
      getNotes()
    }
  }, [show])

  // Function to get badge color based on status
  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'active':
        return <Badge bg='success'>Active</Badge>
      case 'passive':
        return <Badge bg='secondary'>Passive</Badge>
      default:
        return <Badge bg='dark'>{status}</Badge>
    }
  }

  // Refresh notes after editing
  const handleRefreshNotes = () => {
    getNotes()
    setItemIdForUpdate('') // Reset the item ID for update
  }

  return (
    <>
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Company Notes - {itemId}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {notes.length > 0 ? (
            <table className='table table-striped table-hover'>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>Title</th>
                  <th>Note</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
              <tbody>
                {notes.map((note) => (
                  <tr key={note.ID}>
                    <td>
                      <Button
                        variant='primary'
                        size='sm'
                        style={{ marginLeft: '5px', padding: '2px 5px', fontSize: '12px' }}
                        onClick={() => handleView(note.ID)}
                      >
                        View
                      </Button>
                    </td>
                    <td>{note.title}</td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: note.note
                            ? note.note.substring(0, 200) + (note.note.length > 200 ? '...' : '')
                            : '',
                        }}
                      />
                    </td>
                    <td>{getStatusBadge(note.status)}</td>
                    <td>{note.created_at}</td>
                    <td>{note.last_updated}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No notes available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => handleAdd()}>
            Add Note
          </Button>
          <Button variant='info' onClick={() => navigate('/company/notes')}>
            All Notes
          </Button>
          <Button variant='secondary' onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {itemIdForUpdate !== '' ? (
        <NoteEditModal
          show={true}
          itemIdForUpdate={itemIdForUpdate}
          onHide={() => {
            handleRefreshNotes()
          }}
        />
      ) : null}
    </>
  )
}

export default CompanyNotesModal
