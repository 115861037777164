import React, { useEffect, useState } from 'react'
import { CompareTotalData } from './CompareTotalData'
import { getCompareDataApi, getStatesByKey } from '../core/_requests'
import { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem, SelectItem } from '../core/_models'
import { useIntl } from 'react-intl'

type Props = {
  company: SingleValue<SelectItem>
  companySite: MultiValue<CompanyListItem>
  meters: MultiValue<CompanyListItem>
  readingType: string
  meterType: string
}
const TotalDataCo2Box: React.FC<Props> = ({
  company,
  companySite,
  meters,
  readingType,
  meterType,
}) => {
  const [compareData, setCompareData] = useState([0, 0, 0])
  const [thisYear, setThisYear] = useState(0)
  const [lastYear, setLastYear] = useState(0)
  const [percent, setPercent] = useState(0)
  const intl = useIntl()
  const [loading, setLoading] = useState(true)
  let conversionFactorType = 'conversion_factor_electric'
  let type = 'Electricity'
  if (meterType == 'Gas') {
    conversionFactorType = 'conversion_factor_gas'
    type = 'Gas'
  } else if (meterType == 'Electric') {
    conversionFactorType = 'conversion_factor_electric'
    type = 'Electricity'
  }
  const params = {
    isLoggedIn: true,
    companyId: company ? company.value : '',
    siteId: companySite,
    metersId: meters,
    meterType: type,
    readingType: readingType,
  }

  let [conversionFactor, setConversionFactor] = useState(1)

  const fetchCompareData = async () => {
    getCompareDataApi({ ...params }).then((response) => {
      setTimeout(async () => {
        const compareData = await getCompareDataApi(params)
        setCompareData(compareData)
        setPercent(compareData[2])
        setThisYear(compareData[0] ? compareData[0] : 0)
        setLastYear(compareData[1] ? compareData[1] : 0)
        setLoading(false)
      }, 1000)
    })

    getStatesByKey(conversionFactorType).then((response) => {
      if (response.data) {
        setConversionFactor(response.data)
      }
    })
  }

  useEffect(() => {
    if (company || companySite || meters) {
      fetchCompareData()
      setLoading(true)
    }
  }, [company, companySite, meters, meterType])

  return (
    <>
      {loading ? (
        <span className='indicator-progress' style={{ display: 'block', textAlign: 'center' }}>
          {intl.formatMessage({ id: 'LOADING' })}...{' '}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      ) : (
        compareData && (
          <CompareTotalData
            className='card-xl-stretch mb-xl-8'
            chartHeight='150px'
            chartPercent={percent}
            thisYearTotalData={parseFloat(((thisYear * conversionFactor) / 1000).toFixed(2))}
            lastYearTotalData={parseFloat(((lastYear * conversionFactor) / 1000).toFixed(2))}
            readingType={readingType}
            type='co2'
          />
        )
      )}
    </>
  )
}

export default TotalDataCo2Box
