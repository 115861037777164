import AnalyticChart from './AnalyticChart'
import FilterFormAnalytics from './FilterFormAnalytics'
import { useState } from 'react'
import { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem, SelectItem } from '../charts/core/_models'
import { meterType, predictionAlgorithmType } from '../reports/core/_models'
import CompareChart from './CompareChart'

const AnalyticsPage = () => {
  let [companyFilter, setCompanyFilter] = useState<SingleValue<CompanyListItem>>(null)
  let [siteFilter, setSiteFilter] = useState<MultiValue<CompanyListItem>>([])
  let [metersFilter, setMetersFilter] = useState<MultiValue<SelectItem>>([])
  let [meterTypeFilter, setMeterTypeFilter] = useState<SingleValue<meterType>>(null)
  let [selectedMeters, setSelectedMeters] = useState<MultiValue<CompanyListItem>>([])
  let [predictionAlgorithmFilter, setPredictionAlgorithmFilter] = useState<
    MultiValue<predictionAlgorithmType>
  >([])

  const [selectedInfo, setSelectedInfo] = useState('Trend Analysis')

  const infoDescriptions: Record<string, string> = {
    'Trend Analysis':
      'The Trend Analysis algorithm utilizes historical data from the past 15 days to create a mathematical trend model. This model is used to predict the energy consumption for the next day.',
    'Trend Analysis (10%)':
      'The Trend Analysis 10% study involved obtaining results by reducing the outcomes of the Trend Analysis algorithm by 10%.',
    'Trend Analysis (10%) Minus 1 Day':
      'The Trend Analysis 10% Minus 1 Day study, involved obtaining the results of the Trend Analysis algorithm by reducing them by 10%, and the dates of the data in these results were adjusted one day backward.',
    'Statistical Approach: Average':
      'Statistical Approach: Average study, a statistical evaluation method using the mean has been employed. If there is less than 7 days of data available for any meter, the prediction value is the average of previous days. However, if there is more than 7 days of data, the prediction value is calculated by considering the data from the same days in previous weeks, based on the day of the week. Additionally, the look-back period for historical data is limited to 2 months.',
  }
  const handleInfoClick = (info: any) => {
    setSelectedInfo(info)
  }

  return (
    <div>
      <FilterFormAnalytics
        companyFilter={companyFilter}
        setCompanyFilter={setCompanyFilter}
        siteFilter={siteFilter}
        setSiteFilter={setSiteFilter}
        metersFilter={metersFilter}
        setMetersFilter={setMetersFilter}
        meterTypeFilter={meterTypeFilter}
        setMeterTypeFilter={setMeterTypeFilter}
        selectedMeters={selectedMeters}
        setSelectedMeters={setSelectedMeters}
        predictionAlgorithmFilter={predictionAlgorithmFilter}
        setPredictionAlgorithmFilter={setPredictionAlgorithmFilter}
      />
      <br></br>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column' style={{ width: '100%' }}>
                  <div className='d-flex align-items-center mb-2'>
                    <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      Information:
                    </div>
                  </div>
                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    {Object.keys(infoDescriptions).map((info) => (
                      <a
                        key={info}
                        href='javascript:void(0)'
                        className={`d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2`}
                        style={{
                          backgroundColor: selectedInfo === info ? '#f0f0f0' : 'transparent',
                          color: selectedInfo === info ? '#333' : '#666',
                        }}
                        onClick={() => handleInfoClick(info)}
                      >
                        {info}
                      </a>
                    ))}
                  </div>

                  {selectedInfo && (
                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                      {infoDescriptions[selectedInfo]}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <CompareChart
        companyFilter={companyFilter}
        siteFilter={siteFilter}
        metersFilter={metersFilter}
        meterTypeFilter={meterTypeFilter}
      />
      <br></br>
      <AnalyticChart
        companyFilter={companyFilter}
        siteFilter={siteFilter}
        metersFilter={metersFilter}
        meterTypeFilter={meterTypeFilter}
        readingType='custom'
        predictionAlgorithmFilter={predictionAlgorithmFilter}
        customDateFilter={true}
      />

      <br></br>
      <AnalyticChart
        companyFilter={companyFilter}
        siteFilter={siteFilter}
        metersFilter={metersFilter}
        meterTypeFilter={meterTypeFilter}
        readingType='yesterday'
        predictionAlgorithmFilter={predictionAlgorithmFilter}
        customDateFilter={false}
      />
      <br></br>
      <AnalyticChart
        companyFilter={companyFilter}
        siteFilter={siteFilter}
        metersFilter={metersFilter}
        meterTypeFilter={meterTypeFilter}
        readingType='last_one_week'
        predictionAlgorithmFilter={predictionAlgorithmFilter}
        customDateFilter={false}
      />
      <br></br>
      <AnalyticChart
        companyFilter={companyFilter}
        siteFilter={siteFilter}
        metersFilter={metersFilter}
        meterTypeFilter={meterTypeFilter}
        readingType='last_one_month'
        predictionAlgorithmFilter={predictionAlgorithmFilter}
        customDateFilter={false}
      />
      <br></br>
      <AnalyticChart
        companyFilter={companyFilter}
        siteFilter={siteFilter}
        metersFilter={metersFilter}
        meterTypeFilter={meterTypeFilter}
        readingType='last_one_year'
        predictionAlgorithmFilter={predictionAlgorithmFilter}
        customDateFilter={false}
      />
      <br></br>

      <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
        <div className='fw-bold'>
          <h4 className='text-gray-800 fw-bolder'>Information:</h4>
          <div className='fs-6 text-gray-600'>
            The chart below displays the data for the upcoming week along with the data from two
            days prior. The predicted values here are calculated using a Statistical Approach:
            Average method.
          </div>
        </div>
      </div>
      <br></br>
      <AnalyticChart
        companyFilter={companyFilter}
        siteFilter={siteFilter}
        metersFilter={metersFilter}
        meterTypeFilter={meterTypeFilter}
        readingType='next_week'
        predictionAlgorithmFilter={[]}
        customDateFilter={false}
      />
    </div>
  )
}

export default AnalyticsPage
