import axios from 'axios'
import { ResultProps } from '../../../../utils/ResultViewer/ResultViewer'
import Table from '../../../../utils/ResultViewer/Table'

type CompanyProps = ResultProps & {
  viewCallback: Function
} & {
  notesCount: any
}
class CompanyTable extends Table {
  //@ts-ignore
  public state: CompanyProps
  constructor(props: CompanyProps) {
    super(props)
    this.state = {
      title: props.title,
      actionItems: props.actionItems,
      dataUrl: props.dataUrl,
      data: {
        headers: {},
        data: [],
        search: [],
        pagination: {
          limit: 100,
          page: 1,
          total_count: 0,
        },
      },
      page: 1,
      orderBy: '',
      orderDirection: '',
      editCallback: props.editCallback,
      deleteCallback: props.deleteCallback,
      filterParams: {},
      viewCallback: props.viewCallback,
      notesCount: props.notesCount,
    }
  }
  componentDidMount(): void {
    axios.get('/company/getCompanyNotesCount').then((response) => {
      this.setState({
        ...this.state,
        notesCount: response.data.data,
      })
    })
    this.fetchData()
  }
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key === 'logo') {
      let imageUrl = row[key] ? row[key] : '/public/media/avatars/blank.png'
      return (
        <td key={colIndex}>
          <div className='symbol symbol-45px me-5'>
            <img src={imageUrl} alt={row['name']} />
          </div>
        </td>
      )
    } else if (key === 'is_active' || key === 'status') {
      let badgeClass = 'badge-success'
      switch (row[key]) {
        case 'Active':
          badgeClass = 'badge-success'
          break
        case 'Passive':
          badgeClass = 'badge-warning'
          break
        case 'Disabled':
          badgeClass = 'badge-warning'
          break
      }
      return (
        <td key={colIndex}>
          <div className={'badge ' + badgeClass}>{row[key]}</div>
        </td>
      )
    } else if (key === 'edit_actions') {
      return (
        <td key={colIndex}>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <button
              onClick={() => this.props.editCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <span className='fa fa-edit'></span>
            </button>
            <button
              onClick={() => this.props.deleteCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
            >
              <span className='fa fa-trash'></span>
            </button>
            <button
              onClick={() => this.state.viewCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 position-relative'
            >
              {this.state.notesCount &&
                this.state.notesCount.map(
                  (item: any) =>
                    item.company === row[key] && (
                      <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                        {item.notes_count}
                      </span>
                    )
                )}
              <span className='fas fa-book'></span>
            </button>
          </div>
        </td>
      )
    } else {
      return super.renderRow(row, key, colIndex)
    }
  }
}

export default CompanyTable
