import React, { useEffect } from 'react'
type DataChartProps = {
  onChartTypeChange: (chartType: string) => void
  defaultValue?: string
}

const DataChart: React.FC<DataChartProps> = ({ onChartTypeChange, defaultValue }) => {
  const options = [
    { id: 'line', label: 'Line' },
    { id: 'bar', label: 'Bar' },
    { id: 'radar', label: 'Radar' },
    { id: 'area', label: 'Area' },
  ]

  const [selectedMethod, setSelectedMethod] = React.useState<string>(options[0].id)

  useEffect(() => {
    if (defaultValue) {
      setSelectedMethod(defaultValue)
      onChartTypeChange(defaultValue)
    } else {
      setSelectedMethod(options[0].id)
      onChartTypeChange(options[0].id)
    }
  }, [])

  const handleMethodChange = (id: any) => {
    setSelectedMethod(id)
    onChartTypeChange(id)
  }

  return (
    <div className='mb-10'>
      <div className='mb-3'>
        <label className='d-flex align-items-center fs-5 fw-semibold'>
          <span className='required'>Chart Type</span>
        </label>
      </div>
      <div className='btn-group w-100'>
        {options.map((option) => (
          <label
            key={option.id}
            className={`btn btn-outline btn-active-success btn-color-muted ${
              selectedMethod === option.id ? 'active' : ''
            }`}
          >
            <input
              className='btn-check'
              type='radio'
              name='method'
              value={option.id}
              checked={selectedMethod === option.id}
              onChange={() => handleMethodChange(option.id)}
            />
            {option.label}
          </label>
        ))}
      </div>
      <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
    </div>
  )
}

export default DataChart
