import { FC, useState } from 'react'
import { Modal } from 'react-bootstrap'
import BlockInterface, {
  BlockAttributes,
  BlockVariation,
  getAvailableBlocks,
} from './BlockInterface'

type Props = {
  onHide: () => void
  onSelect: (item: BlockAttributes) => void
  show: boolean
  blocks: any
}

const AddTemplateWidgetModal: FC<Props> = (props: Props) => {
  var [selectedGroup, setSelectedGroup] = useState('layout')
  var [selectedVariation, setSelectedVariation] = useState<any>(null)
  const blocks = getAvailableBlocks(props.blocks)
  return (
    <Modal
      id='template_block_select_modal'
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      size='xl'
      show={props.show}
      backdrop={true}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Type To Add</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-3'>
            <div className='menu menu-rounded menu-column menu-active-bg menu-hover-bg menu-title-gray-700 fs-5 fw-semibold w-100'>
              {Object.keys(blocks).map(function (key: any) {
                let block: BlockInterface = blocks[key]
                return (
                  <div
                    key={key}
                    className='menu-item'
                    onClick={() => {
                      setSelectedGroup(key)
                    }}
                  >
                    <div
                      className={
                        'menu-link border-3 border-start' +
                        (selectedGroup === key ? ' border-primary active' : '')
                      }
                    >
                      <span className='menu-title'>{block.getName()}</span>
                      <span className={block.getIconClass()}></span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='col-md-9'>
            <div className='d-flex flex-wrap gap-10'>
              {selectedGroup && blocks[selectedGroup as any] ? (
                blocks[selectedGroup as any]
                  .getVariationOptions()
                  .map((variation: BlockVariation, index) => {
                    return (
                      <label
                        key={index}
                        className={
                          'form-check-image text-center' +
                          (selectedVariation === variation.name
                            ? ' active text-primary'
                            : ' text-gray-800')
                        }
                      >
                        <div className='form-check-wrapper display-3'>
                          <span className={variation.icon}></span>
                        </div>

                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='radio'
                            defaultChecked={selectedVariation === variation.name}
                            onClick={(e) => {
                              setSelectedVariation(variation.name)
                            }}
                            name='variation'
                          />
                          <div className='form-check-label'>{variation.name}</div>
                        </div>
                      </label>
                    )
                  })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-end'>
        <button
          type='button'
          onClick={(event) => {
            props.onHide()
          }}
          className='btn btn-danger btn-sm'
        >
          Cancel
        </button>
        <button
          type='submit'
          className='btn btn-success btn-sm'
          onClick={() => {
            props.onHide()
            let block = blocks[selectedGroup as any]
            let variation: BlockVariation[] = block.getVariationOptions().filter((obj) => {
              return obj.name === selectedVariation
            })
            props.onSelect({
              type: selectedGroup,
              attributes: variation[0].attribute,
            })
          }}
        >
          Add Block
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddTemplateWidgetModal
