import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../utils/Form/FormHandler'
import Field from '../../utils/Form/Widgets/Field'
import Check from '../../utils/Form/Widgets/Check'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}

class DataFile {
  file_path?: string
  processed?: string = '0'
  is_processing?: string = '0'
}

class DataFileEditModal extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new DataFile() }
  }

  getApiUrl(): string {
    return '/data_files/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/data_files/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      file_process: this.state.entity,
    }
  }
  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Data File Path'
    if (this.state.entity && this.props.itemIdForUpdate != 'add') {
      let entity = this.state.entity as any
      title = entity.name
    }
    return (
      <Modal
        id='kt_modal_create_company'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title='Data File Paths'
                className='form-control'
                type='text'
                name='file_path'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.file_path}
              />
            </FormGroup>
            <FormGroup>
              <Check
                title='Processed'
                name='processed'
                type='switch'
                checked={state.entity.processed ? true : false}
                options={['']}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { DataFileEditModal }
