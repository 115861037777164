import axios, { AxiosResponse } from 'axios'

export function getNotifications() {
  const url = `/notifications/list`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function formatDate(inputDate: any) {
  const date = new Date(inputDate)
  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.getFullYear()
  return `${day} ${month} ${year}`
}

export function calculateTimeDifference(dateString: string | number | Date) {
  const date = new Date(dateString)
  //@ts-ignore
  if (isNaN(date)) {
    return 'Invalid date'
  }

  const now = new Date()
  //@ts-ignore
  const difference = now - date

  const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24))
  const hoursDifference = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))

  if (daysDifference > 30) {
    return `${Math.floor(daysDifference / 30)} month${daysDifference >= 60 ? 's ago' : ''}`
  } else if (daysDifference > 7) {
    return `${Math.floor(daysDifference / 7)} week${daysDifference >= 14 ? 's ago' : ''}`
  } else if (daysDifference > 0 || hoursDifference > 0) {
    let result = ''
    if (daysDifference > 0) {
      result += `${daysDifference} day${daysDifference > 1 ? 's' : ''} `
    }
    if (hoursDifference > 0) {
      result += `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''}`
    }
    return result + ` ago`
  } else {
    return 'Just now'
  }
}

export function getBodyField(type: string, body: string, field: string) {
  let parseArray = JSON.parse(body)
  if (type === 'alarms_site') {
    if (field === 'title') {
      field = 'siteName'
    } else if (field === 'date') {
      field = 'alarmDate'
    } else if (field === 'is_read') {
      field = 'siteAcknowledge'
    }
  }
  if (
    field &&
    Object.values(parseArray) &&
    Object.values(parseArray).length > 0 &&
    Object.keys(parseArray) &&
    Object.keys(parseArray).includes(field)
  ) {
    return parseArray[field]
  } else {
    return null
  }
}
