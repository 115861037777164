import React, { ReactNode } from 'react'
import BlockInterface, { BlockAttributes, BlockVariation } from '../BlockInterface'
import { FormGroup } from 'react-bootstrap'
import Field from '../../Form/Widgets/Field'
import DataChart from './DataChart'
import Chart from 'react-apexcharts'

export type DataBlockAttributes = {
  type: string
  chartType?: string
  caption?: string
}

export default class DataBlock implements BlockInterface {
  attributes: null | DataBlockAttributes = null

  getType(): string {
    return 'data'
  }
  setAttributes(attributes: DataBlockAttributes): void {
    this.attributes = attributes
  }
  getAttributes(): any {
    return this.attributes
  }
  getName(): string {
    return 'Data'
  }
  getIconClass(): string {
    return 'fas fa-chart-area'
  }
  getModalClasses(): string {
    if (this.attributes?.type === 'data') {
      return 'modal-fullscreen'
    } else {
      return ''
    }
  }
  getVariationOptions(): BlockVariation[] {
    return [
      {
        name: 'Table',
        icon: 'fa fa-table',
        attribute: {
          type: 'table',
          caption: '',
        },
      },
      {
        name: 'Chart',
        icon: 'fas fa-chart-pie',
        attribute: {
          type: 'chart',
          chartType: '',
        },
      },
    ]
  }
  renderTemplateView(
    onChange: () => void,
    openModal: (callback: (item: BlockAttributes) => void) => void
  ): ReactNode {
    if (this.attributes) {
      switch (this.attributes.type) {
        case 'table':
          const data = [
            { x: 'Jan', y: 30 },
            { x: 'Feb', y: 40 },
            { x: 'Mar', y: 80 },
            { x: 'Apr', y: 20 },
            { x: 'May', y: 70 },
          ]

          return (
            <table style={{ borderCollapse: 'collapse', width: '100%', borderColor: 'black' }}>
              <thead>
                <tr>
                  {Object.keys(data[0]).map((header, index) => (
                    <th
                      key={index}
                      style={{ border: '1px solid black', padding: '10px', textAlign: 'left' }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.values(row).map((cell, cellIndex) => (
                      <td
                        key={cellIndex}
                        style={{ border: '1px solid black', padding: '10px', textAlign: 'left' }}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )
        case 'chart':
          const chartData = {
            options: {
              chart: {
                id: 'basic-bar',
              },
              xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
              },
              toolbar: {
                show: false,
              },
            },
            series: [
              {
                name: 'Test',
                data: [30, 40, 45, 50, 49],
              },
            ],
          }
          const chartType: any = this.attributes.chartType || 'line'

          return (
            <div>
              <Chart
                options={chartData.options}
                series={chartData.series}
                type={chartType}
                height={250}
              />
            </div>
          )
      }
    }
    return <>{JSON.stringify(this.attributes)}</>
  }
  renderTemplateForm(): ReactNode {
    if (this.attributes) {
      switch (this.attributes.type) {
        case 'table':
          return (
            <div>
              <FormGroup>
                <Field
                  title='Caption'
                  className='form-control'
                  name='text'
                  defaultValue={this.attributes.caption}
                  onInput={(event: any) => {
                    ;(this.attributes as any).caption = event.target.value
                  }}
                />
              </FormGroup>
            </div>
          )
        case 'chart':
          return (
            <div className='d-flex flex-nowrap h-100 gap-3'>
              <FormGroup className='h-100'>
                <DataChart
                  onChartTypeChange={(event: any) => {
                    ;(this.attributes as any).chartType = event
                  }}
                  defaultValue={this.attributes.chartType}
                />
              </FormGroup>
            </div>
          )
      }
    }
    return <></>
  }
  clone(): BlockInterface {
    return new DataBlock()
  }
}
