import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useIntl } from 'react-intl'
import { getSiteChartData } from '../core/_requests'
import { Datasets } from '../../charts/core/_models'
import { Modal, Button } from 'react-bootstrap'

interface SiteChartModalProps {
  benchmarkData: {
    siteName: string
    companyValue: string
    siteValue: string
    start_date: string
    end_date: string
  }
  show: boolean
  onClose: () => void
}

function SiteChartModal({ benchmarkData, show, onClose }: SiteChartModalProps) {
  const {
    siteName: site,
    companyValue: company,
    siteValue: siteId,
    start_date: startDate,
    end_date: endDate,
  } = benchmarkData
  const [data, setData] = useState<any>({ labels: [], datasets: [] })
  const [isLoading, setLoading] = useState(true)

  const fetchDataForSite = async () => {
    try {
      setTimeout(async () => {
        const data = await getSiteChartData(
          benchmarkData.companyValue,
          benchmarkData.siteValue,
          benchmarkData.start_date,
          benchmarkData.end_date
        )
        setData(data)
        setLoading(false)
      }, 2000)
    } catch (error) {
      console.log('Error fetching alarm chart data:', error)
    }
  }

  useEffect(() => {
    if (siteId && startDate && endDate) {
      fetchDataForSite()
    }
  }, [siteId, startDate, endDate])

  const intl = useIntl()
  const chartData = {
    series:
      data.datasets && data.datasets.length > 0
        ? data.datasets.map((dataset: Datasets) => ({
            name: dataset.name,
            data: dataset.data,
          }))
        : [],
    options: {
      chart: {
        zoom: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 1,
      },
      stroke: {
        curve: 'smooth' as const,
      },
      colors: ['#0070FF', '#FFA500'],
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
      xaxis: {
        categories: data.labels,
        tickAmount: 6,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      height: 350,
    },
  }

  return (
    <Modal show={show} onHide={onClose} size='lg' centered>
      <Modal.Header closeButton>
        <Modal.Title>{benchmarkData.siteName} Chart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div style={{ textAlign: 'center' }}>
            {intl.formatMessage({ id: 'LOADING' })}...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </div>
        ) : data.datasets && data.datasets.length > 0 ? (
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            //@ts-ignore
            height={chartData.options.height}
          />
        ) : (
          <p>{intl.formatMessage({ id: 'NO_DATA_AVAILABLE' })}</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SiteChartModal
