import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import ConversionFilters from './ConversionFilters'
import { listing } from '../core/_requests'
import { ConversionFactorsEditModal } from './ConversionFactorsEditModal'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl'

const ConversionFactorsList = () => {
  const [year, setYear] = useState<number | null>(2024)
  const [name, setName] = useState<string | null>(null)
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')

  const [data, setData] = useState([])

  const intl = useIntl()

  const fetchData = async () => {
    try {
      const response = await listing(year ?? 2024, name ?? '')
      setData(response)
      console.log(response)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleClick = (itemId: string) => {
    setItemIdForUpdate(itemId)
  }

  const handleClickDelete = (id: any, refresh: Function) => {
    axios.get('/conversion_factors/save/' + id).then((response: { data: { data: any } }) => {
      let csrfData = response.data.data
      Swal.fire({
        title: 'Warning',
        html: 'Are you want to delete this conversion factor?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete('conversion_factors/save/' + id, {
              data: csrfData,
            })
            .then(() => {
              refresh()
            })
        }
      })
    })
  }

  useEffect(() => {
    fetchData()
  }, [year, name])

  return (
    <div className={`card mb-5 mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {intl.formatMessage({ id: 'conversion.list.title' })}
          </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {data &&
              data.length + ' ' + intl.formatMessage({ id: 'conversion.list.records' }) + ' found.'}
          </span>
        </h3>

        <ConversionFilters
          year={year}
          onYearChange={setYear}
          name={name}
          onNameChange={setName}
          handleClick={handleClick}
        />
      </div>
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th
                  className='min-w-100px text-start'
                  style={{ fontWeight: 'bold', color: 'black' }}
                >
                  {intl.formatMessage({ id: 'conversion.list.tableHead.actions' })}
                </th>
                <th className='min-w-150px' style={{ fontWeight: 'bold', color: 'black' }}>
                  {intl.formatMessage({ id: 'conversion.list.tableHead.year' })}
                </th>
                <th className='min-w-140px' style={{ fontWeight: 'bold', color: 'black' }}>
                  {intl.formatMessage({ id: 'conversion.list.tableHead.name' })}
                </th>
                <th className='min-w-120px' style={{ fontWeight: 'bold', color: 'black' }}>
                  {intl.formatMessage({ id: 'conversion.list.tableHead.conversionUnit' })}
                </th>
                <th className='min-w-120px' style={{ fontWeight: 'bold', color: 'black' }}>
                  {intl.formatMessage({ id: 'conversion.list.tableHead.value' })}
                </th>
                <th className='min-w-100px' style={{ fontWeight: 'bold', color: 'black' }}>
                  {intl.formatMessage({ id: 'conversion.list.tableHead.lastUpdated' })}
                </th>
              </tr>
            </thead>

            {/* begin::Table body */}
            <tbody>
              {data &&
                data.length > 0 &&
                data.map((item: any) => (
                  <tr key={item.ID}>
                    <td>
                      <div className='d-flex justify-content-start flex-shrink-0'>
                        <button
                          onClick={() => handleClick(item.ID)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </button>
                        <button
                          onClick={() => handleClickDelete(item.ID, fetchData)}
                          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-muted me-2 fs-7 fw-semibold'>{item.year}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-muted me-2 fs-7 fw-semibold'>{item.name}</span>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-semibold'>
                            {item.base_unit + '-->' + item.target_unit}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-semibold'>{item.value}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-semibold'>
                            {item.last_updated}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* end::Table container */}
        {(!data || data.length === 0) && (
          <div className='d-flex flex-column text-danger pe-0 pe-sm-10'>
            <span>{intl.formatMessage({ id: 'conversion.noData' })}</span>
          </div>
        )}
        {itemIdForUpdate !== '' ? (
          <ConversionFactorsEditModal
            show={itemIdForUpdate !== ''}
            itemIdForUpdate={itemIdForUpdate}
            onHide={() => {
              setItemIdForUpdate('')
            }}
            refreshFunction={(itemId: any) => {
              if (itemId !== undefined) {
                setItemIdForUpdate(itemId)
              }
              fetchData()
              setItemIdForUpdate('')
            }}
          />
        ) : (
          <></>
        )}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default ConversionFactorsList
