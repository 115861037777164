import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import LoraTable from './LoraTable'

const LoraLogsList: FC = () => {
  const intl = useIntl()
  return (
    <>
      <LoraTable
        title={intl.formatMessage({ id: 'MENU.LORALOGS' })}
        dataUrl='/lora_data/list'
        editCallback={(id: any, refresh: Function) => {}}
        deleteCallback={(id: any, refresh: Function) => {}}
        actionItems={[]}
      />
    </>
  )
}

export { LoraLogsList }
