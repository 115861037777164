import { Form } from 'react-bootstrap'
import { PropsWithChildren, useState } from 'react'
import Element, { BaseProps } from './Element'
import { FormControlProps } from 'react-bootstrap/FormControl'
import { AsProp, BsPrefixProps, ReplaceProps } from 'react-bootstrap/helpers'

export type FieldProps = PropsWithChildren<
  Omit<ReplaceProps<'input', BsPrefixProps<'input'> & BaseProps & FormControlProps>, 'as'> & AsProp
>

export default function Field(props: PropsWithChildren<FieldProps>) {
  const { name, title, required, children, ...restProps } = props

  function checkIsValid(event: any) {
    const value = event.target.value + event.key
    if (event.key.length == 1 && value.length > 0 && props.pattern) {
      let regex = new RegExp(props.pattern)
      if (!regex.test(value)) {
        event.preventDefault()
        event.stopPropagation()
        return false
      }
    }
    restProps.value = event.target.value
  }

  return (
    <Element title={title} required={required}>
      <Form.Control name={name} {...restProps} onKeyDown={checkIsValid} />
      {children}
    </Element>
  )
}

Field.defaultProps = {
  type: 'text',
  rows: 3,
}
