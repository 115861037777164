import clsx from 'clsx'
import { KTSVG } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useAuth } from '../../../../app/modules/auth/core/AuthProvider'
import { useEffect, useState } from 'react'
import { NotificationsMenu } from '../../../../app/modules/notifications/NotificationsMenu'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'

const Navbar = () => {
  const { config } = useLayout()
  const { user, logout } = useAuth()
  const [initials, setInitials] = useState('')
  const [alarmStatus, setAlarmStatus] = useState<boolean>(false)
  useEffect(() => {
    if (user) {
      let first = user.name ? user.name.charAt(0) : ''
      let second = user.surname ? user.surname.charAt(0) : ''
      let headerLabel = `${first}.${second}`
      setInitials(headerLabel)
    }
  }, [user])
  let blinkSpanClass =
    'bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={clsx('position-relative', btnClass)}
        >
          <KTSVG path='/media/icons/duotune/general/gen007.svg' className={btnIconClass} />
          {alarmStatus == true && <span className={blinkSpanClass}></span>}
        </div>
        <NotificationsMenu setAlarmStatus={setAlarmStatus} />
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              color: 'white',
              background: 'black',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {initials}
          </div>
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export { Navbar }
