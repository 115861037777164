import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'

import DocumentTitle from 'react-document-title'
import { SecrReportsList } from './components/SecrReportsList'
import AnnualReportsPage from '../annual-reports/AnnualReportsPage'
import SecrReportsEditPage from './components/SecrReportsEditPage'
import { useIntl } from 'react-intl'

const secrBreadCrumbs: Array<PageLink> = [
  {
    title: 'Secr Reports',
    path: '/secr_reports/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SecrReportsPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='secr_dashboard'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | Secr Dashboard'}
              ></DocumentTitle>
              <AnnualReportsPage />
            </>
          }
        />
        <Route
          path='list'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Secr Reports'}></DocumentTitle>
              <SecrReportsList />
            </>
          }
        />
        <Route
          path='edit/:secrReportId'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Secr Reports'}></DocumentTitle>
              <PageTitle breadcrumbs={secrBreadCrumbs}>Secr Reports Edit Page</PageTitle>
              <SecrReportsEditPage />
            </>
          }
        />
        <Route
          path='pdf/download/:secrReportId'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Secr Reports'}></DocumentTitle>
            </>
          }
        />
        <Route index element={<Navigate to='/secr_reports/list' />} />
      </Route>
    </Routes>
  )
}

export default SecrReportsPage
