import axios, { AxiosResponse } from 'axios'

export function listing(year: number, name: string) {
  return axios
    .get('/conversion_factors/listing', {
      params: {
        year: year,
        name: name,
      },
    })
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
    .catch(() => [])
}
