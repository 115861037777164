import axios, { AxiosResponse } from 'axios'
import {
  generalChartParamaters,
  groupSitesChartParamaters,
  summaryScopesChartParamaters,
} from './_models'

export function getSummaryScopes(params: summaryScopesChartParamaters) {
  const { company, reportYear } = params
  const paramsArr = [
    company && `company=${company.value}`,
    reportYear && `report_year=${reportYear.value}`,
  ]
  const url = `/secr_reports/getSummaryScopes?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getGeneralChartData(params: generalChartParamaters) {
  const { company, reportYear, scopeType, chartPeriod } = params
  const paramsArr = [
    company && `company=${company}`,
    reportYear && `report_year=${reportYear}`,
    scopeType && `scope_type=${scopeType}`,
    chartPeriod && `chart_period=${chartPeriod}`,
  ]
  const url = `/secr_reports/getGeneralChartData?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getGroupSitesChartData(params: groupSitesChartParamaters) {
  const { company, sites, reportYear, scopeType, chartPeriod } = params
  const companySites: string[] = []
  sites.map((element) => {
    companySites.push(element.value)
  })
  const sitesString = companySites.join(',')
  const siteIdEncoded = encodeURIComponent(sitesString)
  const paramsArr = [
    company && `company=${company}`,
    siteIdEncoded && `sites=${siteIdEncoded}`,
    reportYear && `report_year=${reportYear}`,
    scopeType && `scope_type=${scopeType}`,
    chartPeriod && `chart_period=${chartPeriod}`,
  ]
  const url = `/secr_reports/getGroupSitesChartData?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getSummaryScopesForPdf(params: summaryScopesChartParamaters) {
  const { company, reportYear } = params
  const paramsArr = [
    company && `company=${company.value}`,
    reportYear && `report_year=${reportYear.value}`,
  ]
  const url = `/secr_reports/getSummaryScopesForPdf?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}
