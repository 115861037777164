import { FC, SyntheticEvent, useState } from 'react'
import Table from '../../../utils/ResultViewer/Table'
import DataViewTable from './table/DataViewTable'
import { useIntl } from 'react-intl'

const DataViewList: FC = () => {
  const intl = useIntl()
  return (
    <>
      <DataViewTable
        title={intl.formatMessage({ id: 'DATA.VIEWLIST' })}
        dataUrl='/data_view/list'
        editCallback={() => {}}
        deleteCallback={() => {}}
      />
    </>
  )
}

export { DataViewList }
