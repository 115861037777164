import Table from '../../../../utils/ResultViewer/Table'

class AlarmsTable extends Table {
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key === 'edit_actions') {
      return <td key={colIndex}></td>
    } else if (key === 'site_acknowledge') {
      if (row[key] === 'Yes') {
        return (
          <td key={colIndex}>
            <button
              onClick={() => this.props.editCallback(row['edit_actions'], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              style={{ backgroundColor: 'white' }}
            >
              <span className='badge badge-success'>Yes</span>
            </button>
          </td>
        )
      } else {
        return (
          <td key={colIndex}>
            <button
              onClick={() => this.props.editCallback(row['edit_actions'], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              style={{ backgroundColor: 'white' }}
            >
              <span className='badge badge-danger'>
                No
                <span style={{ marginLeft: '5px' }} className='fa fa-edit'>
                  {' '}
                </span>
              </span>
            </button>
          </td>
        )
      }
    } else if (key === 'site_alarm_comment') {
      if (row['site_acknowledge'] === 'Yes') {
        return (
          <td key={colIndex}>
            <>{row[key]}</>
            <button
              onClick={() => this.props.editCallback(row['edit_actions'], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              style={{ backgroundColor: 'white' }}
            >
              <span className='fa fa-edit'></span>
            </button>
          </td>
        )
      } else {
        return (
          <td key={colIndex}>
            <button
              onClick={() => this.props.editCallback(row['edit_actions'], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              style={{ backgroundColor: 'white' }}
            >
              <span className='fa fa-commenting'></span>
            </button>
          </td>
        )
      }
    } else {
      return super.renderRow(row, key, colIndex)
    }
  }
}

export default AlarmsTable
