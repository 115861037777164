import { useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import AutocompleteSelect from '../Form/Widgets/AutocompleteSelect'
import Field from '../Form/Widgets/Field'
import Select from '../Form/Widgets/Select'
import { SearchWidget } from './ResultViewer'
import { DateRangeInput } from '../Form/Widgets/DateRangeInput'
import { useIntl } from 'react-intl'

const Filters = (props: {
  search: SearchWidget[]
  setFilterParams: Function
  applyFilter: Function
  clearFilters: Function
}) => {
  const intl = useIntl()
  const [filterValues, setFilterValues] = useState<any>({})
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        return false
      }}
      className='d-inline'
    >
      <Field
        title={''}
        placeholder={intl.formatMessage({ id: 'ECOMMERCE.COMMON.SEARCH' })}
        type='search'
        name={'q'}
        autoComplete='off'
        onChange={(event) => {
          props.setFilterParams('q', event.target.value)
          filterValues['q'] = event.target.value
          setFilterValues(filterValues)
        }}
      />
      <button
        type='button'
        className='btn btn-sm btn-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen031.svg'
          className='svg-icon-6 svg-icon-muted me-1'
        />
        {intl.formatMessage({ id: 'FILTERS' })}
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>
            {' '}
            {intl.formatMessage({ id: 'FILTERS.OPTIONS' })}
          </div>
        </div>

        <div className='separator border-gray-200'></div>
        {props.search.map((searchWidget) => {
          switch (searchWidget.type) {
            case 'select':
              if (
                searchWidget.attributes &&
                (searchWidget.attributes as any)['data-autocomplete-token']
              ) {
                return (
                  <div className='px-7' key={searchWidget.name}>
                    <AutocompleteSelect
                      title={searchWidget.label}
                      name={searchWidget.name}
                      autoCompleteToken={
                        (searchWidget.attributes as any)['data-autocomplete-token']
                      }
                      multiple={true}
                      defaultValue={filterValues[searchWidget.name] ?? 0}
                      onChange={(event: any) => {
                        props.setFilterParams(searchWidget.name, event.target.value)
                        filterValues[searchWidget.name] = event.target.value
                        setFilterValues(filterValues)
                      }}
                    />
                  </div>
                )
              } else {
                return (
                  <div className='px-7' key={searchWidget.name}>
                    <Select
                      title={searchWidget.label}
                      name={searchWidget.name}
                      options={
                        searchWidget.options
                          ? Object.keys(searchWidget.options).map((key) => {
                              return {
                                value: key,
                                label: (searchWidget.options as any)[key],
                              }
                            })
                          : []
                      }
                      defaultValue={filterValues[searchWidget.name] ?? searchWidget.value}
                      onChange={(event: any) => {
                        props.setFilterParams(searchWidget.name, event.target.value)
                        filterValues[searchWidget.name] = event.target.value
                        setFilterValues(filterValues)
                      }}
                      nullElement={searchWidget.nullElement}
                    />
                  </div>
                )
              }
            case 'daterange':
              return (
                <div className='px-7' key={searchWidget.name}>
                  <DateRangeInput
                    title={searchWidget.label}
                    name={searchWidget.name}
                    seperator='&'
                    onChange={(event: any) => {
                      props.setFilterParams(searchWidget.name, event.target.value)
                      filterValues[searchWidget.name] = event.target.value
                      setFilterValues(filterValues)
                    }}
                  />
                </div>
              )
            case 'text':
            default:
              return (
                <div className='px-7' key={searchWidget.name}>
                  <Field
                    title={searchWidget.label}
                    name={searchWidget.name}
                    autoComplete='off'
                    onChange={(event) => {
                      props.setFilterParams(searchWidget.name, event.target.value)
                      filterValues[searchWidget.name] = event.target.value
                      setFilterValues(filterValues)
                    }}
                  />
                </div>
              )
          }
        })}
        <div className='px-7 py-5'>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-light btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={() => {
                props.clearFilters()
                setFilterValues({})
              }}
            >
              Reset
            </button>

            <button
              type='submit'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => props.applyFilter()}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Filters
