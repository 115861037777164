import { ResultProps } from '../../../utils/ResultViewer/ResultViewer'
import Table from '../../../utils/ResultViewer/Table'
type SecrReportsProps = ResultProps & {
  viewCallback: Function
}
class SecrReportsTable extends Table {
  //@ts-ignore
  public state: SecrReportsProps
  constructor(props: SecrReportsProps) {
    super(props)
    this.state = {
      title: props.title,
      actionItems: props.actionItems,
      dataUrl: props.dataUrl,
      data: {
        headers: {},
        data: [],
        search: [],
        pagination: {
          limit: 100,
          page: 1,
          total_count: 0,
        },
      },
      page: 1,
      orderBy: '',
      orderDirection: '',
      editCallback: props.editCallback,
      deleteCallback: props.deleteCallback,
      filterParams: {},
      viewCallback: props.viewCallback,
    }
  }
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key === 'edit_actions') {
      return (
        <td key={colIndex}>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <button
              onClick={() => this.props.editCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <span className='fa fa-edit'></span>
            </button>
            <button
              onClick={() => this.props.deleteCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
            >
              <span className='fa fa-trash'></span>
            </button>
            <button
              onClick={() => this.state.viewCallback(row[key], this.fetchData)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <span className='fas fa-eye'></span>
            </button>
          </div>
        </td>
      )
    } else {
      return super.renderRow(row, key, colIndex)
    }
  }
}

export default SecrReportsTable
