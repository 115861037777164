import axios, { AxiosResponse } from 'axios'

export function getAlarmModalChartData(site: string, date: string) {
  let alarmSite: any = site
  let alarmDate: any = date

  const paramsArr = [alarmSite && `alarm_site=${alarmSite}`, alarmDate && `date=${alarmDate}`]
  const url = `/alarms/getChartData?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getChartDataForMeterAlarms(site: string, date: string) {
  let alarmSite: any = site
  let alarmDate: any = date

  const paramsArr = [alarmSite && `alarm_site=${alarmSite}`, alarmDate && `date=${alarmDate}`]
  const url = `/alarms/getChartDataForMeterAlarms?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getAlarmMetersData(site: string, date: string) {
  let alarmSite: any = site
  let alarmDate: any = date

  const paramsArr = [alarmSite && `alarm_site=${alarmSite}`, alarmDate && `date=${alarmDate}`]
  const url = `/alarms/getAlarmMetersData?${paramsArr.filter(Boolean).join('&')}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}
