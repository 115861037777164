import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { CalendarOptions } from '@fullcalendar/core'
import CellChart from './CellChart'
import { useEffect, useState } from 'react'
import { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem, SelectItem } from '../../charts/core/_models'
import { DataObject, SeriesDataItem, meterType } from '../core/_models'
import { getCellChartData } from '../core/_requests'
import { useIntl } from 'react-intl'
import FilterFormCalendar from './FilterFormCalendar'
import ChartViewModalForCalendar from './ChartViewModalForCalendar'
import CalendarAlarmViewModal from './CalendarAlarmViewModal'
const CalendarViewPage = () => {
  const intl = useIntl()
  let [companyFilter, setCompanyFilter] = useState<SingleValue<CompanyListItem>>(null)
  let [siteFilter, setSiteFilter] = useState<MultiValue<CompanyListItem>>([])
  let [metersFilter, setMetersFilter] = useState<MultiValue<SelectItem>>([])
  let [meterTypeFilter, setMeterTypeFilter] = useState<SingleValue<meterType>>(null)
  let [selectedMeters, setSelectedMeters] = useState<MultiValue<CompanyListItem>>([])
  let [data, setData] = useState<Record<string, DataObject> | null>(null)
  let [thisYearData, setThisYearData] = useState<number[]>([0])
  let [lastYearData, setLastYearData] = useState<number[]>([0])

  const init = new Date()
  init.setDate(1)
  const initialDateYear = init.getFullYear()
  const initialDateMonth = String(init.getMonth() + 1).padStart(2, '0')
  const initialDateDay = String(init.getDate()).padStart(2, '0')
  const initialFormattedDate = `${initialDateYear}-${initialDateMonth}-${initialDateDay}`
  let [initialDate, setInitialDate] = useState(initialFormattedDate)

  const end = new Date(initialDateYear, init.getMonth() + 1, 0)
  const endDateYear = end.getFullYear()
  const endDateMonth = String(end.getMonth() + 1).padStart(2, '0')
  const endDateDay = String(end.getDate()).padStart(2, '0')
  const endFormattedDate = `${endDateYear}-${endDateMonth}-${endDateDay}`
  let [endDate, setEndDate] = useState(endFormattedDate)

  const [isLoading, setIsLoading] = useState(false)
  let [selectedDate, setSelectedDate] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  let [seriesData, setSeriesData] = useState<SeriesDataItem[]>([
    { name: 'This Year', data: [] },
    { name: 'Last Year', data: [] },
  ])

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const [isAlarmModalOpen, setIsAlarmModalOpen] = useState(false)
  const [isAlarmLoading, setIsAlarmLoading] = useState(false)
  const openAlarmModal = () => {
    setIsAlarmModalOpen(true)
  }

  const closeAlarmModal = () => {
    setIsAlarmModalOpen(false)
  }

  const params = {
    isLoggedIn: true,
    companyId: companyFilter ? companyFilter.value : '',
    siteId: siteFilter,
    metersId: metersFilter,
    meterType: meterTypeFilter,
    startDate: initialDate,
    endDate: endDate,
  }
  const fetchCellChartData = async () => {
    try {
      setIsLoading(true)
      getCellChartData({ ...params }).then((response) => {
        setTimeout(async () => {
          const data = await getCellChartData(params)
          setData(data)
          setIsLoading(false)
        }, 2000)
      })
    } catch (error) {
      console.log('Error fetching cell chart data:', error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (companyFilter?.label && initialDate && endDate) {
      fetchCellChartData()
    }
  }, [companyFilter, siteFilter, metersFilter, initialDate, endDate])

  useEffect(() => {
    if (data && selectedDate && data[selectedDate]) {
      setThisYearData(data ? data[selectedDate]?.thisYear ?? [0] : [0])
      setLastYearData(data ? data[selectedDate]?.lastYear ?? [0] : [0])
      setSeriesData([
        {
          name: 'This Year',
          data: thisYearData,
        },
        {
          name: 'Last Year',
          data: lastYearData,
        },
      ])
    }
  }, [selectedDate])

  useEffect(() => {
    setMetersFilter([])
  }, [meterTypeFilter])

  const cellRender = (cellInfo: any) => {
    const date: any = cellInfo.dayNumberText
    let d = cellInfo.date
    const inputDate = new Date(d)

    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, '0')
    const day = String(inputDate.getDate()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`
    const handleViewButtonClick = () => {
      setSelectedDate(formattedDate)
      if (companyFilter && data && data[formattedDate]) {
        setThisYearData(data[formattedDate]?.thisYear ?? [0])
        setLastYearData(data[formattedDate]?.lastYear ?? [0])
        if (thisYearData.length === 48 && lastYearData.length === 48) {
          setSeriesData([
            {
              name: 'This Year',
              data: thisYearData,
            },
            {
              name: 'Last Year',
              data: lastYearData,
            },
          ])
          openModal()
        }
      }
    }

    const alarmViewButtonClick = () => {
      setSelectedDate(formattedDate)
      openAlarmModal()
    }
    let alarmBackgroundColor = ''

    if (data && data[formattedDate]?.alarm == '1') {
      alarmBackgroundColor = '#ededed'
    }
    return (
      <div style={{ backgroundColor: alarmBackgroundColor }}>
        {isLoading && companyFilter && !formattedDate ? (
          <div className='card-body' style={{ textAlign: 'center' }}>
            <span className='indicator-progress' style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'LOADING' })}...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </div>
        ) : companyFilter && data && data[formattedDate] ? (
          <div style={{ backgroundColor: alarmBackgroundColor }}>
            <div>{date}</div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                onClick={handleViewButtonClick}
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_2'
                style={{ backgroundColor: 'transparent', border: 'none' }}
              >
                <span
                  aria-hidden='true'
                  className='fa-solid fa-magnifying-glass-chart fs-4'
                  style={{ color: '#1644ce' }}
                ></span>
              </button>
              {data && data[formattedDate]?.alarm == '1' && (
                <button
                  type='button'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_alarm'
                  onClick={alarmViewButtonClick}
                  style={{ backgroundColor: 'transparent', border: 'none', textAlign: 'right' }}
                >
                  <span
                    aria-hidden='true'
                    className='fa fa-comments fs-4'
                    style={{ color: '#1644ce' }}
                  ></span>
                </button>
              )}
            </div>

            <CellChart
              date={formattedDate}
              thisYearData={data[formattedDate]?.thisYear ?? [0]}
              lastYearData={data[formattedDate]?.lastYear ?? [0]}
              alarm={data[formattedDate]?.alarm ?? '0'}
            />
          </div>
        ) : (
          <>
            <div>{date}</div>
            <div>N/A</div>
          </>
        )}
      </div>
    )
  }

  const handleDatesChange = (dates: any) => {
    const start = dates.startStr
    const startDate = new Date(start)

    const startDateYear = startDate.getFullYear()
    const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0')
    const startDateDay = String(startDate.getDate()).padStart(2, '0')
    const formattedStartDate = `${startDateYear}-${startDateMonth}-${startDateDay}`

    const end = dates.endStr
    const endDate = new Date(end)

    const endDateYear = endDate.getFullYear()
    const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0')
    const endDateDay = String(endDate.getDate()).padStart(2, '0')
    const formattedEndDate = `${endDateYear}-${endDateMonth}-${endDateDay}`
    setInitialDate(formattedStartDate)
    setEndDate(formattedEndDate)
  }

  const calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    dayCellContent: cellRender,
    initialDate: initialDate,
    datesSet: handleDatesChange,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek',
    },
  }

  return (
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-12'>
        <FilterFormCalendar
          companyFilter={companyFilter}
          setCompanyFilter={setCompanyFilter}
          siteFilter={siteFilter}
          setSiteFilter={setSiteFilter}
          metersFilter={metersFilter}
          setMetersFilter={setMetersFilter}
          meterTypeFilter={meterTypeFilter}
          setMeterTypeFilter={setMeterTypeFilter}
          selectedMeters={selectedMeters}
          setSelectedMeters={setSelectedMeters}
        />
      </div>
      <div className='card'>
        <div className='calendar-container' style={{ marginTop: '10px' }}>
          <FullCalendar {...calendarOptions} />
        </div>
      </div>

      <ChartViewModalForCalendar
        isOpen={isModalOpen}
        date={selectedDate}
        data={seriesData}
        onClose={closeModal}
      />

      <CalendarAlarmViewModal
        isOpen={isAlarmModalOpen}
        onClose={closeAlarmModal}
        date={selectedDate}
        company={companyFilter}
        companySites={siteFilter}
        meters={metersFilter}
      />
    </div>
  )
}

export default CalendarViewPage
