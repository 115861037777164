import { readingGetApi } from '../core/_requests'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import FilterButton from './FilterButton'
import LineChart from './LineChart'
import { MultiValue, SingleValue } from 'react-select'
import { ChartData, CompanyListItem, SelectItem } from '../core/_models'
import { DateRangeSelector } from '../../../utils/Form/Widgets/DateRangeInput'
import moment from 'moment'
import { Range } from 'react-date-range'
import { useIntl } from 'react-intl'

type Props = {
  meterType: string
  className?: string
  company: SingleValue<SelectItem>
  companySite: MultiValue<CompanyListItem>
  meters: MultiValue<CompanyListItem>
  setReadingTypeElectiric: Dispatch<SetStateAction<string>>
  setReadingTypeGas: Dispatch<SetStateAction<string>>
  setCustomStart: Dispatch<SetStateAction<string>>
  setCustomEnd: Dispatch<SetStateAction<string>>
}

const DashboardGroupChart: React.FC<Props> = ({
  meterType,
  className,
  company,
  companySite,
  meters,
  setReadingTypeElectiric,
  setReadingTypeGas,
  setCustomStart,
  setCustomEnd,
}) => {
  const intl = useIntl()
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        name: '',
        data: [],
      },
    ],
    meterType: '',
  })
  const [chartType, setChartType] = useState('yesterday')
  const [isLoading, setIsLoading] = useState(false)
  const [rangeStart, setRangeStart] = useState<moment.Moment>(moment())
  const [rangeEnd, setRangeEnd] = useState<moment.Moment>(moment())
  const handleButtonClick = (id: string) => {
    setChartType(id)
  }
  let [chartDataList, setChartDataList] = useState<ChartData>({
    labels: [],
    datasets: [],
    meterType: '',
  })
  meterType == 'gas' ? setReadingTypeGas(chartType) : setReadingTypeElectiric(chartType)

  const fetchChartData = async (newParams = {}) => {
    try {
      setIsLoading(true)
      const params = {
        isLoggedIn: true,
        companyId: Number(company?.value),
        siteId: companySite,
        meterType: meterType,
        //   meterId: meters,
        readingType: chartType,
        customStartDate: rangeStart.format('YYYY-MM-DD'),
        customEndDate: rangeEnd.format('YYYY-MM-DD'),
        ...newParams,
      }
      meters.map((meterId) => {
        readingGetApi({ ...params, meterId: [meterId] }).then((chartData) => {
          chartDataList.labels = chartData.labels
          chartData.datasets.map((a) => {
            const uniqueDatasetNames = new Set(
              chartDataList.datasets.map((dataset) => dataset.name)
            )
            if (!uniqueDatasetNames.has(meterId.label)) {
              chartDataList.datasets.push({ ...a, name: meterId.label })
            }
          })
          setTimeout(async () => {
            setChartData(chartDataList)
            setIsLoading(false)
          }, 250)
        })
      })
    } catch (error) {
      console.log('Error fetching chart data:', error)
      setIsLoading(false)
    }
  }

  chartDataList = { labels: [], datasets: [], meterType: '' }
  useEffect(() => {
    fetchChartData()
    setCustomStart(rangeStart.format('YYYY-MM-DD'))
    setCustomEnd(rangeEnd.format('YYYY-MM-DD'))
  }, [chartType, company, companySite, meters, rangeStart, rangeEnd])
  return (
    <div>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1 text-uppercase'>
              {company?.label} /{' '}
              {meterType === 'electricity'
                ? intl.formatMessage({ id: 'CHARTTITLE.ELECTRIC' })
                : intl.formatMessage({ id: 'CHARTTITLE.GAS' })}
            </span>
          </h3>

          <div className='card-toolbar' data-kt-buttons='true'>
            <FilterButton
              id='yesterday'
              value='Yesterday'
              isActive={chartType === 'yesterday'}
              onClick={handleButtonClick}
            />
            <FilterButton
              id='last_one_week'
              value='Last 7 Days'
              isActive={chartType === 'last_one_week'}
              onClick={handleButtonClick}
            />
            <FilterButton
              id='last_one_month'
              value='Last 30 Days'
              isActive={chartType === 'last_one_month'}
              onClick={handleButtonClick}
            />
            <FilterButton
              id='last_one_year'
              value='Last 365 Days'
              isActive={chartType === 'last_one_year'}
              onClick={handleButtonClick}
            />
            <FilterButton
              id='custom'
              value='Custom'
              isActive={chartType === 'custom'}
              onClick={handleButtonClick}
            />
          </div>
        </div>
        <div className='card-body'>
          {chartType == 'custom' &&
          companySite &&
          meters &&
          companySite.length > 0 &&
          meters.length > 0 ? (
            <div className='w-md-50'>
              <DateRangeSelector
                name='custom_range'
                title={intl.formatMessage({ id: 'DATERANGE' })}
                seperator='to'
                ranges={[
                  {
                    startDate: rangeStart.toDate(),
                    endDate: rangeStart.toDate(),
                  },
                ]}
                rangeSelected={(range: Range) => {
                  setRangeStart(moment(range.startDate))
                  setRangeEnd(moment(range.endDate))
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {isLoading && companySite && meters && companySite.length > 0 && meters.length > 0 ? (
            <div className='card-body' style={{ textAlign: 'center' }}>
              <span className='indicator-progress' style={{ display: 'block' }}>
                {intl.formatMessage({ id: 'LOADING' })}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>{' '}
            </div>
          ) : companySite && companySite.length > 0 ? (
            meters && meters.length > 0 ? (
              chartData &&
              chartData.datasets &&
              chartData.datasets.length > 0 &&
              chartData.labels ? (
                <LineChart
                  datasets={chartData.datasets}
                  labels={chartData.labels}
                  meterType={meterType}
                />
              ) : (
                <div className='card-body' style={{ textAlign: 'center' }}>
                  {intl.formatMessage({ id: 'NODATA' })}
                </div>
              )
            ) : (
              <div className='card-body' style={{ textAlign: 'center' }}>
                {intl.formatMessage({ id: 'DASHBOARDGROUP.SELECTAMETER' })}
              </div>
            )
          ) : (
            <div className='card-body' style={{ textAlign: 'center' }}>
              {intl.formatMessage({ id: 'DASHBOARDGROUP.SELECTACOMPANYSITE' })}
            </div>
          )}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}
export default DashboardGroupChart
