import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { SecrDocumentsList } from './components/SecrDocumentList'

const documentsBreadcrumbs: Array<PageLink> = [
  {
    title: 'SECR Documents',
    path: '/secr_documents/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const SecrDocumentsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=':id'
          element={
            <>
              <DocumentTitle
                title={process.env.REACT_APP_NAME + ' | SECR Documents'}
              ></DocumentTitle>
              <PageTitle breadcrumbs={documentsBreadcrumbs}>SECR Documents</PageTitle>
              <SecrDocumentsList />
            </>
          }
        />
        <Route index element={<Navigate to='/secr_documents/list' />} />
      </Route>
    </Routes>
  )
}

export default SecrDocumentsPage
