import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import '../rank.css'
import SiteChartModal from './SiteChartModal'

interface BenchmarkTableProps {
  parameter: any
  data?: {
    name: string
    feature_value: string
    electricity_consumption: string
    gas_consumption: string
    total_combined_consumption: string
    consumption_per_feature: string
    average_per_feature: string
  }[]
  company: string
  start_date: string
  end_date: string
}

const BenchmarkTable: React.FC<BenchmarkTableProps> = ({
  parameter,
  data,
  company,
  start_date,
  end_date,
}) => {
  const [sortField, setSortField] = useState<string>('name')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [rankedData, setRankedData] = useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedSite, setSelectedSite] = useState<any>('')
  const [selectedSiteName, setSelectedSiteName] = useState<any>('')

  const handleSort = (field: string) => {
    const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc'
    setSortField(field)
    setSortDirection(direction)
  }

  const rankData = (sortedData: any[]) => {
    const ranked = sortedData.map((item, index) => ({
      ...item,
      rank: index + 1,
    }))
    setRankedData(ranked)
  }

  useEffect(() => {
    if (data) {
      const sortedData = data
        ? [...data]
            .sort((a, b) => {
              const aValue = parseFloat(a.consumption_per_feature)
              const bValue = parseFloat(b.consumption_per_feature)
              return sortDirection === 'asc' ? aValue - bValue : bValue - aValue
            })
            .map((item, index) => ({ ...item, rank: index + 1 }))
        : []

      rankData(sortedData)
    }
  }, [data])

  const sortedData = rankedData
    ? [...rankedData].sort((a, b) => {
        const aValue = a[sortField as keyof typeof a]
        const bValue = b[sortField as keyof typeof b]

        if (typeof aValue === 'string') {
          return sortDirection === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue)
        } else {
          return sortDirection === 'asc'
            ? parseFloat(aValue) - parseFloat(bValue)
            : parseFloat(bValue) - parseFloat(aValue)
        }
      })
    : []

  const renderSortIcon = (field: string) => {
    if (sortField === field) {
      return sortDirection === 'asc' ? (
        <KTSVG
          path='/media/icons/duotune/arrows/arr066.svg'
          className='svg-icon svg-icon-1 svg-icon-primary ms-2'
        />
      ) : (
        <KTSVG
          path='/media/icons/duotune/arrows/arr065.svg'
          className='svg-icon svg-icon-1 svg-icon-primary ms-2'
        />
      )
    }
    return (
      <KTSVG
        path='/media/icons/duotune/arrows/arr065.svg'
        className='svg-icon svg-icon-1 svg-icon-gray-500 ms-2'
      />
    )
  }

  const openModal = (siteData: any) => {
    setSelectedSite(siteData.site_id ?? '')
    setSelectedSiteName(siteData.name ?? '')
  }

  // Effect to open the modal after `selectedSite` is set
  useEffect(() => {
    if (selectedSite) {
      setIsModalOpen(true)
    }
  }, [selectedSite])

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedSite(null)
  }

  return (
    <div className={`card mb-5 mb-xl-8`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {data && data.length > 0 ? (
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-50px' onClick={() => handleSort('rank')}>
                    Rank
                    {renderSortIcon('rank')}
                  </th>
                  <th className='min-w-150px' onClick={() => handleSort('name')}>
                    Site
                    {renderSortIcon('name')}
                  </th>
                  <th className='min-w-140px' onClick={() => handleSort('feature_value')}>
                    {parameter.name} {' ('} {parameter.unit} {')'}
                    {renderSortIcon('feature_value')}
                  </th>
                  <th className='min-w-120px' onClick={() => handleSort('electricity_consumption')}>
                    Total Electric Consumption (kWh)
                    {renderSortIcon('electricity_consumption')}
                  </th>
                  <th className='min-w-120px' onClick={() => handleSort('gas_consumption')}>
                    Total Gas Consumption (kWh)
                    {renderSortIcon('gas_consumption')}
                  </th>
                  <th
                    className='min-w-120px'
                    onClick={() => handleSort('total_combined_consumption')}
                  >
                    Total Combined Consumption (kWh)
                    {renderSortIcon('total_combined_consumption')}
                  </th>
                  <th className='min-w-120px'>
                    Average Consumption Per {parameter.unit} (kWh)
                    {renderSortIcon('average_per_feature')}
                  </th>
                  <th className='min-w-120px' onClick={() => handleSort('consumption_per_feature')}>
                    Consumption Per {parameter.unit} (kWh)
                    {renderSortIcon('consumption_per_feature')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((item, index) => (
                  <tr key={index} className={item.rank <= 3 ? `rank-${item.rank}` : ''}>
                    <td style={{ fontWeight: 'bold', textAlign: 'center' }}>{item.rank}</td>
                    <td>
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault()
                          openModal(item) // Open modal with selected site data
                        }}
                        className='text-dark fw-bold text-hover-primary fs-6'
                      >
                        {item.name}
                      </a>
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.feature_value).toFixed(2)}
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.electricity_consumption).toFixed(2)}
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.gas_consumption).toFixed(2)}
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.total_combined_consumption).toFixed(2)}
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.average_per_feature).toFixed(2)}
                    </td>
                    <td className='text-dark fw-bold fs-6'>
                      {parseFloat(item.consumption_per_feature).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className='text-muted'>No data available.</p>
          )}
        </div>
      </div>

      {/* SiteChartModal, rendered conditionally */}
      {isModalOpen && (
        <SiteChartModal
          benchmarkData={{
            siteName: selectedSiteName,
            companyValue: company,
            siteValue: selectedSite,
            start_date: start_date,
            end_date: end_date,
          }}
          show={true}
          onClose={closeModal}
        />
      )}
    </div>
  )
}

export default BenchmarkTable
