import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import NotificationsList from './NotificationsList'
import { useIntl } from 'react-intl'

const NotificationsPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <DocumentTitle
                title={
                  process.env.REACT_APP_NAME +
                  ' | ' +
                  intl.formatMessage({ id: 'MENU.NOTIFICATIONS' })
                }
              ></DocumentTitle>
              <PageTitle>{intl.formatMessage({ id: 'MENU.NOTIFICATIONS' })}</PageTitle>
              <NotificationsList />
            </>
          }
        />
        <Route index element={<Navigate to='/notifications/list' />} />
      </Route>
    </Routes>
  )
}

export default NotificationsPage
