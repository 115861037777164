import { Component } from 'react'
import axios from 'axios'

export type ActionItemProps = {
  text: string
  class: string
  icon: string
  onClick: Function
}

export type SearchWidget = {
  attributes?: {}
  description?: string
  label: string
  name: string
  type: string
  value: any
  options?: {}
  nullElement?: string
}

export type ResultProps = {
  title: string
  actionItems?: Array<ActionItemProps>
  dataUrl: string
  data?: {
    headers: object
    data: Array<object>
    search: SearchWidget[]
    pagination: {
      limit: number
      page: number
      total_count: number
    }
  }
  page?: number
  orderBy?: string
  orderDirection?: string
  editCallback: Function
  deleteCallback: Function
  filterParams?: {}
}

/**
 * Abstract Class ResultViewer.
 *
 * @class ResultViewer
 */
class ResultViewer extends Component<ResultProps, {}> {
  public state: ResultProps

  constructor(props: ResultProps) {
    super(props)
    if (this.constructor === ResultViewer) {
      throw new Error("Abstract classes can't be instantiated.")
    }
    this.state = {
      title: props.title,
      actionItems: props.actionItems,
      dataUrl: props.dataUrl,
      data: {
        headers: {},
        data: [],
        search: [],
        pagination: {
          limit: 100,
          page: 1,
          total_count: 0,
        },
      },
      page: 1,
      orderBy: '',
      orderDirection: '',
      editCallback: props.editCallback,
      deleteCallback: props.deleteCallback,
      filterParams: {},
    }
    this.fetchData = this.fetchData.bind(this)
    this.setPage = this.setPage.bind(this)
    this.setOrderBy = this.setOrderBy.bind(this)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    axios
      .get(this.props.dataUrl, {
        withCredentials: true,
        params: {
          page: this.state.page,
          orderBy: this.state.orderBy,
          orderDirection: this.state.orderDirection,
          ...this.state.filterParams,
        },
      })
      .then((response) => {
        let data = response.data.data
        data.search = Object.values(data.search)
        this.setState({
          data: data,
        })
      })
      .catch((error) => {})
  }

  setPage(page: number) {
    this.setState(
      {
        page: page,
      },
      () => {
        this.fetchData()
      }
    )
  }

  setOrderBy(orderBy: string, orderDirection: string) {
    this.setState(
      {
        orderBy: orderBy,
        orderDirection: orderDirection,
      },
      () => {
        this.fetchData()
      }
    )
  }

  setFilterParams(name: string, value: any) {
    let filterParams = this.state.filterParams as any
    filterParams[name] = value
    this.setState(
      {
        filterParams: filterParams,
      },
      () => {
        this.fetchData()
      }
    )
  }
  clearFilterParams() {
    this.setState(
      {
        filterParams: {},
      },
      () => {
        this.fetchData()
      }
    )
  }
}

export default ResultViewer
