import React, { useContext, useEffect, useState } from 'react'
import SummarySection from './summary/SummarySection'
import AnnualReportFilter from './AnnualReportFilter'
import ChartsGeneralSection from './general-section/ChartsGeneralSection'
import ChartsGroupSitesSection from './sites-section/ChartsGroupSitesSection'
import { SingleValue } from 'react-select'
import { CompanyListItem } from '../charts/core/_models'
import { summaryChartDataSeries } from './core/_models'
import { CustomizationContext } from '../customization/CustomizationContext'
import { companyOptionsList } from '../charts/core/_requests'

function AnnualReportsPage() {
  // @ts-ignore
  const { setSettingsAndUpdate, settings } = useContext(CustomizationContext)

  let [companyFilter, setCompanyFilter] = useState<SingleValue<CompanyListItem>>(null)
  let [yearFilter, setYearFilter] = useState<SingleValue<CompanyListItem>>(null)
  const [scope1, setScope1] = useState<summaryChartDataSeries>({ scope: 'Scope 1', total: 0 })
  const [scope2, setScope2] = useState<summaryChartDataSeries>({ scope: 'Scope 2', total: 0 })
  const [scope3, setScope3] = useState<summaryChartDataSeries>({ scope: 'Scope 3', total: 0 })
  let userSettingsCompany: CompanyListItem | null | undefined = null

  const currentDate = new Date()
  const currentYear = {
    value: currentDate.getFullYear().toString(),
    label: currentDate.getFullYear().toString(),
  }

  useEffect(() => {
    companyOptionsList().then((data) => {
      if (settings.companyS) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        userSettingsCompany = data.find((company) => company.value === settings.companyS)
      }
      if (userSettingsCompany) {
        setCompanyFilter({ label: userSettingsCompany.label, value: userSettingsCompany.value })
        setYearFilter(currentYear)
      }
    })
  }, [])

  return (
    <div>
      <section>
        <h1>SECR Report Dashboard</h1>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='col-12'>
            <AnnualReportFilter
              companyFilter={companyFilter}
              setCompanyFilter={setCompanyFilter}
              yearFilter={yearFilter}
              setYearFilter={setYearFilter}
              isDisabled={false}
            />
          </div>
        </div>
      </section>
      <section>
        <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Summary Scopes Based Data
        </h3>
        <SummarySection
          companyItem={companyFilter}
          yearItem={yearFilter}
          scope1={scope1}
          setScope1={setScope1}
          scope2={scope2}
          setScope2={setScope2}
          scope3={scope3}
          setScope3={setScope3}
          showToolbar={true}
        />
      </section>
      <section>
        <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          General Charts
        </h3>
        <ChartsGeneralSection companyItem={companyFilter} yearItem={yearFilter} />
      </section>
      <section>
        <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Company Sites, tCO2e and Scope Based Chart charts.
        </h3>
        <ChartsGroupSitesSection companyItem={companyFilter} yearItem={yearFilter} />
      </section>
    </div>
  )
}

export default AnnualReportsPage
