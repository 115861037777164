import React, { FC, SyntheticEvent, useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl'
import { DataRequestsEditModal } from '../DataRequestsEditModal'
import DataRequestTable from './table/DataRequestTable'
import SendDataRequestModal from './SendDataRequestModal'
import { DataRequestUploadFileModal } from './DataRequestUploadFileModal'

const DataRequestsList: FC = () => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const [itemIdForSend, setItemIdForSend] = useState('')
  const [itemIdForUpload, setItemIdForUpload] = useState('')
  const intl = useIntl()

  useEffect(() => {
    console.log(
      'Item Id For Update:',
      itemIdForUpdate,
      'Item Id For Send:',
      itemIdForSend,
      'Item Id For Upload:',
      itemIdForUpload
    )
  }, [itemIdForUpdate, itemIdForSend, itemIdForUpload])
  return (
    <>
      <DataRequestTable
        title={intl.formatMessage({ id: 'DATA.DATAREQUESTSLIST' })}
        dataUrl='/data_requests/list'
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForUpdate(id)
        }}
        deleteCallback={(id: any, refresh: Function) => {
          axios.get('/data_requests/save/' + id).then((response) => {
            let csrfData = response.data.data
            Swal.fire({
              title: 'Warning',
              html: 'Are you want to delete this data request entity?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                axios
                  .delete('data_requests/save/' + id, {
                    data: csrfData,
                  })
                  .then(() => {
                    refresh()
                  })
              }
            })
          })
        }}
        actionItems={[
          {
            text: intl.formatMessage({ id: 'DATA.DATAREQUESTS.ADDDATAREQUEST' }),
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-plus',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              setItemIdForUpdate('add')
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        //@ts-ignore
        sendCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForSend(id)
        }}
        //@ts-ignore
        uploadCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          setItemIdForUpload(id)
        }}
      />
      {itemIdForUpdate !== '' ? (
        <DataRequestsEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onHide={() => {
            setItemIdForUpdate('')
          }}
          refreshFunction={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
      {itemIdForSend !== '' ? (
        <>
          <SendDataRequestModal
            show={itemIdForSend !== ''}
            dataRequestId={itemIdForSend}
            onHide={() => {
              setItemIdForSend('')
            }}
            refreshFunction={(itemId: any) => {
              if (itemId !== undefined) {
                setItemIdForSend(itemId)
              }
              refreshFunction()
            }}
          />
        </>
      ) : (
        <></>
      )}
      {itemIdForUpload !== '' ? (
        <DataRequestUploadFileModal
          show={itemIdForUpload !== ''}
          itemIdForUpload={itemIdForUpload}
          onHide={() => {
            setItemIdForUpload('')
          }}
          refreshFunction={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpload(itemId)
            }
            setItemIdForUpload('')

            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export { DataRequestsList }
