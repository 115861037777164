import Swal from 'sweetalert2'

export type BlockDeleteButtonAttributes = {
  onDelete: () => void
}

export default function BlockDeleteButton(props: BlockDeleteButtonAttributes) {
  return (
    <button
      className='btn btn-light-danger btn-icon rounded-circle btn-sm position-absolute end-0 me-4 mt-2'
      onClick={async (e) => {
        e.preventDefault()
        let result = await Swal.fire({
          title: 'Are you sure want to delete this block?',
          text: "You won't be able to revert this after saving!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-warning',
          },
        })
        if (result.isConfirmed) {
          props.onDelete()
        }
      }}
      style={{
        zIndex: 100,
      }}
    >
      <span className='fa fa-trash'></span>
    </button>
  )
}
