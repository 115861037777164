import { FC, useEffect, useState } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem, SelectItem } from '../../charts/core/_models'
import ApexChart from 'react-apexcharts'
import { getAlarmDataForCalendarView } from '../core/_requests'
import { AlarmDataForCalendarModal } from '../core/_models'
import { AlarmCommentForm } from './AlarmCommentForm'
interface AlarmProps {
  onClose: () => void
  isOpen: boolean
  date: string
  company: SingleValue<CompanyListItem>
  companySites: MultiValue<CompanyListItem>
  meters: MultiValue<SelectItem>
}

const CalendarAlarmViewModal: FC<AlarmProps> = ({
  onClose,
  isOpen,
  date,
  company,
  companySites,
  meters,
}) => {
  const params = {
    isLoggedIn: true,
    companyId: company ? company.value : '',
    siteId: companySites,
    metersId: meters,
    meterType: 'Electricity',
    selectedDate: date,
  }

  let [alarmDataDetail, setAlarmDataDetail] = useState<AlarmDataForCalendarModal>([])
  const fetchAlarmDataForCalendar = async () => {
    try {
      getAlarmDataForCalendarView({
        ...params,
        isLoggedIn: false,
      }).then((response) => {
        setTimeout(async () => {
          let data = await getAlarmDataForCalendarView(params)
          setAlarmDataDetail(data)
        }, 2000)
      })
    } catch (error) {
      console.log('Error fetching cell chart data:', error)
    }
  }

  useEffect(() => {
    if (company && date) {
      fetchAlarmDataForCalendar()
    }
  }, [company, companySites, meters, date])

  const handleCloseModal = () => {
    onClose()
  }
  const chartData = {
    options: {
      chart: {
        zoom: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 2,
        strokeWidth: 0,
        fillOpacity: 1,
      },
      stroke: {
        curve: 'smooth' as const,
      },
      colors: ['#0070FF', '#FFA500'],
      xaxis: {
        categories: [
          '00:30',
          '01:00',
          '01:30',
          '02:00',
          '02:30',
          '03:00',
          '03:30',
          '04:00',
          '04:30',
          '05:00',
          '05:30',
          '06:00',
          '06:30',
          '07:00',
          '07:30',
          '08:00',
          '08:30',
          '09:00',
          '09:30',
          '10:00',
          '10:30',
          '11:00',
          '11:30',
          '12:00',
          '12:30',
          '13:00',
          '13:30',
          '14:00',
          '14:30',
          '15:00',
          '15:30',
          '16:00',
          '16:30',
          '17:00',
          '17:30',
          '18:00',
          '18:30',
          '19:00',
          '19:30',
          '20:00',
          '20:30',
          '21:00',
          '21:30',
          '22:00',
          '22:30',
          '23:00',
          '23:30',
          '00:00',
        ],
        tickAmount: 12,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      legend: {
        show: false,
      },
    },
  }

  type organizedDataType = {
    site_name: string
    site_alarm_id: number
    site_data: number[]
    meters: any[]
  }
  const organizedData: organizedDataType[] = []
  let i = 0
  for (let alarm in alarmDataDetail) {
    organizedData[i] = {
      site_name: alarm,
      site_alarm_id: alarmDataDetail[alarm]['site_alarm_id'],
      site_data: alarmDataDetail[alarm]['site_data'],
      meters: Object.keys(alarmDataDetail[alarm]['meters']).map((key) => ({
        meter: key,
        data: alarmDataDetail[alarm]['meters'][key],
      })),
    }
    i = i + 1
  }
  function formatDate(date: any) {
    const dateParts = date.split('-')
    const year = dateParts[0]
    const month = dateParts[1]
    const day = dateParts[2]

    const formattedDate = new Date(`${year}-${month}-${day}`)

    const formattedDateString = `${formattedDate.getDate()}-${
      formattedDate.getMonth() + 1
    }-${formattedDate.getFullYear()}`

    return formattedDateString
  }
  const formattedDate = formatDate(date)

  return (
    <Modal
      id='kt_modal_create_alarms'
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      size='xl'
      show={isOpen}
      backdrop={true}
    >
      <Modal.Header closeButton onClick={handleCloseModal}>
        <Modal.Title>Alarm Details for {formattedDate}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='accordion' id='outerAccordion'>
          {organizedData.map((data, dataIndex) => (
            <div className='accordion-item' key={`site-${dataIndex}`}>
              <h2 className='accordion-header' id={`site${dataIndex}Header`}>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target={`#site${dataIndex}Collapse`}
                  aria-expanded='false'
                  aria-controls={`site${dataIndex}Collapse`}
                >
                  {data.site_name}
                </button>
              </h2>
              <div
                id={`site${dataIndex}Collapse`}
                className='accordion-collapse collapse'
                aria-labelledby={`site${dataIndex}Header`}
                data-bs-parent='#outerAccordion'
              >
                <div className='accordion-body'>
                  <div className='accordion-body'>
                    <ApexChart
                      options={chartData.options}
                      series={data.site_data}
                      type='line'
                      height={300}
                    />
                  </div>

                  <div>
                    <AlarmCommentForm alarm_id={data.site_alarm_id} />
                  </div>
                  <div className='accordion' key={`meter-more}`}>
                    <h2 className='accordion-header' id={`meter-moreHeader`}>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#meter-moreCollapse`}
                        aria-expanded='false'
                        aria-controls={`meter-moreCollapse`}
                        style={{ backgroundColor: '#f9f9f9' }}
                      >
                        More Details Based on Meters
                      </button>
                    </h2>
                    <div
                      id={`meter-moreCollapse`}
                      className='accordion-collapse collapse'
                      aria-labelledby={`meter-moreHeader`}
                    >
                      <div className='accordion-body'>
                        {Array.isArray(data.meters) ? (
                          data.meters.map((item) => (
                            <div className='accordion' key={`meter-${item.meter}`}>
                              <h2 className='accordion-header' id={`meter${item.meter}Header`}>
                                <button
                                  className='accordion-button collapsed'
                                  type='button'
                                  data-bs-toggle='collapse'
                                  data-bs-target={`#meter${item.meter}Collapse`}
                                  aria-expanded='false'
                                  aria-controls={`meter${item.meter}Collapse`}
                                >
                                  Meter:{item.meter}
                                </button>
                              </h2>
                              <div
                                id={`meter${item.meter}Collapse`}
                                className='accordion-collapse collapse'
                                aria-labelledby={`meter${item.meter}Header`}
                              >
                                <div className='accordion-body'>
                                  <ApexChart
                                    options={chartData.options}
                                    series={item.data}
                                    type='line'
                                    height={300}
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='accordion-item'>
                            <h2 className='accordion-header'>
                              <button className='accordion-button' type='button' disabled>
                                Veri yok
                              </button>
                            </h2>
                            <div className='accordion-body'>Veri bulunamadı.</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-end'>
        <button className='btn btn-danger btn-sm'>Close</button>
      </Modal.Footer>
    </Modal>
  )
}

export default CalendarAlarmViewModal
