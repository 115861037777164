import React from 'react'
import { ChartGeneral } from './ChartGeneral'
import { SingleValue } from 'react-select'
import { SelectItem } from '../../charts/core/_models'
type chartGeneralProps = {
  companyItem: SingleValue<SelectItem>
  yearItem: SingleValue<SelectItem>
}
const ChartsGeneralSection: React.FC<chartGeneralProps> = ({ companyItem, yearItem }) => {
  return (
    <>
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-4'>
          <ChartGeneral
            className='card-xl-stretch mb-xl-8'
            chartColor='info'
            chartHeight='200px'
            company={companyItem?.value}
            year={yearItem?.value}
            scope='Scope 1'
            dataPeriod='yearly'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <ChartGeneral
            className='card-xl-stretch mb-xl-8'
            chartColor='warning'
            chartHeight='200px'
            company={companyItem?.value}
            year={yearItem?.value}
            scope='Scope 2'
            dataPeriod='yearly'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <ChartGeneral
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            company={companyItem?.value}
            year={yearItem?.value}
            scope='Scope 3'
            dataPeriod='yearly'
          />
        </div>
        {/* end::Col */}
      </div>
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-4'>
          <ChartGeneral
            className='card-xl-stretch mb-xl-8'
            chartColor='info'
            chartHeight='400px'
            company={companyItem?.value}
            year={yearItem?.value}
            scope='Scope 1'
            dataPeriod='monthly'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <ChartGeneral
            className='card-xl-stretch mb-xl-8'
            chartColor='warning'
            chartHeight='400px'
            company={companyItem?.value}
            year={yearItem?.value}
            scope='Scope 2'
            dataPeriod='monthly'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <ChartGeneral
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='400px'
            company={companyItem?.value}
            year={yearItem?.value}
            scope='Scope 3'
            dataPeriod='monthly'
          />
        </div>
        {/* end::Col */}
      </div>
    </>
  )
}

export default ChartsGeneralSection
