import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TemplateReportEditForm from './TemplateReportEditForm'

const TemplateReportEdit: FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  if (params.id === 'add') {
    navigate('/reports/template-reports/')
  }
  return (
    <TemplateReportEditForm
      itemIdForUpdate={params.id}
      refreshFunction={(itemId: any) => {
        navigate('/reports/template-reports/' + itemId)
      }}
    />
  )
}

export default TemplateReportEdit
