import React, { useEffect, useState } from 'react'
import { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem } from '../../charts/core/_models'
import moment from 'moment'
import BenchmarkFilter from './BenchmarkFilter'
import { BenchmarkSingleChart } from './BenchmarkSingleChart'
import { benchmarkChartData } from '../core/_requests'
import { BenchmarkChartData } from '../core/_models'

function BenchmarkCharts() {
  let [companyFilter, setCompanyFilter] = useState<SingleValue<CompanyListItem>>(null)
  let [siteFilter, setSiteFilter] = useState<MultiValue<CompanyListItem>>([])
  let [period, setPeriod] = useState<string>('this_year')
  let [startDate, setStartDate] = useState<moment.Moment>(moment())
  let [endDate, setEndDate] = useState<moment.Moment>(moment())
  let [isLoading, setIsLoading] = useState<boolean>(false)
  let [chartData, setChartData] = useState<BenchmarkChartData>()
  let [selectedFeature, setSelectedFeature] = useState<string>('')
  let [selectedFeatureName, setSelectedFeatureName] = useState<{
    name: string
    unit: string
  }>({
    name: '',
    unit: '',
  })

  const getDateRangeLabel = () => {
    if (period === 'custom') {
      return `${startDate.format('DD MMMM YYYY')} - ${endDate.format('DD MMMM YYYY')}`
    }
    if (period === 'this_year') {
      return `${moment().startOf('year').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    if (period === 'last_one_month') {
      return `${moment().subtract(1, 'month').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    if (period === 'last_one_week') {
      return `${moment().subtract(1, 'week').format('DD MMMM YYYY')} - ${moment().format(
        'DD MMMM YYYY'
      )}`
    }
    return ''
  }

  const fetchChartData = async () => {
    try {
      setIsLoading(true)
      const params = {
        isLoggedIn: true,
        companyId: Number(companyFilter?.value),
        siteId: siteFilter,
        period: period,
        customStartDate: startDate.format('YYYY-MM-DD'),
        customEndDate: endDate.format('YYYY-MM-DD'),
        siteFeature: selectedFeature,
      }
      const data = await benchmarkChartData(params)
      setChartData(data)
    } catch (error) {
      console.error('Error fetching chart data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (companyFilter && selectedFeature) {
      fetchChartData()
    }
  }, [companyFilter, siteFilter, selectedFeature])

  useEffect(() => {
    if (companyFilter && selectedFeature && period === 'custom' && startDate && endDate) {
      fetchChartData()
    } else if (companyFilter && period !== 'custom') {
      fetchChartData()
    }
  }, [startDate, endDate, period])
  useEffect(() => {
    setSiteFilter([])
  }, [companyFilter])
  return (
    <>
      <div className='row mb-4'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'>
                <i className='bi bi-calendar'></i> {getDateRangeLabel()}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <BenchmarkFilter
        companyFilter={companyFilter}
        setCompanyFilter={setCompanyFilter}
        siteFilter={siteFilter}
        setSiteFilter={setSiteFilter}
        period={period}
        setPeriod={setPeriod}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        setSelectedFeatureName={setSelectedFeatureName}
      />

      <div className='row gy-10 gx-xl-10 mt-2'>
        {chartData ? (
          <>
            <div className='col-xl-6'>
              <BenchmarkSingleChart
                title='Electric Consumption'
                description='Electric consumption in kWh'
                color='primary'
                data={
                  chartData && chartData.electricity_consumption
                    ? chartData.electricity_consumption.data
                    : []
                }
                labels={
                  chartData && chartData.electricity_consumption
                    ? chartData.electricity_consumption.labels
                    : []
                }
                className='card-xxl-stretch mb-5 mb-xl-10'
                target={
                  chartData && chartData.electricity_consumption.average_line
                    ? chartData.electricity_consumption.average_line.data
                    : 0
                }
                target_text={
                  chartData && chartData.electricity_consumption.average_line
                    ? chartData.electricity_consumption.average_line.name
                    : ''
                }
              />
            </div>

            <div className='col-xl-6'>
              <BenchmarkSingleChart
                title='Gas Consumption'
                description='Gas consumption in kWh'
                color='info'
                data={chartData && chartData.gas_consumption ? chartData.gas_consumption.data : []}
                labels={
                  chartData && chartData.gas_consumption ? chartData.gas_consumption.labels : []
                }
                className='card-xxl-stretch mb-5 mb-xl-10'
                target={
                  chartData && chartData.gas_consumption.average_line
                    ? chartData.gas_consumption.average_line.data
                    : 0
                }
                target_text={
                  chartData && chartData.gas_consumption.average_line
                    ? chartData.gas_consumption.average_line.name
                    : ''
                }
              />
            </div>
            <div className='col-xl-6'>
              <BenchmarkSingleChart
                title='Total Consumption'
                description='Total consumption in kWh'
                color='primary'
                data={
                  chartData && chartData.total_combined_consumption
                    ? chartData.total_combined_consumption.data
                    : []
                }
                labels={
                  chartData && chartData.total_combined_consumption
                    ? chartData.total_combined_consumption.labels
                    : []
                }
                className='card-xxl-stretch mb-5 mb-xl-10'
                target={
                  chartData && chartData.total_combined_consumption.average_line
                    ? chartData.total_combined_consumption.average_line.data
                    : 0
                }
                target_text={
                  chartData && chartData.total_combined_consumption.average_line
                    ? chartData.total_combined_consumption.average_line.name
                    : ''
                }
              />
            </div>
            <div className='col-xl-6'>
              <BenchmarkSingleChart
                title={`Consumption Per ${selectedFeatureName.name}`}
                description={`Consumption per ${selectedFeatureName.unit} in kWh`}
                color='info'
                data={
                  chartData && chartData.consumption_per_feature
                    ? chartData.consumption_per_feature.data
                    : []
                }
                labels={
                  chartData && chartData.consumption_per_feature
                    ? chartData.consumption_per_feature.labels
                    : []
                }
                className='card-xxl-stretch mb-5 mb-xl-10'
                target={
                  chartData && chartData.consumption_per_feature.average_line
                    ? chartData.consumption_per_feature.average_line.data
                    : 0
                }
                target_text={
                  chartData && chartData.consumption_per_feature.average_line
                    ? chartData.consumption_per_feature.average_line.name
                    : ''
                }
              />
            </div>
          </>
        ) : (
          <div className='row mt-5'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <h5 className='card-title'>
                    <i className='bi bi-error'></i> No data available for the selected filters
                  </h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default BenchmarkCharts
