import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { SummaryBox } from './SummaryBox'
import { SingleValue } from 'react-select'
import { SelectItem } from '../../charts/core/_models'
import { getSummaryScopes } from '../core/_requests'
import { summaryChartDataSeries } from '../core/_models'
import SummaryDataToolbar from './SummaryToolbar'

type summaryProps = {
  companyItem: SingleValue<SelectItem>
  yearItem: SingleValue<SelectItem>
  scope1: summaryChartDataSeries
  setScope1: Dispatch<summaryChartDataSeries>
  scope2: summaryChartDataSeries
  setScope2: Dispatch<summaryChartDataSeries>
  scope3: summaryChartDataSeries
  setScope3: Dispatch<summaryChartDataSeries>
  showToolbar: boolean
}

const SummarySection: React.FC<summaryProps> = ({
  companyItem,
  yearItem,
  scope1,
  setScope1,
  scope2,
  setScope2,
  scope3,
  setScope3,
  showToolbar,
}) => {
  const [activeUnit, setActiveUnit] = useState('tCO2e')
  const [summaryData, setSummaryData] = useState<any>({})

  const fetchSummaryScopesData = async () => {
    try {
      const params = {
        isLoggedIn: true,
        company: companyItem,
        reportYear: yearItem,
      }
      const summaryDataResponse = await getSummaryScopes(params)

      setSummaryData(summaryDataResponse)
    } catch (error) {
      console.error('Error fetching summary data:', error)
    }
  }
  useEffect(() => {
    if (companyItem && yearItem && activeUnit) {
      fetchSummaryScopesData()
    }
  }, [companyItem, yearItem])

  useEffect(() => {
    if (summaryData && Object.entries(summaryData).length > 0) {
      const newScope1: summaryChartDataSeries = {
        scope: Object.keys(summaryData)[0],
        total: summaryData[Object.keys(summaryData)[0]][activeUnit],
      }
      setScope1(newScope1)

      const newScope2: summaryChartDataSeries = {
        scope: Object.keys(summaryData)[1],
        total: summaryData[Object.keys(summaryData)[1]][activeUnit],
      }
      setScope2(newScope2)

      const newScope3: summaryChartDataSeries = {
        scope: Object.keys(summaryData)[2],
        total: summaryData[Object.keys(summaryData)[2]][activeUnit],
      }
      setScope3(newScope3)
    }
  }, [activeUnit, summaryData])

  const handleUnitChange = (unit: string) => {
    setActiveUnit(unit)
  }

  return (
    <>
      {showToolbar && (
        <SummaryDataToolbar activeUnit={activeUnit} onUnitChange={handleUnitChange} />
      )}
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-4'>
          <SummaryBox
            className='card-xl-stretch mb-xl-8'
            chartColor='info'
            chartHeight='200px'
            company={companyItem?.value}
            year={yearItem?.value}
            scope={scope1}
            unit={activeUnit}
            notes='The data shows consumption of Scope 1.'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <SummaryBox
            className='card-xl-stretch mb-xl-8'
            chartColor='warning'
            chartHeight='200px'
            company={companyItem?.value}
            year={yearItem?.value}
            scope={scope2}
            unit={activeUnit}
            notes='The data shows consumption of Scope 2.'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <SummaryBox
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            company={companyItem?.value}
            year={yearItem?.value}
            scope={scope3}
            unit={activeUnit}
            notes='The data shows consumption of Scope 3.'
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

export default SummarySection
