import React from 'react'
import LoraChart from './LoraChart'

function LoraDashboard() {
  return (
    <div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Dashboard</span>
          </h3>

          <div className='card-toolbar' data-kt-buttons='true'></div>
        </div>
        <div className='card-body'>
          <>
            <LoraChart />
          </>
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export default LoraDashboard
