import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { CustomizationProvider } from './modules/customization/CustomizationContext'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <CustomizationProvider>
          <LayoutProvider>
            <>
              <Outlet />
              <MasterInit />
            </>
          </LayoutProvider>
        </CustomizationProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
