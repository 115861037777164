import React from 'react'
import { useIntl } from 'react-intl'

interface ConversionFiltersProps {
  year: number | null
  onYearChange: (year: number | null) => void
  name: string | null
  onNameChange: (name: string | null) => void
  handleClick: (itemId: string) => void
}

const ConversionFilters: React.FC<ConversionFiltersProps> = ({
  year,
  onYearChange,
  name,
  onNameChange,
  handleClick,
}) => {
  const handleYearClick = (selectedYear: number) => {
    onYearChange(selectedYear)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onNameChange(event.target.value)
  }
  const intl = useIntl()

  return (
    <>
      <div>
        <button
          type='button'
          className='btn btn-sm btn-primary'
          onClick={() => handleClick('add')}
          style={{ marginRight: '10px', width: '160px' }}
        >
          <i className='fas fa-add fs-4 me-2'></i>
          {intl.formatMessage({ id: 'conversion.list.newData' })}
        </button>
        <button
          type='button'
          className='btn btn-sm btn-success'
          onClick={() => console.log('importData')}
          style={{ marginRight: '10px', width: '160px' }}
        >
          <i className='fas fa-file-excel fs-4 me-2'></i>
          {intl.formatMessage({ id: 'conversion.list.importData' })}
        </button>
        <button
          type='button'
          className={`btn btn-sm btn-light-primary ${year === 2021 ? 'active' : ''}`}
          onClick={() => handleYearClick(2021)}
        >
          2021
        </button>
        <span style={{ marginRight: '10px' }}></span>
        <button
          type='button'
          className={`btn btn-sm btn-light-primary ${year === 2022 ? 'active' : ''}`}
          onClick={() => handleYearClick(2022)}
        >
          2022
        </button>
        <span style={{ marginRight: '10px' }}></span>
        <button
          type='button'
          className={`btn btn-sm btn-light-primary ${year === 2023 ? 'active' : ''}`}
          onClick={() => handleYearClick(2023)}
        >
          2023
        </button>
        <span style={{ marginRight: '10px' }}></span>
        <button
          type='button'
          className={`btn btn-sm btn-light-primary ${year === 2024 ? 'active' : ''}`}
          onClick={() => handleYearClick(2024)}
        >
          2024
        </button>
        <span style={{ marginRight: '10px' }}></span>
        <input
          style={{ marginTop: '10px' }}
          className='form-control form-control-sm'
          type='text'
          name='name'
          onChange={handleInputChange}
          value={name || ''}
          autoComplete='off'
          placeholder={intl.formatMessage({ id: 'conversion.searchByName' })}
        />
      </div>
    </>
  )
}

export default ConversionFilters
