import React, { useEffect, useState } from 'react'
import MailCard from './MailCard'
import {
  checkAuthentication,
  getAuthenticateMailAddress,
  getAuthorizationUrl,
  getInboxMails,
  getSentMails,
} from './core/_requests'
import { Mail } from './core/_models'
import { DataRequestsEditModal } from '../../DataRequestsEditModal'
import { useNavigate } from 'react-router-dom'
import NewMailModal from './NewMailModal'

function DataCollectionsMailbox() {
  let [authenticationStatus, setAuthenticationStatus] = useState<boolean>(false)
  let [authenticationStatusClass, setAuthenticationStatusClass] = useState('danger')
  let [inboxMails, setInboxMails] = useState<Mail[]>([])
  let [sentMails, setSentMails] = useState<Mail[]>([])
  const [selectedFolder, setSelectedFolder] = useState<'inbox' | 'sent'>('inbox')
  let [newDataRequest, setNewDataRequest] = useState('')
  let [newMail, setNewMail] = useState('')

  let [authenticateMailAddress, setAuthenticateMailAddress] = useState('')
  let [authorizationUrl, setAuthorizationUrl] = useState('')

  const navigate = useNavigate()

  function handleClick() {
    navigate('/data_requests/list')
  }

  let text = ''
  const fetchAuthenticateMailAddres = async () => {
    const mail = await getAuthenticateMailAddress()
    setAuthenticateMailAddress(mail)
  }

  const fetchCheckAuthentication = async () => {
    let res = await checkAuthentication()

    if (res === true) {
      setAuthenticationStatus(true)
      setAuthenticationStatusClass('success')
    } else {
      setAuthenticationStatus(false)
      setAuthenticationStatusClass('danger')
    }
  }

  const fetchInboxMails = async () => {
    const mails = await getInboxMails()
    setInboxMails(mails)
  }
  const fetchSentMails = async () => {
    const mails = await getSentMails()
    setSentMails(mails)
  }

  const getAuthorization = async () => {
    let url = await getAuthorizationUrl()
    setAuthorizationUrl(url)
  }

  useEffect(() => {
    if (authenticationStatus !== true) {
      getAuthorization()
    } else {
      setAuthorizationUrl('')
    }
  }, [])

  useEffect(() => {
    fetchAuthenticateMailAddres()
    fetchCheckAuthentication()
    fetchInboxMails()
    fetchSentMails()
  }, [])
  useEffect(() => {
    if (selectedFolder === 'inbox') {
      fetchInboxMails()
    } else {
      fetchSentMails()
    }
  }, [selectedFolder])
  let countInboxMails = inboxMails ? inboxMails.length : 0
  let countSentMails = sentMails ? sentMails.length : 0
  if (authenticationStatus === true) {
    text = `authenticated.`
  } else {
    text = `does not authenticate.`
  }
  return (
    <>
      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
        <div
          className={`notice d-flex bg-light-${authenticationStatusClass} rounded border-${authenticationStatusClass} fw-light border border-dashed p-3 flex-grow-1`}
        >
          <div className='d-flex align-items-center'>
            <div style={{ fontWeight: 'bold', marginRight: '5px' }}>{authenticateMailAddress}</div>
            {text}
            {authenticationStatus !== true ? (
              <a
                style={{ marginLeft: '50px' }}
                className='btn btn-danger btn-sm'
                href={authorizationUrl}
              >
                Authenticate
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
          <div className='card card-flush'>
            <div className='card-body'>
              {/* New Message Button */}
              <div className='d-flex justify-content-center mb-3'>
                <button
                  onClick={(event) => {
                    setNewDataRequest('add')
                  }}
                  type='button'
                  className='btn btn-info btn-xl me-8'
                >
                  New Data Request
                </button>
                {newDataRequest !== '' ? (
                  <DataRequestsEditModal
                    show={newDataRequest !== ''}
                    itemIdForUpdate={newDataRequest}
                    onHide={() => {
                      setNewDataRequest('')
                    }}
                    refreshFunction={(itemId: any) => {
                      if (itemId !== undefined) {
                        setNewDataRequest(itemId)
                      }
                      handleClick()
                    }}
                  />
                ) : (
                  <></>
                )}
                <button
                  onClick={(event) => {
                    setNewMail('add')
                  }}
                  type='button'
                  className='btn btn-primary btn-xl'
                >
                  New Mail
                </button>
                {newMail !== '' ? (
                  <>
                    <NewMailModal
                      show={newMail !== ''}
                      dataRequestId={newMail}
                      onHide={() => {
                        setNewMail('')
                      }}
                      refreshFunction={(itemId: any) => {
                        if (itemId !== undefined) {
                          setNewMail(itemId)
                        }
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/* Inbox Menu */}
              <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10'>
                {/* Inbox Menu Item */}
                <div className='menu-item mb-3'>
                  <span
                    className={`menu-link ${selectedFolder === 'inbox' ? 'active' : ''}`}
                    onClick={() => setSelectedFolder('inbox')}
                  >
                    <span className='menu-icon'>
                      <i className='ki-duotone ki-sms fs-2 me-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                    <span className='menu-title fw-bold'>Inbox</span>
                    <span className='badge badge-light-primary'>{countInboxMails}</span>
                  </span>
                </div>
                {/* Sent Menu Item */}
                <div className='menu-item mb-3'>
                  <span
                    className={`menu-link ${selectedFolder === 'sent' ? 'active' : ''}`}
                    onClick={() => setSelectedFolder('sent')}
                  >
                    <span className='menu-icon'>
                      <i className='ki-duotone ki-send fs-2 me-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                    <span className='menu-title fw-bold'>Sent</span>
                    <span className='badge badge-light-primary'>{countSentMails}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
          <div className='container mt-5'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card card-flush'>
                    <div className='card-body'>
                      {selectedFolder === 'inbox' && inboxMails && inboxMails.length > 0 ? (
                        inboxMails.map((mail) => (
                          <MailCard
                            type='inbox'
                            id={mail.id}
                            mailId={mail.mailId}
                            key={mail.id}
                            bodyPreview={mail.bodyPreview}
                            body={mail.body}
                            from={mail.from}
                            toRecipients={mail.toRecipients}
                            subject={mail.subject}
                            receivedDateTime={mail.receivedDateTime}
                            sentDateTime={mail.sentDateTime}
                            mailAttachments={mail.mailAttachments}
                            sender={mail.sender}
                          />
                        ))
                      ) : selectedFolder === 'sent' && sentMails && sentMails.length > 0 ? (
                        sentMails.map((mail) => (
                          <MailCard
                            type='sent'
                            id={mail.id}
                            mailId={mail.mailId}
                            key={mail.id}
                            bodyPreview={mail.bodyPreview}
                            body={mail.body}
                            from={mail.from}
                            toRecipients={mail.toRecipients}
                            subject={mail.subject}
                            receivedDateTime={mail.receivedDateTime}
                            sentDateTime={mail.sentDateTime}
                            mailAttachments={mail.mailAttachments}
                            sender={mail.sender}
                          />
                        ))
                      ) : (
                        <>There isn't any mail.</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DataCollectionsMailbox
