import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import Check from '../../utils/Form/Widgets/Check'
import Textarea from '../../utils/Form/Widgets/Textarea'
import FormHandler from '../../utils/Form/FormHandler'
import ChartForAlarmCommentModal from './ChartForAlarmCommentModal'
import MetersForAlarmCommentModal from './MetersForAlarmCommentModal'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}

class Alarms {
  site?: string
  date?: string
  site_acknowledge?: boolean
  site_alarm_comment?: string
}

class AlarmsEditModal extends FormHandler {
  acknowledgeOptions = [
    {
      value: 'Yes',
      label: 'Yes',
    },
    {
      value: 'No',
      label: 'No',
    },
  ]

  constructor(props: Props) {
    super(props)
    this.state = { entity: new Alarms() }
  }
  getApiUrl(): string {
    return '/alarms/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/alarms/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      alarms_site: this.state.entity,
    }
  }
  render(): ReactNode {
    var state = this.state as any
    const { date, site } = state.entity
    function formatDate(date: any) {
      if (date) {
        return new Date(date).toLocaleDateString('tr-TR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
      }
    }

    const formattedDate = formatDate(date)
    let title = 'Alarm Chart - ' + formattedDate
    const chartComponent =
      date && site ? <ChartForAlarmCommentModal siteValue={site} dateValue={date} /> : null
    const metersComponent =
      date && site ? (
        <div className='accordion' id='metersAccordion'>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='metersAccordionHeader'>
              <button
                className='accordion-button fs-4 fw-bold'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#metersAccordionBody'
                aria-expanded='true'
                aria-controls='metersAccordionBody'
              >
                More Details Based on Meters
              </button>
            </h2>
            <div
              id='metersAccordionBody'
              className='accordion-collapse collapse show'
              aria-labelledby='metersAccordionHeader'
              data-bs-parent='#metersAccordion'
            >
              <div className='accordion-body'>
                <MetersForAlarmCommentModal siteValue={site} dateValue={date} />
              </div>
            </div>
          </div>
        </div>
      ) : null

    return (
      <Modal
        id='kt_modal_create_alarms'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='xl'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          {chartComponent}

          <Modal.Body>
            <FormGroup>
              <Check
                title='Acknowledge'
                name='site_acknowledge'
                type='switch'
                checked={state.entity.site_acknowledge ? true : false}
                options={['']}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
              />
            </FormGroup>
            <FormGroup>
              <Textarea
                title='Alarm Comment'
                className='form-control'
                type='string'
                name='site_alarm_comment'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity?.site_alarm_comment}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
          {metersComponent}
        </form>
      </Modal>
    )
  }
}
export { AlarmsEditModal }
