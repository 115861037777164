import React, { useEffect, useRef, useState } from 'react'
import ApexCharts from 'apexcharts'
import { useThemeMode } from '../../../../_metronic/partials'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { getGroupSitesChartData } from '../core/_requests'
import { CompanyListItem } from '../../charts/core/_models'
import { MultiValue } from 'react-select'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  company: string | any
  sites: MultiValue<CompanyListItem>
  year: string | any
  scope: string | any
  dataPeriod: string
}

const ChartGroup: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  company,
  sites,
  year,
  scope,
  dataPeriod,
}) => {
  const [labels, setLabels] = useState<string[]>([])
  const [kWhData, setKWhData] = useState<number[]>([])
  const [tCO2eData, setTCO2eData] = useState<number[]>([])
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()

  const fetchGroupSitesChartData = async () => {
    try {
      const params = {
        isLoggedIn: true,
        company,
        sites: sites,
        reportYear: year,
        scopeType: scope,
        chartPeriod: dataPeriod,
      }
      const groupSitesChartData = await getGroupSitesChartData(params)

      if (groupSitesChartData && Object.keys(groupSitesChartData).length > 0) {
        const { labels, kWh, tCO2e } = groupSitesChartData
        setLabels(labels)
        setKWhData(kWh)
        setTCO2eData(tCO2e)
      }
    } catch (error) {
      console.error('Error fetching summary data:', error)
    }
  }

  useEffect(() => {
    if (company && year && scope && dataPeriod) {
      setTimeout(() => {
        fetchGroupSitesChartData()
      }, 1000)
    }
  }, [company, sites, year, scope, dataPeriod])

  useEffect(() => {
    const chart = refreshChart(kWhData, tCO2eData, labels)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [kWhData, tCO2eData, labels, mode])

  const refreshChart = (kWhData: number[], tCO2eData: number[], labels: string[]) => {
    if (!chartRef.current) return

    const chartOptions = getChartOptions(chartColor, chartHeight, tCO2eData, labels)
    const chart = new ApexCharts(chartRef.current, chartOptions)
    chart.render()

    return chart
  }

  const getChartOptions = (
    chartColor: string,
    chartHeight: string,
    data: number[],
    labels: string[]
  ) => {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const secondaryColor = getCSSVariableValue('--bs-gray-300')
    const baseColor = getCSSVariableValue('--bs-' + chartColor)

    return {
      series: [
        {
          name: scope,
          data: data,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: labels,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        type: 'solid',
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val: number) {
            return val + ' tCO2e'
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        padding: {
          top: 10,
        },
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bold text-gray-800 d-block fs-3'>{scope}</span>
          </div>
          <div className={`fw-bold fs-3 text-${chartColor}`}>
            {dataPeriod && dataPeriod === 'yearly' ? 'Yearly' : 'Monthly'}
          </div>
        </div>
        {/* end::Hidden */}
        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

export { ChartGroup }
