import axios from 'axios'
import { ChangeEventHandler, useEffect, useState } from 'react'
import Swal from 'sweetalert2'

type DefaultFile = {
  referenceId: string
  fileId: string
}
type MultipleFileUploadProps = {
  fileUploadKey?: string
  fileUploadKeyUrl?: string
  name: string
  fieldName: string
  onChange: ChangeEventHandler
  accept?: string
  defaultFiles?: DefaultFile[]
}

function MultipleFileInputWidget(props: MultipleFileUploadProps) {
  const [fileUploadKey, setFileUploadKey] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([])

  useEffect(() => {
    if (props.defaultFiles && props.defaultFiles.length > 0 && props.name) {
      getFileById(props.defaultFiles, props.name)
    }
  }, [props.defaultFiles])

  async function getFileById(fileArray: DefaultFile[], referenceName: string) {
    for (let file of fileArray) {
      let url = '/files/getFileData/' + file.fileId

      if (referenceName && file.fileId && file.referenceId) {
        url =
          '/files/getFileData/' +
          file.fileId +
          '?entity_name=' +
          referenceName +
          '&reference_id=' +
          file.referenceId

        try {
          await axios.get(url).then((response) => {
            uploadedFiles.push(response.data.data)
            setUploadedFiles([...uploadedFiles])
          })

          //setUploadedFiles([...uploadedFiles])
        } catch (error) {
          console.error('Error fetching file data:', error)
        }
      }
    }
  }

  useEffect(() => {
    if (!props.fileUploadKey && props.fileUploadKeyUrl) {
      axios.get(props.fileUploadKeyUrl).then((response) => {
        setFileUploadKey(response.data.data)
      })
    }
  }, [props.fileUploadKeyUrl, props.fileUploadKey])

  async function uploadNewFiles(files: File[]) {
    for (let file of files) {
      var formData = new FormData()
      formData.append('file', file)
      formData.append('key', fileUploadKey)
      let uploadApi = '/files/uploadFile'
      await axios
        .post(uploadApi, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })
        .then((response) => {
          uploadedFiles.push(response.data.data)
          setUploadedFiles([...uploadedFiles])
        })
    }
  }
  const { name, onChange } = props
  useEffect(() => {
    let values = []
    for (let item of uploadedFiles) {
      let row: any = {}
      row[props.fieldName] = item.ID
      values.push(row)
    }
    let event: any = {
      target: {
        name: name,
        value: values,
      },
    }
    onChange(event)
  }, [name, uploadedFiles])
  return (
    <div>
      <label className='form-label fw-bolder fs-6'>Files</label>
      <div className='table-responsive'>
        {uploadedFiles.length > 0 ? (
          <table className='table table-striped table-light table-hover position-relative multiple-files-content'>
            <thead>
              <tr>
                <th className='ps-2 text-nowrap'>Preview</th>
                <th className='ps-2'>File name</th>
                <th className='ps-2 text-nowrap'>File size</th>
                <th className='ps-2'></th>
              </tr>
            </thead>
            <tbody>
              {uploadedFiles.map((uploadedFile, index) => {
                return (
                  <tr key={uploadedFile.ID}>
                    <td>
                      <div className='text-center' style={{ width: '50px' }}>
                        {uploadedFile.is_image ? (
                          // eslint-disable-next-line
                          <a href='#'>
                            <img
                              src={
                                process.env.REACT_APP_API_BASE_URL +
                                'files/uploaded/' +
                                uploadedFile.file_path
                              }
                              className='img-thumbnail ms-2'
                              alt={uploadedFile.file_name}
                            />
                          </a>
                        ) : (
                          <span
                            className={uploadedFile.icon_class + ' fs-2 ms-2 text-primary'}
                          ></span>
                        )}
                      </div>
                    </td>
                    <td>{uploadedFile.file_name}</td>
                    <td>{uploadedFile.file_size}</td>
                    <td>
                      <button
                        className='btn btn-icon btn-danger btn-sm'
                        onClick={(event) => {
                          event.preventDefault()
                          Swal.fire({
                            title: 'Warning',
                            html: 'Are you want to delete this file?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Delete',
                            cancelButtonText: 'Cancel',
                            customClass: {
                              confirmButton: 'btn btn-danger',
                              cancelButton: 'btn btn-primary',
                            },
                          }).then((result) => {
                            if (result.isConfirmed) {
                              axios
                                .post('/files/delete', {
                                  key: uploadedFile.remove_key,
                                })
                                .then(() => {
                                  uploadedFiles.splice(index, 1)
                                  setUploadedFiles([...uploadedFiles])
                                })
                            }
                          })
                        }}
                      >
                        <span className='fa fa-times'></span>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
      <label className='btn bg-primary text-light'>
        <span className='fa fa-file-upload me-2'></span>
        Add New File
        <input
          className='d-none'
          type='file'
          multiple
          accept={props.accept}
          onChange={(event: any) => {
            uploadNewFiles(event.target.files)
          }}
        />
      </label>
    </div>
  )
}
export default MultipleFileInputWidget
