import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useIntl } from 'react-intl'
import { getChartData } from './core/_requests'

import TimePicker from '../../utils/Form/Widgets/TimePicker'
import DatePicker from '../../utils/Form/Widgets/DatePicker'
import { Button, Modal } from 'react-bootstrap'
interface DataItem {
  time: string
  value: number
}
const LoraChart = () => {
  let [data, setData] = useState<DataItem[]>([])
  const [chartType, setChartType] = useState<string>('line')
  const availableChartTypes = [
    { value: 'line', label: 'Line' },
    { value: 'area', label: 'Area' },
    { value: 'radar', label: 'Radar' },
  ]
  const [date, setDate] = useState<Date | null>(null)
  const [initialTime, setInitialTime] = useState<Date | null>(null)
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => setShowModal(false)
  const handleDateChange = (date: Date | null) => {
    setDate(date)
  }
  const handleInitialTime = (initTime: Date | null) => {
    setInitialTime(initTime)
  }
  const handleEndTime = (endTime: Date | null) => {
    setEndTime(endTime)
  }

  const fetchLoraChartData = async () => {
    try {
      setTimeout(async () => {
        if (date && initialTime && endTime) {
          data = await getChartData(date, initialTime, endTime)
        } else {
          data = await getChartData()
        }

        setData(data)
      }, 2000)
    } catch (error) {
      console.log('Error fetching chart data:', error)
    }
  }
  useEffect(() => {
    fetchLoraChartData()
  }, [])

  let times: any = []
  let values: any = []
  if (data) {
    times = data.map((item) => item.time)
    values = data.map((item) => item.value)
  }
  const intl = useIntl()
  const chartData = {
    series: [
      {
        name: 'Lora Data',
        data: values,
      },
    ],
    options: {
      chart: {
        zoom: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 1,
      },
      stroke: {
        curve: 'smooth' as const,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
      xaxis: {
        categories: times,
        tickAmount: 6,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      height: 350,
    },
  }
  useEffect(() => {}, [chartType])
  const renderChart = () => {
    let type = availableChartTypes.find((type) => type.value === chartType)?.value
    return (
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        //@ts-ignore
        type={type}
        height={chartData.options.height}
      />
    )
  }

  return (
    <div className='w-100'>
      <div className='d-flex align-items-center'>
        <div className='position-relative'>
          <span className='fw-bold fs-6 text-gray-400'>Chart Type</span>
          <select
            title=''
            className='form-select form-select-xl w-125px me-2'
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
          >
            <option value=''>{intl.formatMessage({ id: 'LINECHART.SELECTCHARTTYPE' })}</option>
            {availableChartTypes.map((type) => (
              <option key={type.value} value={type.value}>
                {intl.formatMessage({ id: type.value })}
              </option>
            ))}
          </select>
        </div>
        <span style={{ margin: '5px' }}></span>
        <DatePicker onChange={handleDateChange} title={'Select Date'} />

        <span style={{ margin: '10px' }}></span>
        {date ? (
          <>
            <TimePicker onChange={handleInitialTime} title='Initial Time' />
            <span style={{ margin: '5px' }}></span>
            {initialTime ? (
              <>
                <TimePicker onChange={handleEndTime} title='End Time' />
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {date && initialTime && endTime ? (
          <>
            <button
              onClick={() => {
                if (initialTime && endTime && initialTime > endTime) {
                  setShowModal(true)
                } else {
                  fetchLoraChartData()
                }
              }}
              className='btn btn-light-primary'
              style={{ marginLeft: '10px', marginTop: '20px' }}
            >
              Filter
            </button>
            {showModal === true ? (
              <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>End Time should be greater than Initial Time.</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='danger' onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      {renderChart()}
    </div>
  )
}

export default LoraChart
