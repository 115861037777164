import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../utils/Form/FormHandler'
import Field from '../../utils/Form/Widgets/Field'
import AutocompleteSelect from '../../utils/Form/Widgets/AutocompleteSelect'
import { DateRangeInput } from '../../utils/Form/Widgets/DateRangeInput'
import MultipleFileInputWidget from '../../utils/Form/Widgets/MultipleFileInputWidget'
import Check from '../../utils/Form/Widgets/Check'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}

class DataRequests {
  company?: number
  data_period?: string
  energy_type?: string
  data_request_status?: string
  data_request_description?: string
  all_data_uploaded?: boolean
}

class DataRequestsEditModal extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new DataRequests() }
  }

  getApiUrl(): string {
    return '/data_requests/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/data_requests/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      data_requests: this.state.entity,
    }
  }
  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Data Request'
    if (this.state.entity && this.props.itemIdForUpdate !== 'add') {
      title = 'Edit Data Request'
    }
    let defaultCompanyValue = state.entity.company || 0
    if (this.props.itemIdForUpdate === 'add') {
      defaultCompanyValue = 0
    } else {
      defaultCompanyValue = state.entity.company
    }
    return (
      <Modal
        id='kt_modal_create_company'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
            })
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <AutocompleteSelect
                title='Company'
                name='company'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultCompanyValue}
                autoCompleteTokenUrl='/company/autocomplete'
              />
            </FormGroup>

            <AutocompleteSelect
              title='Energy Type'
              name='energy_type'
              onChange={(event: any) => {
                this.handleInputChange(event)
              }}
              defaultValue={this.props.itemIdForUpdate !== 'add' ? state.entity.energy_type : 0}
              autoCompleteTokenUrl='/energy_types/autocomplete'
            />

            <FormGroup>
              <DateRangeInput
                title='Data Period'
                name='data_period'
                seperator={'to'}
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                ranges={[
                  {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection',
                  },
                ]}
              />
            </FormGroup>
            {this.props.itemIdForUpdate !== 'add' ? (
              <FormGroup>
                <FormGroup>
                  <Field
                    title='Data Request Description'
                    className='form-control'
                    type='text'
                    name='data_request_description'
                    onInput={(event: any) => {
                      this.handleInputChange(event)
                    }}
                    defaultValue={state.entity.data_request_description}
                  />
                </FormGroup>
                <FormGroup>
                  <MultipleFileInputWidget
                    fileUploadKeyUrl='/data_requests/getDataRequestFileUploadKey'
                    name='data_request_files'
                    fieldName='data_file'
                    onChange={(event) => {
                      this.handleInputChange(event)
                    }}
                    accept='*'
                    defaultFiles={state.entity.data_request_files} //data_request_files (reference id) data_files (file id)
                  />
                </FormGroup>
                <FormGroup>
                  <Check
                    title='All Data Uploaded'
                    name='all_data_uploaded'
                    type='switch'
                    checked={state.entity.all_data_uploaded ? true : false}
                    options={['']}
                    onChange={(event: any) => {
                      this.handleInputChange(event)
                    }}
                  />
                </FormGroup>
              </FormGroup>
            ) : (
              <></>
            )}
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { DataRequestsEditModal }
