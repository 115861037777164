import React from 'react'
import Table from '../../../utils/ResultViewer/Table'

class DocumentsTable extends Table {
  renderRow(row: any, key: string, colIndex: number) {
    if (key === 'file_path') {
      return <React.Fragment key={colIndex}></React.Fragment>
    }
    if (key === 'file_name') {
      return (
        <td key={colIndex}>
          <a href={row['file_path']} target='_blank' rel='noreferrer'>
            {row['file_name']}
          </a>
        </td>
      )
    }
    return super.renderRow(row, key, colIndex)
  }
}

export default DocumentsTable
