import axios, { AxiosResponse } from 'axios'

export function getInboxMails() {
  const url = `/microsoft_integration/getMailBox?folder=inbox`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getSentMails() {
  const url = `/microsoft_integration/getMailBox?folder=sentItems`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function checkAuthentication() {
  const url = `/microsoft_integration/checkAuthentication`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getAuthenticateMailAddress() {
  const url = `/microsoft_integration/getAuthenticateMailAddress`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export function getAuthorizationUrl() {
  const url = `/microsoft_integration/getAuthorizationUrlForClient`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}
