import { useEffect, useState } from 'react'
import axios from 'axios'

export default function PreviewSecrReportPdf({
  company,
  reportYear,
  executiveSummary,
  methodology,
  environmental,
  targetSetting,
  verifications,
  appendices,
  previewMode = 'html',
}: {
  company?: string
  reportYear?: string
  executiveSummary?: string
  methodology?: string
  environmental?: string
  targetSetting?: string
  verifications?: string
  appendices?: string
  previewMode?: string
}) {
  const [data, setData] = useState('')
  const [shown, setShown] = useState(false)
  const [previewMethod, setPreviewMethod] = useState(previewMode)

  useEffect(() => {
    if (shown) {
      let serviceUrl = 'preview'
      if (previewMethod === 'pdf') {
        serviceUrl += 'Pdf'
      }
      axios
        .post('/secr_reports/' + serviceUrl, {
          company: company,
          report_year: reportYear,
          executive_summary: executiveSummary,
          methodology: methodology,
          environmental: environmental,
          target_setting: targetSetting,
          verifications: verifications,
          appendices: appendices,
        })
        .then((response) => {
          if (previewMethod === 'pdf') {
            let blob = base64ToBlob(response.data, 'application/pdf')
            setData(URL.createObjectURL(blob))
          } else {
            setData(response.data)
          }
        })
    }
  }, [shown, company, previewMethod])

  useEffect(() => {
    setData('')
  }, [previewMethod])
  return (
    <>
      <div
        className='position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2'
        data-bs-toggle='tooltip'
        data-bs-placement='left'
        data-bs-dismiss='click'
        data-bs-trigger='hover'
        data-bs-original-title='Preview'
      >
        <button
          className='btn btn-primary p-2 px-5'
          type='button'
          id='previewOffcanvasButton'
          data-bs-toggle='offcanvas'
          data-bs-target='#previewOffcanvas'
          aria-controls='previewOffcanvas'
          onClick={() => {
            setShown(true)
          }}
        >
          <span className='fa fa-eye me-2'></span>
          <span>Preview</span>
        </button>
      </div>
      <div
        className='offcanvas offcanvas-end w-100'
        tabIndex={-1}
        id='previewOffcanvas'
        aria-labelledby='previewOffcanvas'
      >
        <div className='offcanvas-header border border-2'>
          <h5 className='offcanvas-title'>Preview</h5>
          <button
            type='button'
            className='btn-close text-reset'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
            onClick={() => {
              setShown(false)
            }}
          ></button>
        </div>
        <div className='offcanvas-body'>
          <ul className='nav nav-pills my-3'>
            <li className='nav-item'>
              <button
                type='button'
                className={'nav-link me-2 ' + (previewMethod === 'html' ? 'active bg-primary' : '')}
                onClick={() => {
                  setPreviewMethod('html')
                }}
              >
                <span className='fa fa-eye me-2'></span>
                <span>Quick Preview</span>
              </button>
            </li>
            <li className='nav-item'>
              <button
                type='button'
                className={
                  'nav-link me-2 ' + (previewMethod === 'pdf' ? 'active bg-danger' : 'text-danger')
                }
                onClick={() => {
                  setPreviewMethod('pdf')
                }}
              >
                <span className='fa fa-file-pdf me-2'></span>
                <span>Preview as PDF</span>
              </button>
            </li>
          </ul>
          {data ? (
            previewMethod === 'pdf' ? (
              <iframe
                title='Preview template'
                className='w-100 h-100 border border-2'
                src={data}
              ></iframe>
            ) : (
              <iframe
                title='Preview template'
                className='w-100 h-100 border border-2'
                srcDoc={data}
              ></iframe>
            )
          ) : (
            <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
              <div
                className='spinner-border text-primary'
                style={{
                  width: '15rem',
                  height: '15rem',
                  borderWidth: '1rem',
                  animationDuration: '1s',
                }}
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

function base64ToBlob(b64Data: any, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}
