import React, { useEffect, useRef, useState } from 'react'

import { SingleValue } from 'react-select'

import { useNavigate, useParams } from 'react-router-dom'
import { CompanyListItem } from '../../charts/core/_models'
import { summaryChartDataSeries } from '../../annual-reports/core/_models'
import AnnualReportFilter from '../../annual-reports/AnnualReportFilter'
import SummarySection from '../../annual-reports/summary/SummarySection'
import ChartsGeneralSection from '../../annual-reports/general-section/ChartsGeneralSection'
import { getSecrReportDataById, saveSecrReportByKey } from '../core/_requests'
import { secrReportType } from '../core/_models'
import { companyOptionsList } from '../../charts/core/_requests'
import HtmlEditor from '../../../utils/Form/Widgets/HtmlEditor'
import axios from 'axios'
import PreviewSecrReportPdf from './PreviewSecrReportPdf'
import { SummarySectionForPdf } from './SummarySectionForPdf'

function SecrReportsEditPage() {
  const navigate = useNavigate()
  const { secrReportId } = useParams()
  const [secrReportData, setSecrReportData] = useState<secrReportType>()
  let [companyFilter, setCompanyFilter] = useState<SingleValue<CompanyListItem>>(null)
  let [yearFilter, setYearFilter] = useState<SingleValue<CompanyListItem>>(null)

  const [executiveSummary, setExecutiveSummary] = useState('')
  const [methodologySection, setMethodologySection] = useState('')
  const [environmentalStrategySection, setEnvironmentalSection] = useState('')
  const [targetSetting, setTargetSetting] = useState('')
  const [verificationSection, setVerificationSection] = useState('')
  const [appendicesSection, setAppendicesSection] = useState('')

  const [selectedCompany, setSelectedCompany] = useState<SingleValue<CompanyListItem>>(null)
  const [companyOptions, setCompanyOptions] = useState<CompanyListItem[]>([])

  const [selectedYear, setSelectedYear] = useState<SingleValue<CompanyListItem>>(null)

  const yearOptions = [
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
  ]
  useEffect(() => {
    companyOptionsList().then(setCompanyOptions)
  }, [])
  const fetchSecrReportData = async () => {
    try {
      if (secrReportId) {
        const responseData = await getSecrReportDataById(secrReportId)

        if (companyOptions && companyOptions.length > 0) {
          const defaultCompany = companyOptions.filter(
            (item) => item.value === responseData?.company
          )
          const defaultYear = yearOptions.filter((item) => item.value === responseData?.report_year)
          setSelectedCompany(defaultCompany[0])
          setSelectedYear(defaultYear[0])
        } else {
          console.log('Waiting for companyOptions to load...')
        }

        setSecrReportData(responseData)
      } else {
        console.log('ERROR-SECR REPORT ID', secrReportId)
      }
    } catch (error) {
      console.error('Error fetching summary data:', error)
    }
  }

  useEffect(() => {
    if (companyOptions && companyOptions.length > 0 && secrReportId) {
      fetchSecrReportData()
    }
  }, [companyOptions, secrReportId])

  const handleSaveExecutiveSummary = () => {
    if (secrReportId) {
      saveSecrReportByKey(secrReportId, { executive_summary_section: executiveSummary })
        .then((response) => {
          console.log('executive_summary_section:', response)
        })
        .catch((error) => {
          console.log('error:', error)
        })
    }
  }
  const handleSaveMethodologySection = () => {
    if (secrReportId) {
      saveSecrReportByKey(secrReportId, { methodology_section: methodologySection })
        .then((response) => {
          console.log('methodology_section:', response)
        })
        .catch((error) => {
          console.log('error:', error)
        })
    }
  }

  const handleSaveEnvironmentalStrategySection = () => {
    if (secrReportId) {
      saveSecrReportByKey(secrReportId, {
        environmental_strategy_section: environmentalStrategySection,
      })
        .then((response) => {
          console.log('environmental_strategy_section:', response)
        })
        .catch((error) => {
          console.log('error:', error)
        })
    }
  }

  const handleSaveTargetSetting = () => {
    if (secrReportId) {
      saveSecrReportByKey(secrReportId, { target_setting_section: targetSetting })
        .then((response) => {
          console.log('target_setting_section:', response)
        })
        .catch((error) => {
          console.log('error:', error)
        })
    }
  }

  const handleSaveVerificationSection = () => {
    if (secrReportId) {
      saveSecrReportByKey(secrReportId, { verification_section: verificationSection })
        .then((response) => {
          console.log('verification_section:', response)
        })
        .catch((error) => {
          console.log('error:', error)
        })
    }
  }

  const handleSaveAppendicesSection = () => {
    if (secrReportId) {
      saveSecrReportByKey(secrReportId, { appendices_section: appendicesSection })
        .then((response) => {
          console.log('appendices_section:', response)
        })
        .catch((error) => {
          console.log('error:', error)
        })
    }
  }

  const handleGenerateReport = async () => {
    try {
      const response = await axios.get('/secr_reports/generateReport/' + secrReportId)
    } catch (error) {
      console.error('Error generating report:', error)
    }
  }

  return (
    <div>
      <section>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='col-12'>
            <AnnualReportFilter
              companyFilter={selectedCompany}
              setCompanyFilter={setCompanyFilter}
              yearFilter={selectedYear}
              setYearFilter={setYearFilter}
              isDisabled={true}
            />
          </div>
        </div>
      </section>
      {selectedCompany && selectedYear ? (
        <>
          <section style={{ marginBottom: '20px' }}>
            <div className='accordion' id='summaryExecutive'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='summaryExecutiveHeader'>
                  <button
                    className='accordion-button fs-4 fw-bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#summaryExecutiveBody'
                    aria-expanded='false'
                    aria-controls='summaryExecutiveBody'
                  >
                    1. Executive Summary Section
                  </button>
                </h2>
                <div
                  id='summaryExecutiveBody'
                  className='accordion-collapse collapse show'
                  aria-labelledby='summaryExecutiveHeader'
                  data-bs-parent='#summaryExecutive'
                >
                  <div className='accordion-body'>
                    <HtmlEditor
                      type='paragraph'
                      onChange={(e) => {
                        setExecutiveSummary(e)
                      }}
                      initialValue={secrReportData?.executive_summary_section}
                    />
                    <button className='btn btn-primary mt-3' onClick={handleSaveExecutiveSummary}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section style={{ marginBottom: '20px' }}>
            <div className='accordion' id='summaryDataSection'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='summaryDataSectionHeader'>
                  <button
                    className='accordion-button fs-4 fw-bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#summaryDataSectionBody'
                    aria-expanded='false'
                    aria-controls='summaryDataSectionBody'
                  >
                    {' '}
                    <i className='fas fa-chart-pie' style={{ marginRight: '5px' }}></i>
                    Summary Data Based On Scopes
                  </button>
                </h2>
                <div
                  id='summaryDataSectionBody'
                  className='accordion-collapse collapse show'
                  aria-labelledby='summaryDataSectionHeader'
                  data-bs-parent='#summaryDataSection'
                >
                  <div className='accordion-body'>
                    <SummarySectionForPdf
                      company={selectedCompany}
                      year={selectedYear}
                      className='card-xl-stretch mb-xl-8'
                      chartColor='warning'
                      chartHeight='200px'
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section style={{ marginBottom: '20px' }}>
            <div className='accordion' id='methodologySection'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='methodologySectionHeader'>
                  <button
                    className='accordion-button fs-4 fw-bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#methodologySectionBody'
                    aria-expanded='false'
                    aria-controls='methodologySectionBody'
                  >
                    2. Methodology Section
                  </button>
                </h2>
                <div
                  id='methodologySectionBody'
                  className='accordion-collapse collapse show'
                  aria-labelledby='methodologySectionHeader'
                  data-bs-parent='#methodologySection'
                >
                  <div className='accordion-body'>
                    <HtmlEditor
                      type='paragraph'
                      onChange={(e) => setMethodologySection(e)}
                      initialValue={secrReportData?.methodology_section}
                    />
                    <button className='btn btn-primary mt-3' onClick={handleSaveMethodologySection}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section style={{ marginBottom: '20px' }}>
            <div className='accordion' id='generalChartsSection'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='generalChartsSectionHeader'>
                  <button
                    className='accordion-button fs-4 fw-bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#generalChartsSectionBody'
                    aria-expanded='false'
                    aria-controls='generalChartsSectionBody'
                  >
                    {' '}
                    <i className='fas fa-chart-bar' style={{ marginRight: '5px' }}></i>
                    General Charts
                  </button>
                </h2>
                <div
                  id='generalChartsSectionBody'
                  className='accordion-collapse collapse show'
                  aria-labelledby='generalChartsSectionHeader'
                  data-bs-parent='#generalChartsSection'
                >
                  <div className='accordion-body'>
                    <ChartsGeneralSection companyItem={selectedCompany} yearItem={selectedYear} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section style={{ marginBottom: '20px' }}>
            <div className='accordion' id='environmentalStrategy'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='environmentalStrategyHeader'>
                  <button
                    className='accordion-button fs-4 fw-bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#environmentalStrategyBody'
                    aria-expanded='false'
                    aria-controls='environmentalStrategyBody'
                  >
                    3. Environmental Strategy Section
                  </button>
                </h2>
                <div
                  id='environmentalStrategyBody'
                  className='accordion-collapse collapse show'
                  aria-labelledby='environmentalStrategyHeader'
                  data-bs-parent='#environmentalStrategy'
                >
                  <div className='accordion-body'>
                    <HtmlEditor
                      type='paragraph'
                      initialValue={secrReportData?.environmental_strategy_section}
                      onChange={(e) => setEnvironmentalSection(e)}
                    />
                    <button
                      className='btn btn-primary mt-3'
                      onClick={handleSaveEnvironmentalStrategySection}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section style={{ marginBottom: '20px' }}>
            <div className='accordion' id='targetSetting'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='targetSettingHeader'>
                  <button
                    className='accordion-button fs-4 fw-bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#targetSettingBody'
                    aria-expanded='false'
                    aria-controls='targetSettingBody'
                  >
                    4. Target Setting Section
                  </button>
                </h2>
                <div
                  id='targetSettingBody'
                  className='accordion-collapse collapse show'
                  aria-labelledby='targetSettingHeader'
                  data-bs-parent='#targetSetting'
                >
                  <div className='accordion-body'>
                    <HtmlEditor
                      type='paragraph'
                      initialValue={secrReportData?.target_setting_section}
                      onChange={(e) => setTargetSetting(e)}
                    />
                    <button className='btn btn-primary mt-3' onClick={handleSaveTargetSetting}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section style={{ marginBottom: '20px' }}>
            <div className='accordion' id='verificationSection'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='verificationSectionHeader'>
                  <button
                    className='accordion-button fs-4 fw-bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#verificationSectionBody'
                    aria-expanded='false'
                    aria-controls='verificationSectionBody'
                  >
                    5. Verification Section
                  </button>
                </h2>
                <div
                  id='verificationSectionBody'
                  className='accordion-collapse collapse show'
                  aria-labelledby='verificationSectionHeader'
                  data-bs-parent='#verificationSection'
                >
                  <div className='accordion-body'>
                    <HtmlEditor
                      type='paragraph'
                      initialValue={secrReportData?.verification_section}
                      onChange={(e) => setVerificationSection(e)}
                    />

                    <button
                      className='btn btn-primary mt-3'
                      onClick={handleSaveVerificationSection}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section style={{ marginBottom: '20px' }}>
            <div className='accordion' id='appendicesSection'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='appendicesSectionHeader'>
                  <button
                    className='accordion-button fs-4 fw-bold'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#appendicesSectionBody'
                    aria-expanded='false'
                    aria-controls='appendicesSectionBody'
                  >
                    6. Appendices Section
                  </button>
                </h2>
                <div
                  id='appendicesSectionBody'
                  className='accordion-collapse collapse show'
                  aria-labelledby='appendicesSectionHeader'
                  data-bs-parent='#appendicesSection'
                >
                  <div className='accordion-body' style={{ minHeight: '350px' }}>
                    <HtmlEditor
                      type='paragraph'
                      onChange={(e) => setAppendicesSection(e)}
                      initialValue={secrReportData?.appendices_section}
                    />
                    <button className='btn btn-primary mt-3' onClick={handleSaveAppendicesSection}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div style={{ textAlign: 'right' }}>
            <div className='d-flex justify-content-left gap-3'>
              <button className='btn btn-secondary' onClick={() => navigate('/secr_reports/list')}>
                <i className='fas fa-arrow-left' style={{ marginRight: '5px' }}></i>
                Back To Secr Reports
              </button>
              <button
                className='btn btn-danger'
                onClick={(event) => {
                  handleGenerateReport()
                }}
              >
                <i className='fa fa-file-pdf' style={{ marginRight: '5px' }}></i>
                Save SECR Report as PDF to System
              </button>
              <button
                type='button'
                className='btn btn-primary btn-sm'
                onClick={() => {
                  document.getElementById('previewOffcanvasButton')?.click()
                }}
              >
                <span className='fa fa-eye me-2'></span>
                <span className=''>Preview</span>
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <PreviewSecrReportPdf
        company={selectedCompany?.value}
        reportYear={selectedYear?.value}
        executiveSummary={
          executiveSummary !== '' ? executiveSummary : secrReportData?.executive_summary_section
        }
        methodology={
          methodologySection !== '' ? methodologySection : secrReportData?.methodology_section
        }
        environmental={
          environmentalStrategySection !== ''
            ? environmentalStrategySection
            : secrReportData?.environmental_strategy_section
        }
        targetSetting={
          targetSetting !== '' ? targetSetting : secrReportData?.target_setting_section
        }
        verifications={
          verificationSection !== '' ? verificationSection : secrReportData?.verification_section
        }
        appendices={
          appendicesSection !== '' ? appendicesSection : secrReportData?.appendices_section
        }
        previewMode='html'
      />
    </div>
  )
}

export default SecrReportsEditPage
