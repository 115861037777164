import Table from '../../../../utils/ResultViewer/Table'

class CompanySitesTable extends Table {
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key === 'logo') {
      let imageUrl = row[key] ? row[key] : '/public/media/avatars/blank.png'
      return (
        <td key={colIndex}>
          <div className='symbol symbol-45px me-5'>
            <img src={imageUrl} alt={row['name']} />
          </div>
        </td>
      )
    } else if (key === 'is_active' || key === 'status') {
      let badgeClass = 'badge-success'
      switch (row[key]) {
        case 'Active':
          badgeClass = 'badge-success'
          break
        case 'Passive':
          badgeClass = 'badge-warning'
          break
        case 'Disabled':
          badgeClass = 'badge-warning'
          break
      }
      return (
        <td key={colIndex}>
          <div className={'badge ' + badgeClass}>{row[key]}</div>
        </td>
      )
    } else {
      return super.renderRow(row, key, colIndex)
    }
  }
}

export default CompanySitesTable
