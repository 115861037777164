import { ReactNode } from 'react'
import LayoutBlock from './Block/LayoutBlock'
import TextBlock from './Block/TextBlock'
import DataBlock from './Block/DataBlock'

export type BlockAttributes = {
  type: string
  attributes: object | any
}

export type BlockVariation = {
  name: string
  icon: string
  attribute: object | any
}

export default interface BlockInterface {
  getType(): string
  setAttributes(attributes: any): void
  getAttributes(): any
  getName(): string
  getIconClass(): string
  getModalClasses(): string
  getVariationOptions(): BlockVariation[]
  renderTemplateView(
    onChange: () => void,
    openModal: (callback: (item: BlockAttributes) => void) => void
  ): ReactNode
  renderTemplateForm(): ReactNode
  clone(): BlockInterface
}

export function getAvailableBlocks(filterOnly: string[] = []): BlockInterface[] {
  let blockList: BlockInterface[] = [new LayoutBlock(), new TextBlock(), new DataBlock()]
  let blocks: any = {}
  for (let block of blockList) {
    blocks[block.getType()] = block
  }
  if (filterOnly.length > 0) {
    let filteredBlocks: any = {}
    for (let key of filterOnly) {
      if (blocks[key]) {
        filteredBlocks[key] = blocks[key]
      }
    }
    return filteredBlocks
  }
  return blocks
}
