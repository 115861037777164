import { useIntl } from 'react-intl'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MenuItem } from './MenuItem'
import { useEffect, useState } from 'react'
import { meApi } from '../../../../../app/modules/auth/core/_requests'
import { User } from '../../../../../app/modules/auth/core/_models'

export function MenuInner() {
  const intl = useIntl()
  const [user, setUser] = useState<User>()
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem('@auth/token') ?? undefined
  })

  useEffect(() => {
    const fetchUser = async () => {
      const user = await meApi(token) // meApi'yi çağırarak kullanıcı bilgilerini alıyoruz

      if (!user) {
        setToken(undefined)
        return
      }
      setUser(user.data)
    }
    fetchUser()
  }, [])
  let hasValidRole = false
  user?.roles.map((role) => {
    if (role == '1' || role == '2') {
      hasValidRole = true
    } else {
      hasValidRole = false
    }
  })

  if (!hasValidRole) {
    return (
      <>
        <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      </>
    )
  }
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      <MenuItem to='users/list' title={intl.formatMessage({ id: 'MENU.USERS' })} />
      <MenuInnerWithSub
        title={intl.formatMessage({ id: 'MENU.COMPANY' })}
        to='company/list'
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem to='company/list' title={intl.formatMessage({ id: 'MENU.COMPANYLIST' })} />
        <MenuItem to='company/sites' title={intl.formatMessage({ id: 'MENU.COMPANYSITES' })} />
        <MenuItem to='company/meters' title={intl.formatMessage({ id: 'MENU.COMPANYMETERS' })} />
      </MenuInnerWithSub>
      <MenuInnerWithSub
        title={intl.formatMessage({ id: 'MENU.DATA' })}
        to='data_files/list'
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem title={intl.formatMessage({ id: 'MENU.DATAFILES' })} to='data_files/list' />
        <MenuItem title={intl.formatMessage({ id: 'MENU.DATAVIEW' })} to='data_view/list' />
      </MenuInnerWithSub>
    </>
  )
}
