import React, { FC, useEffect, useState } from 'react'
import ApexChart from 'react-apexcharts'
import { SeriesDataItem } from '../core/_models'

interface LargeChartProps {
  onClose: () => void
  isOpen: boolean
  date: string
  data: SeriesDataItem[]
}

const ChartViewModalForCalendar: FC<LargeChartProps> = ({ onClose, isOpen, date, data }) => {
  const parts = date.split('-')

  const [year, month, day] = parts

  date = `${day}.${month}.${year}`
  const [seriesData, setSeriesData] = useState<SeriesDataItem[]>(data)
  useEffect(() => {
    if (data && data.length > 0) {
      ApexCharts.exec('large-calendar-chart', 'updateOptions', {
        chart: {
          redrawOnParentResize: true,
        },
      })
      setSeriesData(data)
    }
  }, [data])
  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{date}</h5>
            <button
              type='button'
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={onClose}
            >
              <span aria-hidden='true'>X</span>
            </button>
          </div>
          <div className='modal-body'>
            <div className='chart'>
              <ApexChart
                options={{
                  chart: {
                    type: 'line',
                    height: 350,
                    width: '100%',
                    toolbar: {
                      show: true,
                    },
                  },
                  stroke: {
                    width: 2,
                    curve: 'smooth',
                  },
                  grid: {
                    show: true,
                  },
                  xaxis: {
                    categories: [
                      '00:30',
                      '01:00',
                      '01:30',
                      '02:00',
                      '02:30',
                      '03:00',
                      '03:30',
                      '04:00',
                      '04:30',
                      '05:00',
                      '05:30',
                      '06:00',
                      '06:30',
                      '07:00',
                      '07:30',
                      '08:00',
                      '08:30',
                      '09:00',
                      '09:30',
                      '10:00',
                      '10:30',
                      '11:00',
                      '11:30',
                      '12:00',
                      '12:30',
                      '13:00',
                      '13:30',
                      '14:00',
                      '14:30',
                      '15:00',
                      '15:30',
                      '16:00',
                      '16:30',
                      '17:00',
                      '17:30',
                      '18:00',
                      '18:30',
                      '19:00',
                      '19:30',
                      '20:00',
                      '20:30',
                      '21:00',
                      '21:30',
                      '22:00',
                      '22:30',
                      '23:00',
                      '23:30',
                      '00:00',
                    ],
                    labels: {
                      show: true,
                      rotate: -45,
                      style: {
                        fontSize: '12px',
                        fontWeight: 400,
                      },
                    },
                    tickAmount: 24,
                  },
                  yaxis: {
                    labels: {
                      show: true,
                    },
                  },
                  markers: {
                    size: 2,
                    strokeWidth: 0,
                    fillOpacity: 1,
                  },
                  legend: {
                    show: true,
                  },
                }}
                series={seriesData}
                type='line'
                height={350}
                id='large-calendar-chart'
              />
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-light'
              data-bs-dismiss='modal'
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChartViewModalForCalendar
