import Clipboard from 'react-clipboard.js'
import Swal from 'sweetalert2'

type PlaceholderGroup = {
  name: string
  items: PlaceholderItem[]
}

type PlaceholderItem = {
  name: string
  placeholder: string
}

export default function TextBlockPlaceholders() {
  const placeholders: PlaceholderGroup[] = [
    {
      name: 'Company',
      items: [
        {
          name: 'Name',
          placeholder: 'company.name',
        },
      ],
    },
  ]

  return (
    <div id='placeholders-accordion'>
      {placeholders.map((group: PlaceholderGroup, index) => {
        let id = 'placeholder-accordion-index-' + index
        return (
          <div className='mb-5' key={index}>
            <div className='accordion-header py-3 d-flex'>
              <button
                className='accordion-button fs-4'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target={'#' + id}
              >
                <span className='fa fa-plus me-3'></span>
                {group.name}
              </button>
            </div>
            <div id={id} className='fs-6 collapse' data-bs-parent='#placeholders-accordion'>
              {group.items.map((item, index) => {
                return (
                  <Clipboard
                    key={index}
                    type='button'
                    data-placeholder={item.placeholder}
                    className='btn btn-outline-dark w-100 py-2 text-start'
                    data-clipboard-text={`{{${item.placeholder}}}`}
                    onClick={() => {
                      Swal.fire({
                        title: 'Placeholder copied',
                        html: 'Now paste on document where you want to place',
                        icon: 'success',
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        customClass: {
                          popup: 'bg-light-info',
                        },
                      })
                    }}
                  >
                    {item.name}
                    <span className='fa fa-copy float-end fs-3 text-primary'></span>
                  </Clipboard>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
