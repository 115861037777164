import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import Select, { MultiValue, SingleValue } from 'react-select'
import { useIntl } from 'react-intl'
import { CompanyListItem, SelectItem } from '../../charts/core/_models'
import { meterType, meterTypeOptions } from '../core/_models'
import { companyMeterList, companyOptionsList, companySitesList } from '../../charts/core/_requests'
import { CustomizationContext } from '../../customization/CustomizationContext'

type Props = {
  companyFilter: SingleValue<SelectItem>
  setCompanyFilter: Dispatch<SetStateAction<SingleValue<SelectItem>>>
  siteFilter: MultiValue<SelectItem>
  setSiteFilter: Dispatch<SetStateAction<MultiValue<SelectItem>>>
  metersFilter: MultiValue<SelectItem>
  setMetersFilter: Dispatch<SetStateAction<MultiValue<SelectItem>>>
  selectedMeters: MultiValue<SelectItem>
  setSelectedMeters: Dispatch<SetStateAction<MultiValue<SelectItem>>>
  meterTypeFilter: SingleValue<meterType>
  setMeterTypeFilter: Dispatch<SetStateAction<SingleValue<meterType>>>
}
const FilterFormCalendar: React.FC<Props> = ({
  companyFilter,
  setCompanyFilter,
  siteFilter,
  setSiteFilter,
  metersFilter,
  setMetersFilter,
  meterTypeFilter,
  setMeterTypeFilter,
  selectedMeters,
  setSelectedMeters,
}) => {
  const [selectedCompany, setSelectedCompany] = useState<SingleValue<CompanyListItem>>(null)
  const [selectedSite, setSelectedSite] = useState<MultiValue<CompanyListItem>>([])
  const [companyOptions, setCompanyOptions] = useState<CompanyListItem[]>([])
  const [companySiteOptions, setCompanySiteOptions] = useState<CompanyListItem[]>([])
  const [meterOptions, setMeterOptions] = useState<CompanyListItem[]>([])
  const [selectedMeterType, setSelectedMeterType] = useState<SingleValue<meterType>>(null)
  const intl = useIntl()

  // @ts-ignore
  const { setSettingsAndUpdate, settings } = useContext(CustomizationContext)

  let userSettingsCompany: CompanyListItem | null | undefined = null
  useEffect(() => {
    if (settings) {
      companyOptionsList().then((options) => {
        setCompanyOptions(options)

        if (settings.companyS) {
          userSettingsCompany = options.find((company) => company.value == settings.companyS)
        }

        if (userSettingsCompany) {
          setCompanyFilter({ label: userSettingsCompany.label, value: userSettingsCompany.value })
        }
      })
      if (settings.companySitesS) {
        setCompanyFilter(null)
        setSiteFilter([])
      }
    }
  }, [])

  useEffect(() => {
    companyOptionsList().then(setCompanyOptions)
    setMeterTypeFilter({
      label: 'Electric',
      value: 'Electricity',
    })
  }, [])

  useEffect(() => {
    setSelectedCompany(companyFilter)
  }, [companyFilter])

  useEffect(() => {
    if (selectedCompany) {
      companySitesList({ companyId: Number(selectedCompany.value) }).then(setCompanySiteOptions)
    }
  }, [selectedCompany])

  useEffect(() => {
    setSelectedSite(siteFilter)
    if (meterTypeFilter?.label && siteFilter) {
      companyMeterList({ siteId: siteFilter, meterType: meterTypeFilter.value }).then(
        setMeterOptions
      )
    }
  }, [siteFilter])
  useEffect(() => {
    setSelectedMeterType(meterTypeFilter)
  }, [meterTypeFilter])
  /*
  useEffect(() => {
    if (selectedSite) {
      companyMeterList({ siteId: selectedSite, meterType: selectedMeterType }).then(setMeterOptions)
    }
  }, [selectedSite])
*/
  useEffect(() => {
    if (selectedSite && selectedMeterType) {
      companyMeterList({ siteId: selectedSite, meterType: selectedMeterType.value }).then(
        setMeterOptions
      )
    }
  }, [selectedMeterType])

  let placeholderMeter = intl.formatMessage({ id: 'ALLMETERS' })
  let placeholderSite = intl.formatMessage({ id: 'ALLSITES' })
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row'>
          <div className='col-lg-3'>
            <label htmlFor='site-select'>
              {' '}
              {intl.formatMessage({ id: 'FILTERFORM.COMPANY' })}:
            </label>
            <Select
              defaultValue={companyFilter}
              onChange={setCompanyFilter}
              options={companyOptions}
              placeholder={companyFilter?.label.toUpperCase()}
              value={selectedCompany}
            />
          </div>

          {companyFilter && (
            <div className='col-lg-3'>
              <label htmlFor='site-select'>
                {intl.formatMessage({ id: 'FILTERFORM.SITEELECT' })}:
              </label>
              <Select
                defaultValue={companySiteOptions}
                onChange={setSiteFilter}
                options={companySiteOptions}
                isMulti={true}
                placeholder={placeholderSite}
                value={siteFilter}
              />
            </div>
          )}

          {companyFilter && siteFilter && (
            <div className='col-lg-3'>
              <label htmlFor='site-select'>Meter Type</label>
              <Select
                defaultValue={meterTypeOptions}
                onChange={setMeterTypeFilter}
                options={meterTypeOptions}
                isMulti={false}
                placeholder={placeholderMeter}
                value={meterTypeFilter}
              />
            </div>
          )}

          {companyFilter && siteFilter && (
            <div className='col-lg-3'>
              <label htmlFor='site-select'>Meter Select</label>
              <Select
                defaultValue={metersFilter}
                onChange={setMetersFilter}
                options={meterOptions}
                isMulti={true}
                placeholder={placeholderMeter}
                value={metersFilter}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FilterFormCalendar
