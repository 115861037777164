import React, { ReactNode } from 'react'
import BlockInterface, { BlockAttributes, BlockVariation } from '../BlockInterface'
import { FormGroup } from 'react-bootstrap'
import Field from '../../Form/Widgets/Field'
import HtmlEditor from '../../Form/Widgets/HtmlEditor'
import TextBlockPlaceholders from './TextBlockPlaceholders'

export type TextBlockAttributes = {
  type: string
  text?: string
}

export default class TextBlock implements BlockInterface {
  attributes: null | TextBlockAttributes = null

  getType(): string {
    return 'text'
  }
  setAttributes(attributes: TextBlockAttributes): void {
    this.attributes = attributes
  }
  getAttributes(): any {
    return this.attributes
  }
  getName(): string {
    return 'Text'
  }
  getIconClass(): string {
    return 'fa fa-quote-right'
  }
  getModalClasses(): string {
    if (this.attributes?.type === 'paragraph') {
      return 'modal-fullscreen'
    } else {
      return ''
    }
  }
  getVariationOptions(): BlockVariation[] {
    return [
      {
        name: 'Heading',
        icon: 'fa fa-heading',
        attribute: {
          type: 'head',
          text: '',
        },
      },
      {
        name: 'Paragraph',
        icon: 'fa fa-align-left',
        attribute: {
          type: 'paragraph',
          text: '',
        },
      },
    ]
  }
  renderTemplateView(
    onChange: () => void,
    openModal: (callback: (item: BlockAttributes) => void) => void
  ): ReactNode {
    if (this.attributes) {
      switch (this.attributes.type) {
        case 'head':
          return <h1>{this.attributes.text}</h1>
        case 'paragraph':
          return <p dangerouslySetInnerHTML={{ __html: this.attributes.text ?? '' }}></p>
      }
    }
    return <>{JSON.stringify(this.attributes)}</>
  }
  renderTemplateForm(): ReactNode {
    if (this.attributes) {
      switch (this.attributes.type) {
        case 'head':
          return (
            <div>
              <FormGroup>
                <Field
                  title='Text'
                  className='form-control'
                  name='text'
                  defaultValue={this.attributes.text}
                  onInput={(event: any) => {
                    ;(this.attributes as any).text = event.target.value
                  }}
                />
              </FormGroup>
            </div>
          )
        case 'paragraph':
          return (
            <div className='d-flex flex-nowrap h-100 gap-3'>
              <div className=''>
                <FormGroup className='h-100'>
                  <HtmlEditor
                    type='paragraph'
                    onChange={(event: any) => {
                      ;(this.attributes as any).text = event
                    }}
                    initialValue={this.attributes.text}
                  />
                </FormGroup>
              </div>
              <div className='w-400px'>
                <TextBlockPlaceholders />
              </div>
            </div>
          )
      }
    }
    return <></>
  }
  clone(): BlockInterface {
    return new TextBlock()
  }
}
