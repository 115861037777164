import axiosWithCSRF, { WithData } from '../../../utils/axiosWithCSRF'
import axios from 'axios'
import {
  LoginParams,
  RegisterParams,
  RequestResetPasswordParams,
  ResetPasswordParams,
  User,
} from './_models'

export function loginPostApi({ rememberMe, ...params }: LoginParams) {
  return axiosWithCSRF
    .post('/auth/login', {
      ...params,
      remember_me: rememberMe ? 1 : 0,
    })
    .then((res) => res.data)
}

export function meApi(token?: string) {
  if (token == null) {
    return Promise.resolve(null)
  }

  return axios
    .get<WithData<User>>('/auth/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .then((data) => {
      if (data?.data?.username === 'guest') {
        return null
      }

      return data
    })
}

export function registerPostAPI(registerParams: RegisterParams) {
  const { passwordConfirmation, ...restParams } = registerParams
  return axiosWithCSRF
    .post('/auth/register', {
      ...restParams,
      password_again: passwordConfirmation,
    })
    .then((res) => res.data)
}

export function requestResetPasswordPostAPI(
  requestResetPasswordParams: RequestResetPasswordParams
) {
  const { resetUrl, ...restParams } = requestResetPasswordParams

  return axiosWithCSRF
    .post('/auth/forgetPassword', { ...restParams, reset_url: resetUrl })
    .then((res) => res.data)
}

export function resetPasswordPostAPI(resetPasswordParams: ResetPasswordParams) {
  const { userId, key, passwordConfirmation, ...restParams } = resetPasswordParams

  return axiosWithCSRF
    .post(`/auth/resetPassword?USER=${userId}&KEY=${key}`, {
      ...restParams,
      password2: passwordConfirmation,
    })
    .then((res) => res.data)
}
