import React, { useState } from 'react'
import moment from 'moment'
import Field from './Field'

interface TimePickerProps {
  onChange: (time: Date | null) => void
  title: string
}

function TimePicker({ onChange, title }: TimePickerProps) {
  const [selectedTime, setSelectedTime] = useState<Date | null>(null)
  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const timeString = event.target.value
    const time = moment(timeString, 'HH:mm', true)
    if (time.isValid()) {
      const selectedDateTime = selectedTime ? moment(selectedTime) : moment().startOf('day')
      selectedDateTime.set({
        hour: time.hour(),
        minute: time.minute(),
        second: 0,
        millisecond: 0,
      })
      setSelectedTime(selectedDateTime.toDate())
      if (onChange) {
        onChange(selectedDateTime.toDate())
      }
    } else {
      setSelectedTime(null)
      if (onChange) {
        onChange(null)
      }
    }
  }

  return (
    <div className='position-relative'>
      <Field
        className='form-control'
        title={title}
        type='time'
        value={selectedTime ? moment(selectedTime).format('HH:mm') : ''}
        onChange={handleTimeChange}
      />
    </div>
  )
}

export default TimePicker
