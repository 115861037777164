import React, { useEffect, useState } from 'react'
import { CompareTotalData } from './CompareTotalData'
import { getCompareDataApi } from '../core/_requests'
import { MultiValue, SingleValue } from 'react-select'
import { CompanyListItem, SelectItem } from '../core/_models'
import { useIntl } from 'react-intl'

type Props = {
  company: SingleValue<SelectItem>
  companySite: MultiValue<CompanyListItem>
  meters: MultiValue<CompanyListItem>
  meterType: string
  readingType: string
}
const TotalDataBox: React.FC<Props> = ({
  company,
  companySite,
  meters,
  readingType,
  meterType,
}) => {
  const [compareData, setCompareData] = useState([0, 0, 0])
  const [thisYear, setThisYear] = useState(0)
  const [lastYear, setLastYear] = useState(0)
  const [percent, setPercent] = useState(0)
  const intl = useIntl()
  const [loading, setLoading] = useState(true)

  if (meterType == 'Gas') {
    meterType = 'Gas'
  } else if (meterType == 'Electric') {
    meterType = 'Electricity'
  }

  const params = {
    isLoggedIn: true,
    companyId: company ? company.value : '',
    siteId: companySite,
    metersId: meters,
    meterType: meterType,
    readingType: readingType,
  }
  const fetchCompareData = async () => {
    getCompareDataApi({ ...params }).then((response) => {
      setTimeout(async () => {
        const compareData = await getCompareDataApi(params)
        setCompareData(compareData)
        setPercent(compareData[2])
        setThisYear(compareData[0] ? compareData[0] : 0)
        setLastYear(compareData[1] ? compareData[1] : 0)
        setLoading(false)
      }, 1000)
    })
  }

  useEffect(() => {
    if (company || companySite || meters) {
      fetchCompareData()
      setLoading(true)
    }
  }, [company, companySite, meters])

  return (
    <>
      {loading ? (
        <span className='indicator-progress' style={{ display: 'block', textAlign: 'center' }}>
          {intl.formatMessage({ id: 'LOADING' })}...{' '}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      ) : (
        compareData && (
          <CompareTotalData
            className='card-xl-stretch mb-xl-8'
            chartHeight='150px'
            chartPercent={percent}
            thisYearTotalData={thisYear}
            lastYearTotalData={lastYear}
            readingType={readingType}
            type={meterType}
          />
        )
      )}
    </>
  )
}

export default TotalDataBox
