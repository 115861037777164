import axios, { AxiosResponse } from 'axios'
import moment from 'moment'

export function getChartData(
  date: Date | null = null,
  initTime: Date | null = null,
  endTime: Date | null = null
) {
  let url = `/lora_webhook/getChartData`
  if (date && initTime && endTime) {
    const dateConverted = moment(date)
    const dateFormatted = dateConverted.format('YYYY-MM-DD')

    const formattedInitTime = initTime?.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })

    const formattedEndTime = endTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

    const paramsArr = [
      `date=${dateFormatted}`,
      `initTime=${formattedInitTime}`,
      `endTime=${formattedEndTime}`,
    ]
    url = `/lora_webhook/getChartData?${paramsArr.filter(Boolean).join('&')}`
  }
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}
