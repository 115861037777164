import { useState } from 'react'
import { BlockAttributes } from './BlockInterface'
import BlockEditModal from './BlockEditModal'

export type BlockEditButtonAttributes = {
  onSave: (item: BlockAttributes) => void
  block?: BlockAttributes
}

export default function BlockEditButton(props: BlockEditButtonAttributes) {
  let [show, setShow] = useState(false)
  return (
    <>
      <button
        className='btn btn-light-primary btn-icon rounded-circle btn-sm position-absolute end-0 me-15 mt-2'
        onClick={async (e) => {
          e.preventDefault()
          setShow(true)
        }}
        style={{
          zIndex: 100,
        }}
      >
        <span className='fa fa-edit'></span>
      </button>
      <BlockEditModal
        show={show}
        onHide={() => {
          setShow(false)
        }}
        onSave={(item: BlockAttributes) => {
          props.onSave(item)
          setShow(false)
        }}
        block={props.block}
      />
    </>
  )
}
