import { ReactNode } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import FormHandler from '../../../utils/Form/FormHandler'
import Textarea from '../../../utils/Form/Widgets/Textarea'
import Check from '../../../utils/Form/Widgets/Check'

type Props = {
  onHide: () => void
  show: boolean
  refreshFunction?: Function
  alarm_id: string
}
class AlarmSite {
  site_acknowledge?: string
  site_alarm_comment?: string
}

class AlarmCommentForm extends FormHandler {
  constructor(props: Props) {
    super(props)
    this.state = { entity: new AlarmSite() }
  }
  getApiUrl(): string {
    return '/alarms/save/' + this.props.alarm_id
  }

  getSingleUrl(): string | null {
    return '/alarms/single/' + this.props.alarm_id
  }

  getPostData(): object {
    return {
      alarms_site: this.state.entity,
    }
  }

  render(): ReactNode {
    var state = this.state as any

    let title = 'Alarm Comment Form'
    return (
      <form
        onSubmit={(event) => {
          this.handleSubmit(event).then((response) => {
            if (this.props.refreshFunction) {
              this.props.refreshFunction(response.data.data.entity_id)
            }
          })
        }}
      >
        <p className='fw-bolder fs-5'>Alarm Comment</p>
        <FormGroup>
          <Check
            title='Acknowledge'
            name='site_acknowledge'
            type='switch'
            checked={state.entity.site_acknowledge ? true : false}
            options={['']}
            onChange={(event: any) => {
              this.handleInputChange(event)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Textarea
            title='Alarm Comment'
            className='form-control'
            type='string'
            name='site_alarm_comment'
            onInput={(event: any) => {
              this.handleInputChange(event)
            }}
            defaultValue={state.entity.site_alarm_comment || ''}
          />
        </FormGroup>
        <button
          style={{ marginTop: '5px', marginBottom: '10px' }}
          type='submit'
          className='btn btn-success btn-sm'
        >
          Save
        </button>
      </form>
    )
  }
}

export { AlarmCommentForm }
