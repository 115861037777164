import React, { Component } from 'react'

type ListingProps = {
  page: number
  limit: number
  total_count: number
  setPage: Function
}

class Pagination extends Component<ListingProps, {}> {
  constructor(props: ListingProps) {
    super(props)
  }

  render() {
    const totalPage = parseInt((this.props?.total_count / (this.props?.limit ?? 1) + 1).toString())
    const fiveItems = Array.from(Array(5).keys())
    return (
      <div className='pagination-card-bg'>
        <nav>
          <ul className='pagination'>
            <li className={'page-item ' + (this.props.page <= 1 ? 'disabled' : '')}>
              <button className='page-link' onClick={() => this.props.setPage(1)}>
                <span className='sr-only'>First</span>
                <i className='fa fa-angle-double-left'></i>
              </button>
            </li>
            <li className={'page-item ' + (this.props.page <= 1 ? 'disabled' : '')}>
              <button className='page-link' onClick={() => this.props.setPage(this.props.page - 1)}>
                <span className='sr-only'>Before</span>
                <i className='fa fa-angle-left'></i>
              </button>
            </li>
            {fiveItems.map((index) => {
              let currentPage = this.props.page - 2 + index
              if (currentPage < 1 || currentPage > totalPage) {
                return <div key={index}></div>
              }
              return (
                <li
                  className={'page-item ' + (this.props.page === currentPage ? 'active' : '')}
                  key={index}
                >
                  <button className='page-link' onClick={() => this.props.setPage(currentPage)}>
                    <span>{this.props.page - 2 + index}</span>
                  </button>
                </li>
              )
            })}
            <li className={'page-item ' + (this.props.page >= totalPage ? 'disabled' : '')}>
              <button className='page-link' onClick={() => this.props.setPage(this.props.page + 1)}>
                <span className='sr-only'>After</span>
                <i className='fa fa-angle-right'></i>
              </button>
            </li>
            <li className={'page-item ' + (this.props.page >= totalPage ? 'disabled' : '')}>
              <button className='page-link' onClick={() => this.props.setPage(totalPage)}>
                <span className='sr-only'>Last</span>
                <i className='fa fa-angle-double-right'></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}

export default Pagination
