import { ReactNode, SyntheticEvent } from 'react'
import { FormGroup, Modal } from 'react-bootstrap'
import axios, { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'
import FormHandler, { FormParams } from '../../../utils/Form/FormHandler'
import Field from '../../../utils/Form/Widgets/Field'
import AutocompleteSelect from '../../../utils/Form/Widgets/AutocompleteSelect'
import Check from '../../../utils/Form/Widgets/Check'
import { companySitesList } from '../../charts/core/_requests'
import Select, { MultiValue, SingleValue } from 'react-select'
import HtmlEditor from '../../../utils/Form/Widgets/HtmlEditor'

type Props = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: Function
}

class Note {
  title?: string
  note?: string
  company?: number
  status?: string
  sites?: string
}
class ModalState {
  siteOptions?: {
    value: string
    label: string
  }[]
}

class NoteEditModal extends FormHandler {
  statusOptions = [
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'passive',
      label: 'Disabled',
    },
  ]

  public state: FormParams & ModalState
  constructor(props: Props) {
    super(props)
    this.state = {
      entity: new Note(),
      siteOptions: [],
    }
  }
  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<{}>, snapshot?: any): void {
    let state = this.state as any
    let prev = prevState as any
    if (prev.entity.company !== state.entity.company) {
      this.getSites()
    }
  }

  getSites() {
    let state = this.state as any
    if (state.entity.company) {
      companySitesList({ companyId: Number(state.entity.company) }).then((response) => {
        this.setState({
          ...this.state,
          siteOptions: response,
        })
      })
    }
  }

  getApiUrl(): string {
    return '/company/notes/save/' + this.props.itemIdForUpdate
  }
  getSingleUrl(): string | null {
    if (this.props.itemIdForUpdate === 'add') {
      return null
    } else {
      return '/company/notes/single/' + this.props.itemIdForUpdate
    }
  }

  getPostData(): object {
    return {
      company_notes: this.state.entity,
    }
  }

  render(): ReactNode {
    var state = this.state as any
    let title = 'Add Note'
    if (this.state.entity && this.props.itemIdForUpdate !== 'add') {
      let entity = this.state.entity as any
      title = entity.title
    }
    let defaultCompanyValue = state.entity.company || 0
    if (this.props.itemIdForUpdate === 'add') {
      defaultCompanyValue = 0
    } else {
      defaultCompanyValue = state.entity.company
    }
    return (
      <Modal
        id='kt_modal_create_company'
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered'
        size='lg'
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={async (event) => {
            event.preventDefault()
            try {
              const response = await this.handleSubmit(event)
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response.data.data.entity_id)
              }
              this.props.onHide()
            } catch (error) {
              console.error('Error saving site:', error)
              Swal.fire({
                title: 'Error',
                text: 'An error occurred while saving the site. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title='Title'
                className='form-control'
                type='text'
                name='title'
                onInput={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={state.entity.title}
              />
            </FormGroup>
            <FormGroup>
              <AutocompleteSelect
                title='Company'
                name='company'
                onChange={(event: any) => {
                  this.handleInputChange(event)
                }}
                defaultValue={defaultCompanyValue}
                autoCompleteTokenUrl='/company/autocomplete'
                isDisabled={this.props.itemIdForUpdate !== 'add'}
                styles={{
                  menu: (provided: any) => ({
                    ...provided,
                    zIndex: 1100,
                    marginTop: '5px',
                    marginBottom: '5px',
                  }),
                  control: (provided: any) => ({
                    ...provided,
                    position: 'relative',
                    zIndex: 1050,
                    marginTop: '5px',
                    marginBottom: '5px',
                  }),
                }}
              />
            </FormGroup>
            {state.entity.company ? (
              <FormGroup>
                <div className='form-group'>
                  <label>Company Sites</label>
                  <Select
                    isMulti={true}
                    value={state.siteOptions.filter((option: any) =>
                      state.entity.sites?.split(',').includes(option.label)
                    )}
                    name='sites'
                    onChange={(selectedOptions: any) => {
                      let selectedSites

                      if (selectedOptions.length === 1) {
                        selectedSites = selectedOptions[0].label
                      } else {
                        selectedSites = selectedOptions.map((option: any) => option.label).join(',')
                      }

                      this.handleInputChange({
                        target: { name: 'sites', value: selectedSites },
                      } as any)
                    }}
                    options={state.siteOptions}
                    required={false}
                    styles={{
                      menu: (provided: any) => ({
                        ...provided,
                        zIndex: 1100,
                        marginTop: '5px',
                        marginBottom: '5px',
                      }),
                      control: (provided: any) => ({
                        ...provided,
                        position: 'relative',
                        zIndex: 1050,
                        marginTop: '5px',
                        marginBottom: '5px',
                      }),
                    }}
                  />
                </div>
              </FormGroup>
            ) : (
              <></>
            )}
            <FormGroup>
              <HtmlEditor
                type='paragraph'
                onChange={(event: any) => {
                  this.handleInputChange({ target: { name: 'note', value: event } } as any)
                }}
                initialValue={state.entity.note}
              />
            </FormGroup>

            <FormGroup>
              <Check
                title='Status'
                name='status'
                type='switch'
                checked={state.entity.status === 'active'}
                options={['']}
                onChange={(event: any) => {
                  const newValue = event.target.checked ? 'active' : 'passive'
                  this.handleInputChange({ target: { name: 'status', value: newValue } } as any)
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {this.props.itemIdForUpdate !== 'add' ? (
              <button
                type='button'
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction('')
                    }
                  })
                }}
                className='btn btn-danger btn-sm'
              >
                <span className='fa fa-trash'></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type='submit' className='btn btn-success btn-sm'>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export { NoteEditModal }
