/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import GroupChartDataRow from './GroupChartDataRow'
import { MultiValue, SingleValue } from 'react-select'
import { SelectItem } from '../core/_models'
import { getGroupChartDataRows } from '../core/_requests'
import { useIntl } from 'react-intl'
type Props = {
  className: string
  meterType: string
  company: SingleValue<SelectItem>
  readingType: string
  meters: MultiValue<SelectItem>
  startCustomDate: string
  endCustomDate: string
}

const GroupChartDataList: React.FC<Props> = ({
  className,
  meterType,
  company,
  readingType,
  meters,
  startCustomDate,
  endCustomDate,
}) => {
  const intl = useIntl()
  const [groupDataRows, setGroupDataRows] = useState([])

  const meterIds: string[] = []
  meters.forEach((meter) => {
    meterIds.push(meter.label)
  })

  const params = {
    isLoggedIn: true,
    companyId: company ? company.value : '',
    meters: meterIds,
    meterType: meterType,
    readingType: readingType,
    startDate: startCustomDate,
    endDate: endCustomDate,
  }

  const fetchGroupChartRowsData = async () => {
    getGroupChartDataRows({ ...params }).then((response) => {
      setTimeout(async () => {
        const data = await getGroupChartDataRows(params)
        setGroupDataRows(data)
      }, 250)
    })
  }

  useEffect(() => {
    fetchGroupChartRowsData()
  }, [company, meters, readingType, startCustomDate, endCustomDate])
  return (
    <div className={clsx('card', className)}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-3'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder text-dark fs-3'>
            {intl.formatMessage({ id: 'DASHBOARDGROUP.METERSDATALIST' })} -{' '}
            {meterType == 'Electricity'
              ? intl.formatMessage({ id: 'CHARTTITLE.ELECTRIC' })
              : intl.formatMessage({ id: 'CHARTTITLE.GAS' })}
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {groupDataRows ? (
          groupDataRows.map((data, index) => (
            <GroupChartDataRow
              key={index}
              meterId={data['meterId']}
              meterType={data['meterType']}
              totalData={data['totalData']}
              site={data['siteName']}
            />
          ))
        ) : (
          <span>N/A</span>
        )}
      </div>
      {/* end::Body */}
    </div>
  )
}
export { GroupChartDataList }
