import axios, { AxiosResponse } from 'axios'
import {
  BenchmarkChartData,
  BenchmarkParams,
  benchmarkRecordData,
  BenchmarkTableData,
  BenchmartHeatmapData,
  siteFeatureOptions,
} from './_models'
import { WithData } from '../../../utils/axiosWithCSRF'

export function benchmarkList(params: BenchmarkParams) {
  const { companyId, siteId, period, customStartDate, customEndDate, siteFeature } = params
  let siteIdEncoded = ''
  const sites: string[] = []
  if (Array.isArray(siteId) && siteId.length > 0) {
    siteId.forEach((element) => {
      sites.push(element.value)
    })
    const sitesString = sites.join(',')
    siteIdEncoded = encodeURIComponent(sitesString)
  }
  const paramsArr = [
    companyId && `company=${companyId}`,
    siteId && siteId.length > 0 && `company_sites=${siteIdEncoded}`,
    period && `period=${period}`,
    period === 'custom' && customStartDate && `start_date=${customStartDate}`,
    period === 'custom' && customEndDate && `end_date=${customEndDate}`,
    siteFeature && `site_feature=${siteFeature}`,
  ]

  const url = `/benchmark/list?${paramsArr.filter(Boolean).join('&')}`

  let results = axios
    .get(url)
    .then((response: AxiosResponse<WithData<BenchmarkTableData>>) => response.data)
    .then((response) => response.data)
  return results
}

export function benchmarkChartData(params: BenchmarkParams) {
  const { companyId, siteId, period, customStartDate, customEndDate, siteFeature } = params
  let siteIdEncoded = ''
  const sites: string[] = []
  if (siteId && siteId.length > 0) {
    siteId.forEach((element) => {
      sites.push(element.value)
    })
    const sitesString = sites.join(',')
    siteIdEncoded = encodeURIComponent(sitesString)
  }
  const paramsArr = [
    companyId && `company=${companyId}`,
    siteId && siteId.length > 0 && `company_sites=${siteIdEncoded}`,
    period && `period=${period}`,
    period === 'custom' && customStartDate && `start_date=${customStartDate}`,
    period === 'custom' && customEndDate && `end_date=${customEndDate}`,
    siteFeature && `site_feature=${siteFeature}`,
  ]

  const url = `/benchmark/charts?${paramsArr.filter(Boolean).join('&')}`

  let results = axios
    .get(url)
    .then((response: AxiosResponse<WithData<BenchmarkChartData>>) => response.data)
    .then((response) => response.data)
  return results
}

export function benchmarkHeatmapData(params: BenchmarkParams) {
  const { companyId, siteId, period, customStartDate, customEndDate, siteFeature } = params
  let siteIdEncoded = ''
  const sites: string[] = []
  if (siteId && siteId.length > 0) {
    siteId.forEach((element) => {
      sites.push(element.value)
    })
    const sitesString = sites.join(',')
    siteIdEncoded = encodeURIComponent(sitesString)
  }
  const paramsArr = [
    companyId && `company=${companyId}`,
    siteId && siteId.length > 0 && `company_sites=${siteIdEncoded}`,
    period && `period=${period}`,
    period === 'custom' && customStartDate && `start_date=${customStartDate}`,
    period === 'custom' && customEndDate && `end_date=${customEndDate}`,
    siteFeature && `site_feature=${siteFeature}`,
  ]

  const url = `/benchmark/heatmap?${paramsArr.filter(Boolean).join('&')}`

  let results = axios
    .get(url)
    .then((response: AxiosResponse<WithData<BenchmartHeatmapData>>) => response.data)
    .then((response) => response.data)
  return results
}

export function getSiteFeatureOptions(companyId: number) {
  let url = '/company/sites/getFeatures/' + companyId.toString()
  let results = axios.get(url).then((response: AxiosResponse<WithData<siteFeatureOptions>>) => {
    return response.data.data
  })
  return results
}

export function getBenchmarkRecordDataById(id: string) {
  const url = `/benchmark_records/single/${id}`
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export async function saveBenchmarkRecordByKey(recordId: string, params: benchmarkRecordData) {
  let csrfUrl = '/benchmark_records/save/' + recordId
  let form_id = ''
  let form_build_id = ''
  let form_token = ''
  const csrf = await axios
    .get(csrfUrl)
    .then((response) => {
      let data = response.data.data
      form_id = data.form_id
      form_build_id = data.form_build_id
      form_token = data.form_token
    })
    .catch((error) => {})
  const url = '/benchmark_records/save/' + recordId

  let postData = {
    benchmark_records: params,
    form_id: form_id,
    form_build_id: form_build_id,
    form_token: form_token,
  }
  let results = axios
    .post(url, postData)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
}

export async function getSiteChartData(
  companyId: string,
  siteId: string,
  startDate: string,
  endDate: string
) {
  const url = `/benchmark/siteChart?company=${companyId}&company_sites=${siteId}&start_date=${startDate}&end_date=${endDate}`
  let results = axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data)
  return results
}
